import React from "react";
import PropTypes from "prop-types";
import { getFunName } from "../helpers";
import Layout from "./Layout";
import DashboardSec from "./Dashboard";

class ClientPicker extends React.Component {
  myInput = React.createRef();
  static propTypes = {
    history: PropTypes.object,
    updatedSite: PropTypes.string
  };


  handleChange = event => {
    console.log(event.currentTarget.value);
    // update that client
    // 1. Take a copy of the curernt client
    const siteName = event.currentTarget.value;
    localStorage.setItem('clientchosed', JSON.stringify(siteName));
    // 3. Change the page to /store/whatever-they-entered
    this.props.history.push(`/client/${siteName}`);
  };

  goToSite = event => {
    // 1. Stop the form from submitting
    event.preventDefault();
    // 2. get the text from that input
    const siteName = event.currentTarget.value;
    // 3. Change the page to /store/whatever-they-entered
    this.props.history.push(`/client/${siteName}`); 
  };
  render() {
    return (

      <DashboardSec>
      <div>

      </div>
      <div>
      <p>Selected Date: {JSON.parse(localStorage.getItem('data'))}</p>
      <p>Selected Time: {JSON.parse(localStorage.getItem('dataampm'))}</p>
      <p>Selected Parking Bay: {JSON.parse(localStorage.getItem('beacons'))}</p>
      </div>
      <div>
      <form className="store-selector" onSubmit={this.goToStore}>
        <select
          type="text"
          name="site"
          onChange={this.handleChange}
          value= {getFunName}
        >
          <option value="Client 1">Choose Client</option>
          <option value="Client 2">Client1</option>
          <option value="Client 3">Client2</option>
          <option value="Client 4">Client3</option>
        </select>
       {/* <button type="submit">Go To Site →</button> */}
       </form>
       </div>
       </DashboardSec>
       
    );
  }
}

export default ClientPicker;
