import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import WithMarker from './WithMarker';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { db } from "./Login";
import isEmpty from 'lodash.isempty';


const sortType = [
  {
    value: 'Free',
    label: 'Free',
  },
  {
    value: 'Per Hour',
    label: 'Per Hour',
  },
  {
    value: 'Guest or Permit Free',
    label: 'Guest or Permit Free',
  },
  {
    value: 'Elapsed Time',
    label: 'Elapsed Time',
  },
];


const paymentTypes = [
  {
    value: 'Free',
    label: 'Free',
  },
  {
    value: 'Per Hour',
    label: 'Per Hour',
  },
  {
    value: 'Guest or Permit Free',
    label: 'Guest or Permit Free',
  },
  {
    value: 'Elapsed Time',
    label: 'Elapsed Time',
  },
];

const hardbays = [
  {
    value: 'Bradfield',
    label: 'Bradfield',
  },
]



export default function UploadBays() {



  const [filteredParkingbay, setfilteredParkingbay] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("filteredParkingbay");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [carParkScheme, setcarParkScheme] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParkScheme");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carParkSchemew, setcarParkSchemew] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParkSchemew");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [carBays, setcarBays] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carBays");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


    const [carPark, setcarPark] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("carPark");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [carParklocation, setcarParklocation] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("carParklocation");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [carParkspaces, setcarParkspaces] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("carParkspaces");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });


      const [adminName, setadminName] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("adminName");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [adminEmail, setadminEmail] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("adminEmail");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [adminNo, setadminNo] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("adminNo");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });
      const [carparkSelected, setcarparkSelected] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("carparkSelected");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });


      const [carparkSelectedName, setcarparkSelectedName] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("carparkSelectedName");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });


      const [baysSeet, setbaysSeet] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("baysSeet");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

    
    
      useEffect(() => {
        // storing input name
        localStorage.setItem("carparkSelected", JSON.stringify(carparkSelected));
      }, [carparkSelected]);
    

function doNothing() {
  console.log(baysSeet);

  {baysSeet.forEach(d => 
    
    db.collection('carParks').doc(d.id + d.carparkSelectedName).set({
      carPark: d.carparkSelectedName,
      carParkspace: d.id,
      week: d.week,
      end: d.end,
      adminEmail: d.adminEmail,
      adminName: d.adminName,
      adminNo: d.adminNo,
      
  })
    
    )};
  
};


function sortOut() {
  const listOfObjects = [];
  filteredParkingbay.forEach(element => {
    if (element['icon'] == "./images/m2.png") {
      var singleObj = {}
      singleObj['id'] = element['id'];
      singleObj['week'] = carParkScheme;
      singleObj['end'] = carParkSchemew;
      listOfObjects.push(singleObj);
    }
  });
  setbaysSeet(listOfObjects);
}
useEffect(() => {
  // storing input name
  doNothing();
}, []);






  return (
    <React.Fragment>

        <Typography variant="h6" gutterBottom align="center" >
          Finished!
        </Typography>
        <Typography variant="subtitle1" align="center" >
          A new car park has been added to the database.
        </Typography>
     

    </React.Fragment>
  );
}