import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import ToggleColorMode from './Dashboard';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { db } from "./Login";
import { useEffect, useState } from 'react';
import { months } from 'moment';
import { List } from '@mui/material';
import Chip from '@mui/material/Chip';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';

import { auth } from "./Login";
import { useAuthState } from "react-firebase-hooks/auth";

// Generate Order Data
function createData(vrn_cl, name_cl, event_cl, email_cl, timein_cl, timeout_cl, bay_cl, paymenttype_cl) {
  return {
    vrn_cl, name_cl, event_cl, email_cl, timein_cl, timeout_cl, bay_cl, paymenttype_cl
  };
}

var rowsH = [];

function preventDefault(event) {
  event.preventDefault();
}

function addDays() {
  var datenow = new Date(Date.now() + (3600 * 1000 * 24 * 30));
  var finaldate = datenow.toLocaleDateString();
  console.log(finaldate);
  return (finaldate)
}


export default function CancelBooking() {
  const [filteredParking, setfilteredParking] = useState([]);
  const [value, setValue] = React.useState('');
  const [tablen, setShowTable] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [user, loading, error] = useAuthState(auth);
  const [dayValue, setdayValue] = useState('');
  const [dateInFinal, setdateInFinal] = useState(new Date());
  const [calendarDays, setcalendarDays] = useState([]);
  const [calendarDaysIn, setcalendarDaysIn] = useState([]);


  const [info, setInfo] = useState([]);
  const [admin, setAdmin] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("admin");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [carparkSelected, setcarparkSelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const fetchUserName = async () => {
    try {
      const query = await db
        .collection("users")
        .doc(user?.uid)
        .get();
      const userName = query.data();
      //console.log(userName);
      setAdmin(userName.adminA);
      //console.log(userName.adminA);
    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };

  const fetchParking = async () => {
    try {
      db.collection("parking_lots").get().then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.forEach(element => {
          var data = element.data();
          if (data.adminA == admin) {
            setInfo(arr => [...arr, data]);
          };
        });
      });

    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };

  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e]).map(e => arr[e]);
    return unique;
  }

  useEffect(() => {
    // storing input name
    if (!admin) {
      fetchUserName();
    }
    else {
      console.log('fetchParking');
      fetchParking();
    };
  }, []);


  useEffect(() => {
    // storing input name
    localStorage.setItem("admin", JSON.stringify(admin));
    fetchParking();
  }, [admin]);


  useEffect(() => {
    // storing input name
    var filt = getUnique(info, "baytype");
    setfilteredParking(filt);
  }, [info]);

  useEffect(() => {

    localStorage.setItem("carparkSelected", JSON.stringify(carparkSelected));

    filteredParking.forEach(dat => {
      if (dat.parking == carparkSelected) {
        localStorage.setItem("carparkSelectedName", JSON.stringify(dat.baytype));
        localStorage.setItem("carparkSelectedlat", JSON.stringify(dat.lat));
        localStorage.setItem("carparkSelectedlng", JSON.stringify(dat.lng));
      }
    }
    );

  }, [carparkSelected]);




  const getMyDay = (valueH) => {
    var dateHe = new Date(valueH);
    setSelectedDate(dateHe);
  }

  const converMonth = (valueZ, valueDay) => {
    //console.log((String(value.toDate().getMonth()) + String(value.toDate().getDay())));
    var monthD = "";
    var monthHere = "";
    var finalDate = "";
    monthD = valueDay.toDate().getMonth();
    var dayD = valueDay.toDate().getDate();
    var dayHere = "";

    if (dayD == "1") {
      dayHere = "Mon";
    }
    if (dayD == "2") {
      dayHere = "Tue";
    }
    if (dayD == "3") {
      dayHere = "Wed";
    }
    if (dayD == "4") {
      dayHere = "Thu";
    }
    if (dayD == "5") {
      dayHere = "Fri";
    }
    if (dayD == "6") {
      dayHere = "Sat";
    }
    if (dayD == "0") {
      dayHere = "Sun";
    }

    if (monthD == "0") {
      monthHere = "January";
    };
    if (monthD == "1") {
      monthHere = "February";
    };
    if (monthD == "2") {
      monthHere = "March";
    };
    if (monthD == "3") {
      monthHere = "April";
    };
    if (monthD == "4") {
      monthHere = "May";
    };
    if (monthD == "5") {
      monthHere = "June";
    };
    if (monthD == "6") {
      monthHere = "July";
    };
    if (monthD == "7") {
      monthHere = "August";
    };
    if (monthD == "8") {
      monthHere = "September";
    };
    if (monthD == "9") {
      monthHere = "October";
    };
    if (monthD == "10") {
      monthHere = "November";
    };
    if (monthD == "11") {
      monthHere = "December";
    };

    finalDate = "Date " + monthHere + "," + dayD + " Time " + String(valueZ.toDate().getHours()) + ":00";
    //console.log(finalDate);
    return (
      finalDate
    );
  }

  const converMonthOut = (valueZO, valueDayO) => {
    //console.log((String(value.toDate().getMonth()) + String(value.toDate().getDay())));
    var monthDO = "";
    var monthHereO = "";
    var finalDateO = "";
    monthDO = valueDayO.toDate().getMonth();
    var dayDO = valueDayO.toDate().getDate();
    var dayHereO = "";

    if (dayDO == "1") {
      dayHereO = "Mon";
    }
    if (dayDO == "2") {
      dayHereO = "Tue";
    }
    if (dayDO == "3") {
      dayHereO = "Wed";
    }
    if (dayDO == "4") {
      dayHereO = "Thu";
    }
    if (dayDO == "5") {
      dayHereO = "Fri";
    }
    if (dayDO == "6") {
      dayHereO = "Sat";
    }
    if (dayDO == "0") {
      dayHereO = "Sun";
    }

    if (monthDO == "0") {
      monthHereO = "January";
    };
    if (monthDO == "1") {
      monthHereO = "February";
    };
    if (monthDO == "2") {
      monthHereO = "March";
    };
    if (monthDO == "3") {
      monthHereO = "April";
    };
    if (monthDO == "4") {
      monthHereO = "May";
    };
    if (monthDO == "5") {
      monthHereO = "June";
    };
    if (monthDO == "6") {
      monthHereO = "July";
    };
    if (monthDO == "7") {
      monthHereO = "August";
    };
    if (monthDO == "8") {
      monthHereO = "September";
    };
    if (monthDO == "9") {
      monthHereO = "October";
    };
    if (monthDO == "10") {
      monthHereO = "November";
    };
    if (monthDO == "11") {
      monthHereO = "December";
    };

    finalDateO = "Date " + monthHereO + "," + dayDO + " Time " + String(valueZO.toDate().getHours()) + ":00";
    //console.log(finalDate);
    return (
      finalDateO
    );
  }

  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time)
    )
  }
  

  function calculateBays() {
    var totalValue = 19;

    /*
        db.
            .collection("mail")
            .add({
              to: "mihairobert.tudor@gmail.com",
              message: {
                subject: "Guys, say Hello to Firebase!",
                text: "This is the plaintext section of the email body.",
                html: "TESTING OUR WAY TO AUTOMATIC E-MAIL CONFIRMATIONS",
              },
            })
            .then(() => console.log("Queued email for delivery!"));
        */
    return (String(totalValue - rowsH.length));
  }



  const getData = async () => {
    if (rowsH.length == 0) {
      db
        .collection("calendar")
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {

            if ((doc.data().admin == admin)) {
              if (
                (selectedDate.getFullYear() == doc.data().dateday.toDate().getFullYear()
                  &&
                  selectedDate.getMonth() == doc.data().dateday.toDate().getMonth()
                  &&
                  selectedDate.getDate() == doc.data().dateday.toDate().getDate())
                ||
                (
                  selectedDate.getFullYear() == doc.data().dateOutday.toDate().getFullYear()
                  &&
                  selectedDate.getMonth() == doc.data().dateOutday.toDate().getMonth()
                  &&
                  selectedDate.getDate() == doc.data().dateOutday.toDate().getDate()
                )
              ) {
                rowsH.push({
                  id: doc.id,
                  vrn_cl: doc.data().vrn,
                  name_cl: doc.data().fullname,
                  event_cl: doc.data().eventname,
                  email_cl: doc.data().email,
                  phone_cl: doc.data().phone,
                  timein_cl: converMonth(doc.data().timein, doc.data().dateday),
                  //(" Day: " + ),
                  timeout_cl: converMonthOut(doc.data().timeout, doc.data().dateOutday),
                  //(" Day: " + String(doc.data().timeout.toDate().getDay()) + " Hour: " + String(doc.data().timeout.toDate().getHours()) + " Minute: " + String(doc.data().timeout.toDate().getMinutes())),
                  paymenttype_cl: doc.data().paymenttype,
                  bay56: (doc.data().bay56 == false ? "V1" : ""),
                  bay57: (doc.data().bay57 == false ? "V2" : ""),
                  bay58: (doc.data().bay58 == false ? "V3" : ""),
                  bay59: (doc.data().bay59 == false ? "V4" : ""),
                  bay60: (doc.data().bay60 == false ? "V5" : ""),
                  bay61: (doc.data().bay61 == false ? "V6" : ""),
                  bay62: (doc.data().bay62 == false ? "V7" : ""),
                  bay63: (doc.data().bay63 == false ? "V8" : ""),
                  bay64: (doc.data().bay64 == false ? "V9" : ""),
                  bay65: (doc.data().bay65 == false ? "V10" : ""),
                  bay66: (doc.data().bay66 == false ? "V11" : ""),
                  bay67: (doc.data().bay67 == false ? "V12" : ""),
                  bay68: (doc.data().bay68 == false ? "V13" : ""),
                  bay69: (doc.data().bay69 == false ? "V14" : ""),
                  bay70: (doc.data().bay70 == false ? "V15" : ""),
                  bay71: (doc.data().bay71 == false ? "V16" : ""),
                  bay72: (doc.data().bay72 == false ? "V17" : ""),
                  bay73: (doc.data().bay73 == false ? "V18" : ""),
                  bay74: (doc.data().bay74 == false ? "V19" : ""),
                });
              }
            }
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
      //console.log(rowsH);
    }
  };


  function sortCalendar() {
    var temp = [];


    calendarDays.forEach(element => {
      var hoursin = moment(element.datein)._d.getHours() * 3600;
      var hoursout = moment(element.dateout)._d.getHours() * 3600;

      if ((moment(dateInFinal).unix() >= (moment(element.datein).unix() - hoursin))
        && (moment(dateInFinal).unix() <= (moment(element.dateout).unix() - hoursout))) {
        temp.push(element);
      };

    });

    setcalendarDaysIn(temp);
  }

  const sortDateIn = async () => {
    var beac = [];
    try {
      db.collection("calendar").get().then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.forEach(element => {
          var data = element.data();
          if (data.parking == carparkSelected) {
            beac.push(data);
          };
        });
        setcalendarDays(beac);
      });

    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };


  useEffect(() => {
    // storing input name
    if (tablen) {
      rowsH = [];
      getData();
      //console.log(rowsH);
      //setLoading(false);
    };
  }, [filteredParking]);




  function generateAll(valueGot) {
    setcarparkSelected(valueGot);
  };


  async function deleteDoc(carparkspaceb,dateinb,dateoutb) {
    var docId = carparkspaceb+dateinb+dateoutb;
    db.collection("calendar").doc(docId).delete();
    var copy_in = [];
    console.log(calendarDaysIn);
    calendarDaysIn.map((row) => 
    {
      console.log(row);
      console.log(row.carParkspace);
      console.log(carparkspaceb);
      console.log(row.datein);
      console.log(dateinb);
      console.log(row.dateout);
      console.log(dateoutb);
      console.log((row.carParkspace === carparkspaceb)
      && (row.datein === dateinb)
      &&(row.dateout === dateoutb));
      if((row.carParkspace === carparkspaceb)
      && (row.datein === dateinb)
      &&(row.dateout === dateoutb))
      {
        console.log("condition true");
      }
      else
      {
        copy_in.push(row);
      }
    });
    console.log(copy_in);
    setcalendarDaysIn(copy_in);
  }



  useEffect(() => {
    var dateHea = new Date(dayValue);

    //console.log((dateHea > 0) && (timeHea > 0) );
    if (dateHea > 0) {
      var selectedDateInYear = dateHea.getFullYear();
      var selectedDateInMonth = dateHea.getMonth();
      var selectedDateInDate = dateHea.getDate();
      var convertedselectedDateIn = moment([selectedDateInYear, selectedDateInMonth, selectedDateInDate, 0, 0, 0]);
      //var finalIn = convertedselectedDateIn.unix();

      setdateInFinal(convertedselectedDateIn);
    }

  }, [dayValue, carparkSelected]);



  useEffect(() => {

    if (moment(dateInFinal).unix() > 0) {
      sortDateIn();
    }
  }, [dateInFinal]);


  useEffect(() => {
    sortCalendar();

  }, [calendarDays]);



  return (
    <ToggleColorMode>
      <React.Fragment>
        <Paper
          sx={{
            margin: 2,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '70vh',
            overflow: 'auto',

          }}
        >
          <div align="center" style={{ fontSize: 20, fontWeight: 'bold' }}>Delete Booking</div>
          <div style={{ padding: '10px', margin: "5px" }}>

            <Grid
              container spacing={4}
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >

              <Grid item xs={5}>
                <TextField fullWidth
                  id="carparks"
                  required
                  select
                  label="Car Parks"
                  value={carparkSelected}
                  onChange={e => generateAll(e.target.value)}
                //helperText="Please select your currenc"
                >
                  {filteredParking.map((option) => (
                    <MenuItem key={option.parking} value={option.parking}>
                      {option.baytype}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>


            </Grid>
          </div>
          <div align="center" style={{ margin: '5px', }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={2} style={{ width: '250px', }}>
                <DesktopDatePicker
                  label="Select Date"
                  value={dayValue}
                  required
                  //maxDate={new Date(Date.now() + ( 3600 * 1000 * 24 * 30))}
                  onChange={(newValue) => {
                    setdayValue(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </div>

          {(calendarDaysIn.length == 0) ?

            <div>
            </div>

            :


            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">Vehicle Registration Number</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Event/Host</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Phone Number</TableCell>
                  <TableCell align="center">Time In</TableCell>
                  <TableCell align="center">Time Out</TableCell>
                  <TableCell align="center">Parking Bay</TableCell>
                  <TableCell align="center">Payment Type</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {calendarDaysIn.map((row) => (
                  <TableRow key={row.carParkspace + row.datein + row.dateout}>
                    <TableCell align="center">
                      <Button style={{ fontSize: 16, margin: 3, padding: 3 }} variant="contained" color="info" endIcon={<DeleteIcon />} onClick={() => deleteDoc(row.carParkspace,row.datein,row.dateout)}>
                        Delete
                      </Button></TableCell>
                    <TableCell align="center">{row.reg}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.evho}</TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">{row.phone}</TableCell>
                    <TableCell align="center">{row.datein.slice(0, -34)}</TableCell>
                    <TableCell align="center">{row.dateout.slice(0, -34)}</TableCell>
                    <TableCell align="center">{row.carParkspace}</TableCell>
                    <TableCell align="center">{row.payment}</TableCell>

                  </TableRow>



                ))}
              </TableBody>
            </Table>
          }
          {/*
    <Link color="secondary" href="#" onClick={addDays} sx={{ mt: 3 }}>
        See more orders
      </Link>
      */}
        </ Paper>
      </React.Fragment>
    </ToggleColorMode>
  );
}
