import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';


export default function SpacesAdd() {

    const [spacesCustomer, setspacesCustomer] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("spacesCustomer");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

    useEffect(() => {
        // storing input name
        localStorage.setItem("spacesCustomer", JSON.stringify(spacesCustomer));
      }, [spacesCustomer]);
  return (
    <React.Fragment>
      <Grid container spacing={2}>
      <Grid item xs={6}>  
          <TextField
            required
            id="spaces"
            name="spaces"
            label="No of Spaces"
            
            autoComplete="No of Spaces"
            variant="standard"
            value={spacesCustomer}
            onChange={e => setspacesCustomer(e.target.value)}
          />
          </Grid>
      </Grid>

    </React.Fragment>
  );
}