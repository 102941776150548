import * as React from 'react';
import "../css/style.css";
import ToggleColorMode from './Dashboard';
import Paper from '@mui/material/Paper';


export default function Confirmation() {

  return (

    <ToggleColorMode>
      <React.Fragment>
        <Paper
          sx={{
            margin: 2,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '80vh',
            overflow: 'auto',

          }}
        >
          <div align="center" style={{ fontSize: 24, fontWeight: 'bold' }}>Confirmation</div>
          <div align="center" style={{ fontSize: 20, padding: 30 }}>Booking information was added to the database. Thank you!</div>
          </ Paper>
</React.Fragment>
</ToggleColorMode>

    
  );
};
