import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';


export default function CustomerAdd() {
    const [nameCustomer, setnameCustomer] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("nameCustomer");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [locationCustomer, setlocationCustomer] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("locationCustomer");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });


    useEffect(() => {
        // storing input name
        localStorage.setItem("nameCustomer", JSON.stringify(nameCustomer));
      }, [nameCustomer]);

      useEffect(() => {
        // storing input name
        localStorage.setItem("locationCustomer", JSON.stringify(locationCustomer));
      }, [locationCustomer]);




 
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={6}>  
          <TextField
            required
            id="Name"
            name="Name"
            label="Name"
            
            autoComplete="Name"
            variant="standard"
            value={nameCustomer}
            onChange={e => doNothing(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            id="Location"
            name="Location"
            label="Location"
            
            autoComplete="Location"
            variant="standard"
            value={locationCustomer}
            onChange={e => setlocationCustomer(e.target.value)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}