import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import WithMarker from './WithMarker';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { db } from "./Login";
import isEmpty from 'lodash.isempty';


const sortType = [
  {
    value: 'Free',
    label: 'Free',
  },
  {
    value: 'Per Hour',
    label: 'Per Hour',
  },
  {
    value: 'Guest or Permit Free',
    label: 'Guest or Permit Free',
  },
  {
    value: 'Elapsed Time',
    label: 'Elapsed Time',
  },
];


const paymentTypes = [
  {
    value: 'Free',
    label: 'Free',
  },
  {
    value: 'Per Hour',
    label: 'Per Hour',
  },
  {
    value: 'Guest or Permit Free',
    label: 'Guest or Permit Free',
  },
  {
    value: 'Elapsed Time',
    label: 'Elapsed Time',
  },
];

const hardbays = [
  {
    value: 'Bradfield',
    label: 'Bradfield',
  },
]



export default function UploadBaysBooking() {



  const [filteredParkingbay, setfilteredParkingbay] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("filteredParkingbay");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [carParkScheme, setcarParkScheme] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParkScheme");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carParkSchemew, setcarParkSchemew] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParkSchemew");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [carBays, setcarBays] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carBays");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


    const [carPark, setcarPark] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("carPark");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [carParklocation, setcarParklocation] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("carParklocation");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [carParkspaces, setcarParkspaces] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("carParkspaces");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });


      const [adminName, setadminName] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("adminName");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [adminEmail, setadminEmail] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("adminEmail");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [adminNo, setadminNo] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("adminNo");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });
      const [carparkSelected, setcarparkSelected] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("carparkSelected");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });


      const [carparkSelectedName, setcarparkSelectedName] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("carparkSelectedName");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });


      const [baysSeet, setbaysSeet] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("baysSeet");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

    
    
      useEffect(() => {
        // storing input name
        localStorage.setItem("carparkSelected", JSON.stringify(carparkSelected));
      }, [carparkSelected]);
    

function doNothing() {
  console.log(baysSeet);

  {baysSeet.forEach(d => 
    {
    db.collection('calendar').doc(d.id + d.datein+d.dateout).set({
      parking: carparkSelected,
      carPark: carparkSelectedName,
      carParkspace: d.id,
      datein: d.datein,
      dateout: d.dateout,
      name: d.name,
      reg: d.reg,
      email: d.email,
      evho: d.evho,
      phone: d.phone,
      payment: d.payment
      
  });

  db
  .collection("mail")
  .add({
    to: d.email,
    message: {
      subject: "Booking Confirmation for" + carparkSelectedName,
      text: "This is the plaintext section of the email body.",
      html: "Dear " + d.name + ","
        + "<br>You have a new booking for " + carparkSelectedName + " with the follwoing details:</br>"
        + "<br></br>"
        + "<br>Parking Bay: " + d.id + " " + "</br>"
        + "<br>Arrive: " + d.datein + " " + "</br>"
        + "<br>Depart: " + d.dateout + " " + "</br>"
        + "<br>Event or Host Name: " + d.evho + "</br>"
        + "<br>Vehicle Registration No.: " + d.reg + "</br>"
        + "<br></br>"
        + "<br>Thank you!" + "<br>"
    },
  })
  .then(() => console.log("Queued email for delivery!"));

}
    )};


};


function sortOut() {
  const listOfObjects = [];
  filteredParkingbay.forEach(element => {
    if (element['icon'] == "./images/m2.png") {
      var singleObj = {}
      singleObj['id'] = element['id'];
      singleObj['week'] = carParkScheme;
      singleObj['end'] = carParkSchemew;
      listOfObjects.push(singleObj);
    }
  });
  setbaysSeet(listOfObjects);
}
useEffect(() => {
  // storing input name
  doNothing();
}, []);






  return (
    <React.Fragment>

        <Typography variant="h6" gutterBottom align="center" >
          Finished!
        </Typography>
        <Typography variant="subtitle1" align="center" >
          A new booking has been added to the database and confirmation e-mail sent.
        </Typography>
     

    </React.Fragment>
  );
}