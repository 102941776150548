import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import ToggleColorMode from './Dashboard';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { db } from "./Login";
import { useEffect, useState } from 'react';
import moment, { months } from 'moment';
import { List } from '@mui/material';
import Chip from '@mui/material/Chip';
import SearchIcon from '@mui/icons-material/Search';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { ConstructionOutlined } from '@mui/icons-material';




// Generate Order Data
function createData(vrn_cl) {
  var array = [vrn_cl, 'b', 'c'];
  var get_it = [{[array[0]]: vrn_cl}];
  return get_it;
}


var rowsH = [];
function preventDefault(event) {
  event.preventDefault();
}

function addDays() {
  var datenow = new Date(Date.now() + (3600 * 1000 * 24 * 30));
  var finaldate = datenow.toLocaleDateString();
  console.log(finaldate);
  return (finaldate)
}


export default function Lottery() {
  const [value, setValue] = React.useState('');
  const [loading, setLoading] = useState(true);
  const [nots, setNots] = useState(false);
  const [tablen, setShowTable] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [globalSort, setglobalSort] = useState(0);
  var [sortType, setSortType] = useState([]);
  const [admin, setAdmin] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("admin");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });




  const getMyDay = (valueH) => {
    var dateHe = new Date(valueH);
    setSelectedDate(dateHe);
  }

  const converMonth = (valueZ, valueDay) => {
    //console.log((String(value.toDate().getMonth()) + String(value.toDate().getDay())));
    var monthD = "";
    var monthHere = "";
    var finalDate = "";
    monthD = valueDay.toDate().getMonth();
    var dayD = valueDay.toDate().getDate();
    var dayHere = "";

    if (dayD == "1") {
      dayHere = "Mon";
    }
    if (dayD == "2") {
      dayHere = "Tue";
    }
    if (dayD == "3") {
      dayHere = "Wed";
    }
    if (dayD == "4") {
      dayHere = "Thu";
    }
    if (dayD == "5") {
      dayHere = "Fri";
    }
    if (dayD == "6") {
      dayHere = "Sat";
    }
    if (dayD == "0") {
      dayHere = "Sun";
    }

    if (monthD == "0") {
      monthHere = "January";
    };
    if (monthD == "1") {
      monthHere = "February";
    };
    if (monthD == "2") {
      monthHere = "March";
    };
    if (monthD == "3") {
      monthHere = "April";
    };
    if (monthD == "4") {
      monthHere = "May";
    };
    if (monthD == "5") {
      monthHere = "June";
    };
    if (monthD == "6") {
      monthHere = "July";
    };
    if (monthD == "7") {
      monthHere = "August";
    };
    if (monthD == "8") {
      monthHere = "September";
    };
    if (monthD == "9") {
      monthHere = "October";
    };
    if (monthD == "10") {
      monthHere = "November";
    };
    if (monthD == "11") {
      monthHere = "December";
    };

    finalDate = "Date " + monthHere + "," + dayD + " Time " + String(valueZ.toDate().getHours()) + ":00";
    //console.log(finalDate);
    return (
      finalDate
    );
  }

  const converMonthOut = (valueZO, valueDayO) => {
    //console.log((String(value.toDate().getMonth()) + String(value.toDate().getDay())));
    var monthDO = "";
    var monthHereO = "";
    var finalDateO = "";
    monthDO = valueDayO.toDate().getMonth();
    var dayDO = valueDayO.toDate().getDate();
    var dayHereO = "";

    if (dayDO == "1") {
      dayHereO = "Mon";
    }
    if (dayDO == "2") {
      dayHereO = "Tue";
    }
    if (dayDO == "3") {
      dayHereO = "Wed";
    }
    if (dayDO == "4") {
      dayHereO = "Thu";
    }
    if (dayDO == "5") {
      dayHereO = "Fri";
    }
    if (dayDO == "6") {
      dayHereO = "Sat";
    }
    if (dayDO == "0") {
      dayHereO = "Sun";
    }

    if (monthDO == "0") {
      monthHereO = "January";
    };
    if (monthDO == "1") {
      monthHereO = "February";
    };
    if (monthDO == "2") {
      monthHereO = "March";
    };
    if (monthDO == "3") {
      monthHereO = "April";
    };
    if (monthDO == "4") {
      monthHereO = "May";
    };
    if (monthDO == "5") {
      monthHereO = "June";
    };
    if (monthDO == "6") {
      monthHereO = "July";
    };
    if (monthDO == "7") {
      monthHereO = "August";
    };
    if (monthDO == "8") {
      monthHereO = "September";
    };
    if (monthDO == "9") {
      monthHereO = "October";
    };
    if (monthDO == "10") {
      monthHereO = "November";
    };
    if (monthDO == "11") {
      monthHereO = "December";
    };

    finalDateO = "Date " + monthHereO + "," + dayDO + " Time " + String(valueZO.toDate().getHours()) + ":00";
    //console.log(finalDate);
    return (
      finalDateO
    );
  }

  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time)
    )
  }
  async function deleteDoc(docId) {

    db.collection("calendar").doc(docId).delete();
    await sleep(500);
    window.location.reload(true);

  }

  function calculateBays() {
    var totalValue = 19;

    /*
        db
            .collection("mail")
            .add({
              to: "mihairobert.tudor@gmail.com",
              message: {
                subject: "Guys, say Hello to Firebase!",
                text: "This is the plaintext section of the email body.",
                html: "TESTING OUR WAY TO AUTOMATIC E-MAIL CONFIRMATIONS",
              },
            })
            .then(() => console.log("Queued email for delivery!"));
        */
    return (String(totalValue - rowsH.length));
  }

  function copyArray() 
  {
  
    const sortedRQ = rowsH.sort((a, b) => (b.counter > a.counter) ? 1 : -1);
    //
    rowsH = sortedRQ;

    
    let uniq = {};
    
    return rowsH.filter(obj => !uniq[obj.id] && (uniq[obj.id] = true));  
  }


  function filterSort_teste() 
  {
  
    const sortedRQ = sortType.sort((a, b) => (b.counter > a.counter) ? 1 : -1);
    //
    sortType = sortedRQ;
    let uniq = {};
    var get_iajl = sortType.filter(obj => 
      
        //console.log("item" + item);
        //console.log("index" + index);
        //console.log("sortType.indexOf(item)" + sortType.indexOf(item));
        //console.log("sortType.indexOf(item) === index" + sortType.indexOf(item)==index);
        !uniq[obj.id] && (uniq[obj.id] = true));
       
        var final_arr = [];

get_iajl.map((row) => {

  var counter_final = 0;
  var user_final = row.email_cl;
  
  get_iajl.map((row2) => {
    if (row2.email_cl == user_final)
    {
      counter_final = counter_final + row2.counter;
      //sortType.push(row2);
      final_arr.push({
        id: row2.id,
        email_cl: row2.email_cl,
        counter: counter_final,
        name_cl: row2.name_cl,
        vrn_cl: row2.vrn_cl,
        dateday: row2.dateday,
        dateOutday: row2.dateOutday,
        timein: row2.timein,
        timeout: row2.timeout,
        phone: row2.phone,
        eventname: row2.eventname,
        paymenttype: row2.paymenttype,
        //bay58: (val3c == true ? "fuzzy" : val3),
        bay: row2.bay,
      });
    }
  }
  );
  
  //setSortType(final_arr);
  //console.log("counter_final" + counter_final);
}
);
//console.log(final_arr);



const sortedRQA = final_arr.sort((a, b) => (b.counter < a.counter) ? 1 : -1);
//
final_arr = sortedRQA;


let uniqA = {};

var get_iajlA = final_arr.filter(obj => 
  
    //console.log("item" + item);
    //console.log("index" + index);
    //console.log("sortType.indexOf(item)" + sortType.indexOf(item));
    //console.log("sortType.indexOf(item) === index" + sortType.indexOf(item)==index);
    !uniqA[obj.email_cl] && (uniqA[obj.email_cl] = true));


    //console.log(get_iajlA);
    setSortType(get_iajlA);
    //console.log(sortType);

setShowTable(false);
  }

  function doNothing() {
  }

  function sortUser() {
    
    //const randomA = copyArray();

    //const sortedR = randomA.sort((a, b) => (b.counter < a.counter) ? 1 : -1);
    //console.log(sortedR);
    //setSortType(sortedR);
    //setSortType(sortedR);
    //console.log(sortedR);
    //setShowTable(false);
    var bay_no = 0;

    sortType.map((row) => {
  
    bay_no++;
    var bay_value = '';
    var bay_fire = 55 + bay_no;
    (bay_no == 1) ? bay_value = "V1": '';
    (bay_no == 2) ? bay_value = "V2": '';
    (bay_no == 3) ? bay_value = "V3": '';
    (bay_no == 4) ? bay_value = "V4": '';
    (bay_no == 5) ? bay_value = "V5": '';
    (bay_no == 6) ? bay_value = "V6": '';
    (bay_no == 7) ? bay_value = "V7": '';
    (bay_no == 8) ? bay_value = "V8": '';
    (bay_no == 9) ? bay_value = "V9": '';
    (bay_no == 10) ? bay_value = "V10": '';
    (bay_no == 11) ? bay_value = "V11": '';
    (bay_no == 12) ? bay_value = "V12": '';
    (bay_no == 13) ? bay_value = "V13": '';
    (bay_no == 14) ? bay_value = 'V14': '';
    (bay_no == 15) ? bay_value = "V15": '';
    (bay_no == 16) ? bay_value = "V16": '';
    (bay_no == 17) ? bay_value = "V17": '';
    (bay_no == 18) ? bay_value = "V18": '';
    (bay_no == 19) ? bay_value = "V19": '';



    if (bay_no < 20)
    {
    db
    .collection("mail")
    .add({
      to: row.email_cl,
      message: {
        subject: "Cambridge Science Park: Your next week parking details",
        text: "",
        html: "Dear "+ row.name_cl + "," 
        +"<br>Automatically, you have been assigned a parking bay for Cambridge Science Park - The Bradfield Centre.</br>" 
        + "<br> The information you will need is listed bellow:</br>"
        + "<br>Date In: " + row.dateday + "</br>"
        
        +"<br>Day Out: " + row.dateOutday + " " + "</br>"
        
        +"<br>Event or Host Name: " + "Parking Lottery" + "</br>"
        +"<br>Vehicle Registration No.: " + row.vrn_cl + "</br>"
        +"<br>Bay Number: " + bay_value + "</br>"
        + "<br></br>"
        +"<br>Thank you!" + "<br>"
      },
    }).then(() => console.log("Queued email for delivery!"));
    var what_is = ["bay"+bay_fire.toString()];
    //console.log(what_is[0]);

      db.collection('calendar').doc().set({
        dateday: row.dateday,
        dateOutday: row.dateOutday,
        timein: row.timein,
        timeout: row.timeout,
        email: row.email_cl,
        phone: row.phone,
        fullname: row.name_cl,
        vrn: row.vrn_cl,
        eventname: row.eventname,
        paymenttype: row.paymenttype,
        //bay57: (val2c == true ? "fuzzy" : val2),
        [what_is[0]]: false,
        admin: admin,
    });
  }
  });
  

  setNots(true);
}

  const getData = async () => {
    var rowsTeste = [];
    
    db
      .collection("calendar")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
        
          if (doc.data().admin == admin) {
            
          var user_to_sort = doc.data().email;
          var user_bay = '';
          var user_bay_s = '';
          var sort = 0;
          var lastweekS = new moment().subtract(1, 'weeks').startOf('week');
          var lastweekE = new moment().subtract(1, 'weeks').endOf('week');
          var finalUser = '';
          //console.log(":::::::::::::::::lastweek:::::::::::::" + lastweekE);
          //console.log("today"+lastweekE);

          var yeats = doc.data().dateday.toDate().getFullYear();
          var monts = doc.data().dateday.toDate().getMonth();
          var dayss = doc.data().dateday.toDate().getDate();
          var hoursW = doc.data().timein.toDate().getHours();
          var dateinNow = moment([yeats, monts, dayss, hoursW, 0, 0]);
          //console.log(":::::::::::::::::dateinNow:::::::::::::" + dateinNow);

          //var converteddateinNow = new moment().format(dateinNow);
          //console.log("converteddateinNow" + converteddateinNow);
          
          var yeatv = doc.data().dateOutday.toDate().getFullYear();
          var montv = doc.data().dateOutday.toDate().getMonth();
          var daysv = doc.data().dateOutday.toDate().getDate();
          var hoursVW = doc.data().timeout.toDate().getHours();
          var dateoutNow = moment([yeatv, montv, daysv, hoursVW, 0, 0]);
          var takeIt = 0;
          
          //var converteddateoutNow = new moment().format(dateoutNow);
          //console.log("converteddateoutNow" + converteddateinNow + ":::::::::::" +converteddateoutNow );
          //console.log(lastweekS);
          //console.log(lastweekS < dateinNow && lastweekE > dateoutNow);
          
          
          setglobalSort(0);
          if (
            lastweekS < dateinNow && lastweekE > dateoutNow
          ) {

            //console.log(dateoutNow.diff(dateinNow, 'hours', true));
            //console.log("FIRST SORT");
            var totalHours = 0;
            
            //console.log("user_to_sort     " + user_to_sort);
            db
            .collection("calendar")
            .get()
            .then(function (querySnapshot) {
              querySnapshot.forEach(function (doc2) {
                if(user_to_sort == doc2.data().email
                &&
                (lastweekS < dateinNow && lastweekE > dateoutNow)
                )
                {
                  
                  //console.log('lastweekS' + lastweekS);
                  //console.log('dateinNow' + dateinNow);
                  //console.log('lastweekE' + lastweekE);
                  
                  //console.log('(lastweekS < dateinNow && lastweekE > dateoutNow) : ' + (lastweekS < dateinNow && lastweekE < dateoutNow).toString() + doc2.id);
                  
                  //console.log(doc2.id);
                  //console.log("doc2.data().email     "+doc2.data().email);
                  //console.log("SECOND SORT");
                  var yeats2 = doc2.data().dateday.toDate().getFullYear();
                  var monts2 = doc2.data().dateday.toDate().getMonth();
                  var dayss2 = doc2.data().dateday.toDate().getDate();
                  var hoursW2 = doc2.data().timein.toDate().getHours();
                  var dateinNow2 = moment([yeats2, monts2, dayss2, hoursW2, 0, 0]);

                  
                  var yeatv2 = doc2.data().dateOutday.toDate().getFullYear();
                  var montv2 = doc2.data().dateOutday.toDate().getMonth();
                  var daysv2 = doc2.data().dateOutday.toDate().getDate();
                  var hoursVW2 = doc2.data().timeout.toDate().getHours();
                  var dateoutNow2 = moment([yeatv2, montv2, daysv2, hoursVW2, 0, 0]);


                  //if (doc.id != doc2.id)
                  if(true)
                  {
                    console.log(doc.data().email);
                  totalHours = dateoutNow.diff(dateinNow, 'hours', true);
                  
                  sortType.push({
                    id: doc.id,
                    email_cl: doc.data().email,
                    counter: totalHours,
                    name_cl: doc.data().fullname,
                    vrn_cl: doc.data().vrn,
                    dateday: moment().add(0, 'weeks').startOf('week').toDate(),
                    dateOutday: moment().add(0, 'weeks').endOf('week').toDate(),
                    timein: moment([yeatv2, 1, 1, 0, 0, 0]).toDate(),
                    timeout: moment([yeatv2, 1, 1, 0, 0, 0]).toDate(),
                    phone: doc.data().phone,
                    eventname: doc.data().eventname,
                    paymenttype: doc.data().paymenttype,
                    //bay58: (val3c == true ? "fuzzy" : val3),
                    bay: takeIt%10 + Math.random() %10,
                  });
                  //console.log(sortType);
                  filterSort_teste();
                  
                  }
                  
                }
              });
            }).catch(function (error) {
                console.log("Error getting documents: ", error);
              });

            
            /*
            db
              .collection("calendar")
              .get()
              .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc2) {
                  var user_to_sortW = doc2.data().email;
                  var sortW = 0;
                  var lastweekSW = new moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD HH:mm:ss');
                  var lastweekEW = new moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD HH:mm:ss')
                  var yeatsW = doc2.data().dateday.toDate().getFullYear();
                  var montsW = doc2.data().dateday.toDate().getMonth();
                  var dayssW = doc2.data().dateday.toDate().getDate();
                  var hoursW = doc2.data().timein.toDate().getHours();
                  //var dateinNowW = String(yeatsW) + "-" + String(montsW) + "-" + String(dayssW) + " "+ String(hoursW) + ":00:00";
                  //var converteddateinNowW = moment().format('YYYY-MM-DD HH:mm:ss');
                  var converteddateinNowW = moment([yeatsW, montsW, dayssW, hoursW, 0, 0]);
                  //console.log('::::::::::::converteddateinNowW::::::::::::: ' + converteddateinNowW);
                  var yeatvW = doc2.data().dateOutday.toDate().getFullYear();
                  var montvW = doc2.data().dateOutday.toDate().getMonth();
                  var daysvW = doc2.data().dateOutday.toDate().getDate();
                  var hoursVW = doc2.data().timeout.toDate().getHours();
                  //var dateoutNowW = String(yeatvW) + "-" + String(montvW) + "-" + String(daysvW) + " " +String(hoursVW) + ":00:00";
                  var converteddateoutNowW = moment([yeatvW, montvW, daysvW, hoursVW, 0, 0]);
                  //converteddateoutNowW = dateoutNowW;


                  var user_to_sortQQ = doc2.data().email;
                  var sortQQ = 0;
                  var lastweekSQQ = new moment().subtract(1, 'weeks').startOf('week');
                  var lastweekEQQ = new moment().subtract(1, 'weeks').endOf('week');


                  var nextweekSQQ = new moment().add(1, 'weeks').startOf('week');
                  var nextweekEQQ = new moment().add(1, 'weeks').endOf('week');

                  //console.log( nextweekSQQ + ":::::::::::::::::" + nextweekEQQ);

                  //console.log("lastweek" + lastweekS);
                  //console.log("today"+lastweekE);

                  var yeatsQQ = doc2.data().dateday.toDate().getFullYear();
                  var montsQQ = doc2.data().dateday.toDate().getMonth() + 1;
                  var dayssQQ = doc2.data().dateday.toDate().getDate();
                  var dateinNowQQ = String(yeatsQQ) + "-" + String(montsQQ) + "-" + String(dayssQQ);

                  var converteddateinNowQQ = new moment().format(dateinNowQQ);
                  //console.log("converteddateinNow" + converteddateinNow);

                  var yeatvQQ = doc2.data().dateOutday.toDate().getFullYear();
                  var montvQQ = doc2.data().dateOutday.toDate().getMonth() + 1;
                  var daysvQQ = doc2.data().dateOutday.toDate().getDate();
                  var dateoutNowQQ = String(yeatvQQ) + "-" + String(montvQQ) + "-" + String(daysvQQ);
                  
                  var converteddateoutNowQQ = new moment().format(dateoutNowQQ);



                  if (
                    lastweekSQQ < converteddateinNowQQ && lastweekEQQ > converteddateoutNowQQ
                  ) {

                                        if (user_to_sort == doc2.data().email) {
                      //console.log(user_to_sort + doc2.data().email)
                      //console.log(":::::::::HOURS:::::::::" + converteddateoutNowW.diff(converteddateinNowW, 'hours', true));
                      finalUser = user_to_sort;
                      //console.log(takeIt);
                      takeIt = takeIt + converteddateoutNowW.diff(converteddateinNowW, 'hours', true);
                      //setglobalSort(takeIt);
                      sortQQ = sortQQ + 1;
                      rowsTeste.push({
                        id: finalUser,
                        email_cl: finalUser,
                        counter: takeIt,
                        name_cl: doc2.data().fullname,
                        vrn_cl: doc2.data().vrn,


                        dateday: moment(nextweekSQQ).toDate(),
                        dateOutday: moment(nextweekEQQ).toDate(),
                        timein: moment().toDate(),
                        timeout: moment().toDate(),
                      
                        phone: doc2.data().phone,
                       
                        eventname: "Blue Mesh",
                        paymenttype: "Team Member",
                        //bay58: (val3c == true ? "fuzzy" : val3),
                        bay: takeIt%10 + Math.random() %10,

                      });

                      const sortedRQ = rowsTeste.sort((a, b) => (b.counter > a.counter) ? 1 : -1);
                      //
                      rowsTeste = sortedRQ;
                      
                      //

                      rowsH.push({

                        id: finalUser,
                        email_cl: finalUser,
                        counter: takeIt,
                        name_cl: doc2.data().fullname,
                        vrn_cl: doc2.data().vrn,


                        dateday: moment(nextweekSQQ).toDate(),
                        dateOutday: moment(nextweekEQQ).toDate(),
                        timein: moment().toDate(),
                        timeout: moment().toDate(),
                      
                        phone: doc2.data().phone,
                       
                        eventname: "Blue Mesh",
                        paymenttype: "Team Member",
                        //bay58: (val3c == true ? "fuzzy" : val3),
                        bay: takeIt%10 + Math.floor(Math.random() *100) %10,


                      });
                      copyArray();
                    }
                  }

                });
              })
              .catch(function (error) {
                console.log("Error getting documents: ", error);
              });

              */
          };


          
          
          

          
          //getMyslice(rowsTeste);
          
      }});
      
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
    

      
    
  }




  useEffect(() => {
    if (tablen) {
      rowsH = [];
      getData();
      // console.log(rowsH);
      setLoading(false);
    };

    //console.log(rows);
  });

  return (
    <ToggleColorMode>
      <React.Fragment>
        <Paper
          sx={{
            margin: 2,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '70vh',
            overflow: 'auto',

          }}
        >
          <div align="center" style={{ fontSize: 20, fontWeight: 'bold' }}>Lottery System</div>
          <div  align="center"  style={{ margin: '20px', }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={1} style={{ width: '250px', }}>
                <div style={{ margin: "14px" }}>
                
                { (nots) ?
                  <Chip color="warning" icon={<EmojiEventsIcon />} label="Notifications Sent" onClick={() => doNothing()} />

                  :

                  <Chip color="success" icon={<EmojiEventsIcon />} label="Start Lottery" onClick={() => sortUser()} />
                }
                </div>
              </Stack>
            </LocalizationProvider>
          </div>
          
          {(tablen) ?

            <div>
            </div>

            :

            <Table size="small">
              <TableHead>
                <TableRow>

                  <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>User</TableCell>
                  <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Total Hours</TableCell>


                </TableRow>
              </TableHead>
              <TableBody>
                {sortType.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{row.email_cl}</TableCell>
                    <TableCell align="center">{row.counter}</TableCell>
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
          {/*
    <Link color="secondary" href="#" onClick={addDays} sx={{ mt: 3 }}>
        See more orders
      </Link>
      */}
        </ Paper>
      </React.Fragment>
    </ToggleColorMode>
  );
}
