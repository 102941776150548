import { useAuthState } from "react-firebase-hooks/auth";
import "../css/style.css";
import { auth, db, logout } from "./Login";
import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import DashboardSec from "./Dashboard";



const Ampm = props => {

  const savedampm = JSON.parse(localStorage.getItem('dataampm'));
  console.log(savedampm);
  const keep = JSON.parse(localStorage.getItem('data'));

  const [dataampm, setDataampm] = useState(savedampm || "");
  console.log(dataampm);
  //const keep = props.location.data;
  //console.log(keep);

  const selecampm = (value) => {
    var ampmnew = value;
    localStorage.setItem('dataampm', JSON.stringify(ampmnew));
    //console.log(nee);
    //setData(nee);
    props.history.push("/beaconselect");
  };


  //console.log(props.location.data);
  return (
   
    <DashboardSec>
    <div> <p>Selected Date: {keep}</p>
            <button class = "dateselect__btn" onClick={() => selecampm("AM")}>
              AM 
            </button>
    
            <button class = "dateselect__btn" onClick={() => selecampm("PM")}>
              PM
            </button>

    
    </div>
    
          </DashboardSec>
    
  );
}
export default Ampm;