import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';


export default function AdminInfo() {

  const [filteredParkingbay, setfilteredParkingbay] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("filteredParkingbay");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  

  const [carParkScheme, setcarParkScheme] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParkScheme");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carParkSchemew, setcarParkSchemew] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParkSchemew");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

    const [adminName, setadminName] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("adminName");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [list_bays_new, setlist_bays_new] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("list_bays_new");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [adminEmail, setadminEmail] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("adminEmail");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [adminNo, setadminNo] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("adminNo");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [baysSeet, setbaysSeet] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("list_bays_new");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });



    useEffect(() => {
        // storing input name
        localStorage.setItem("adminName", JSON.stringify(adminName));
      }, [adminName]);

      useEffect(() => {
        // storing input name
        localStorage.setItem("adminEmail", JSON.stringify(adminEmail));
      }, [adminEmail]);

      useEffect(() => {
        // storing input name
        localStorage.setItem("adminNo", JSON.stringify(adminNo));
      }, [adminNo]);
      
console.log(carParkScheme);
console.log(carParkSchemew);
console.log(filteredParkingbay);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={6}>  
          <TextField
            required
            id="adminName"
            name="adminName"
            label="Admin Name"
            
            autoComplete="adminName"
            variant="standard"
            value={adminName}
            onChange={e => setadminName(e.target.value)}
          />
        </Grid>

        <Grid item xs={6}>  
          <TextField
            required
            id="adminEmail"
            name="adminEmail"
            label="Admin Email"
            
            autoComplete="Name"
            variant="standard"
            value={adminEmail}
            onChange={e => setadminEmail(e.target.value)}
          />
        </Grid>

        <Grid item xs={6}>  
          <TextField
            required
            id="adminNo"
            name="adminNo"
            label="Admin Phone"
            
            autoComplete="Name"
            variant="standard"
            value={adminNo}
            onChange={e => setadminNo(e.target.value)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}