import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;

function createMapOptions(maps) {
  // next props are exposed at maps
  // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
  // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
  // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
  // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
  // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
      styles: [{ stylers: [{ 'saturation': -10 }, { 'gamma': 0.1 }, { 'lightness': 1 }, { 'visibility': 'on' }] }]
    },
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT
    },
    mapTypeControl: true
  };
}

const keyAk = process.env.REACT_APP_MAP_KEY;

const MapContainer = ({ children, ...props }) => (
  <Wrapper>
    <GoogleMap
      bootstrapURLKeys={{
        key: 'AIzaSyAHXPTUj2yO7cLM89zIl3o-XMyEqGgEWgA',
      }}
      options={createMapOptions}
      {...props}
    >
      {children}
    </GoogleMap>
  </Wrapper>
);

MapContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

MapContainer.defaultProps = {
  children: null,
};

export default MapContainer;