import React, { useState }  from "react";

class NotFound extends React.Component {
  
  render() {

    return (
  <div className="dateselect">

        <button class = "dateselect__btn" onClick={() => this.props.deleteClient(this.props.index)}>
          AM
        </button>
        <button class = "dateselect__btn"   onClick={() => this.props.deleteClient(this.props.index)}>
          PM
        </button>
        
        <button class = "dateselect__btnc"  onClick={() => this.props.deleteClient(this.props.index)}>
          CONTINUE
        </button>
        
        
  </div>
    );
  }
}

export default NotFound;