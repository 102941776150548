import { useAuthState } from "react-firebase-hooks/auth";
import "../css/style.css";
import { auth, db, logout } from "./Login";
import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import Ampm from "./Ampm";
import Dashboard from "./Dashboard";
import ToggleColorMode from "./Dashboard";



const DateSelect = (props) => {
  //const [user, loading, error] = useAuthState(auth);
  const newDate1 = new Date(Date.now() + ( 3600 * 1000 * 24));
  //var str = newDate1;
  const saveddata = JSON.parse(localStorage.getItem('data'));

  const [data, setData] = useState(saveddata || "");
  
  const newDate = new Date();
 

  useEffect(() => {
    //if ( saveddata !== data) 
    //{localStorage.setItem('data', JSON.stringify(data));}
    //console.log(data);
  });

  useEffect(() => {
    //console.log(data);
    //if(data) {props.history.push({pathname:   "/Ampm", data });}
    
    //const localData = localStorage.getItem('data');
}, [data]);

      
  const newDate2 = new Date(Date.now() + ( 3600 * 1000 * 24 * 2));
      
  const newDate3 = new Date(Date.now() + ( 3600 * 1000 * 24 * 3));
      
  const newDate4 = new Date(Date.now() + ( 3600 * 1000 * 24 * 4));
      
  const newDate5 = new Date(Date.now() + ( 3600 * 1000 * 24 * 5));
      
  const newDate6 = new Date(Date.now() + ( 3600 * 1000 * 24 * 6));
      
  const newDate7 = new Date(Date.now() + ( 3600 * 1000 * 24 * 7));
      
  const newDate8 = new Date(Date.now() + ( 3600 * 1000 * 24 * 8));
      
  const newDate9 = new Date(Date.now() + ( 3600 * 1000 * 24 * 9));
      
  const newDate10 = new Date(Date.now() + ( 3600 * 1000 * 24 * 10));
      
  const newDate11 = new Date(Date.now() + ( 3600 * 1000 * 24 * 11));
      
  const newDate12 = new Date(Date.now() + ( 3600 * 1000 * 24 * 12));
  
      
  const newDate13 = new Date(Date.now() + ( 3600 * 1000 * 24 * 13));
        
  const newDate14 = new Date(Date.now() + ( 3600 * 1000 * 24 * 14));
        
  const newDate15 = new Date(Date.now() + ( 3600 * 1000 * 24 * 15));
        
  const newDate16 = new Date(Date.now() + ( 3600 * 1000 * 24 * 16));
        
  const newDate17 = new Date(Date.now() + ( 3600 * 1000 * 24 * 17));
        
  const newDate18 = new Date(Date.now() + ( 3600 * 1000 * 24 * 18));
        
  const newDate19 = new Date(Date.now() + ( 3600 * 1000 * 24 * 19));
        
  const newDate20 = new Date(Date.now() + ( 3600 * 1000 * 24 * 20));
        
  const newDate21 = new Date(Date.now() + ( 3600 * 1000 * 24 * 21));
        
  const newDate22 = new Date(Date.now() + ( 3600 * 1000 * 24 * 22));
        
  const newDate23 = new Date(Date.now() + ( 3600 * 1000 * 24 * 23));
        
  const newDate24 = new Date(Date.now() + ( 3600 * 1000 * 24 * 24));
        
  const newDate25 = new Date(Date.now() + ( 3600 * 1000 * 24 * 25));
        
  const newDate26 = new Date(Date.now() + ( 3600 * 1000 * 24 * 26));
  
      
  const newDate27 = new Date(Date.now() + ( 3600 * 1000 * 24 * 27));
        
  const newDate28 = new Date(Date.now() + ( 3600 * 1000 * 24 * 28));
        
  const newDate29 = new Date(Date.now() + ( 3600 * 1000 * 24 * 29));
        
  const newDate30 = new Date(Date.now() + ( 3600 * 1000 * 24 * 30));
        


  const selecthour = (datewhat) => {
    var nee = datewhat.toLocaleDateString();
    localStorage.setItem('data', JSON.stringify(nee));
    //console.log(nee);
    //setData(nee);
    props.history.push("/Ampm");
  };
  
  return (
    <ToggleColorMode>
    <div  class = "dateselect_page">
    <div>
    
            <button class = "dateselect__btn" onClick={() => selecthour(newDate)}>
              {newDate.toLocaleDateString()}
            </button>
    
            <button class = "dateselect__btn" onClick={() => selecthour(newDate1)}>
              {newDate1.toLocaleDateString()}
            </button>
    
            <button class = "dateselect__btn" onClick={() => selecthour(newDate2)}>
              {newDate2.toLocaleDateString()}
            </button>

            <button class = "dateselect__btn" onClick={() => selecthour(newDate3)}>
              {newDate3.toLocaleDateString()}
            </button>

            <button class = "dateselect__btn" onClick={() => selecthour(newDate4)}>
              {newDate4.toLocaleDateString()}
            </button>
    
    </div>
    
    <div>
    
            <button class = "dateselect__btn" onClick={() => selecthour(newDate5)}>
              {newDate5.toLocaleDateString()}
            </button>
    
            <button class = "dateselect__btn" onClick={() => selecthour(newDate6)}>
              {newDate6.toLocaleDateString()}
            </button>

            <button class = "dateselect__btn" onClick={() => selecthour(newDate7)}>
              {newDate7.toLocaleDateString()}
            </button>

            <button class = "dateselect__btn" onClick={() => selecthour(newDate8)}>
              {newDate8.toLocaleDateString()}
            </button>
    
            <button class = "dateselect__btn" onClick={() => selecthour(newDate9)}>
              {newDate9.toLocaleDateString()}
            </button>
    
    </div>
    
    <div>
            <button class = "dateselect__btn" onClick={() => selecthour(newDate10)}>
              {newDate10.toLocaleDateString()}
            </button>

                
            <button class = "dateselect__btn" onClick={() => selecthour(newDate11)}>
              {newDate11.toLocaleDateString()}
            </button>
        
            <button class = "dateselect__btn" onClick={() => selecthour(newDate12)}>
              {newDate12.toLocaleDateString()}
            </button>
    
            <button class = "dateselect__btn" onClick={() => selecthour(newDate13)}>
              {newDate13.toLocaleDateString()}
            </button>
    
            <button class = "dateselect__btn" onClick={() => selecthour(newDate14)}>
              {newDate14.toLocaleDateString()}
            </button>
    
    </div>
    
    <div>

        
            <button class = "dateselect__btn" onClick={() => selecthour(newDate15)}>
              {newDate15.toLocaleDateString()}
            </button>

    
            <button class = "dateselect__btn" onClick={() => selecthour(newDate16)}>
      {newDate16.toLocaleDateString()}
    </button>

    <button class = "dateselect__btn" onClick={() => selecthour(newDate17)}>
      {newDate17.toLocaleDateString()}
    </button>

    <button class = "dateselect__btn" onClick={() => selecthour(newDate18)}>
      {newDate18.toLocaleDateString()}
    </button>

    <button class = "dateselect__btn" onClick={() => selecthour(newDate19)}>
      {newDate19.toLocaleDateString()}
    </button>

    
    </div>

<div>
    
    <button class = "dateselect__btn" onClick={() => selecthour(newDate20)}>
      {newDate20.toLocaleDateString()}
    </button>

    <button class = "dateselect__btn" onClick={() => selecthour(newDate21)}>
      {newDate21.toLocaleDateString()}
    </button>

    <button class = "dateselect__btn" onClick={() => selecthour(newDate22)}>
      {newDate22.toLocaleDateString()}
    </button>

    <button class = "dateselect__btn" onClick={() => selecthour(newDate23)}>
      {newDate23.toLocaleDateString()}
    </button>

        
    <button class = "dateselect__btn" onClick={() => selecthour(newDate24)}>
      {newDate24.toLocaleDateString()}
    </button>


</div>

<div>

    <button class = "dateselect__btn" onClick={() => selecthour(newDate25)}>
      {newDate25.toLocaleDateString()}
    </button>

    <button class = "dateselect__btn" onClick={() => selecthour(newDate26)}>
      {newDate26.toLocaleDateString()}
    </button>

    <button class = "dateselect__btn" onClick={() => selecthour(newDate27)}>
      {newDate27.toLocaleDateString()}
    </button>

        
    <button class = "dateselect__btn" onClick={() => selecthour(newDate28)}>
      {newDate28.toLocaleDateString()}
    </button>

    <button class = "dateselect__btn" onClick={() => selecthour(newDate29)}>
      {newDate29.toLocaleDateString()}
    </button>

</div>

<div>


    <button class = "dateselect__btn" onClick={() => selecthour(newDate30)}>
      {newDate30.toLocaleDateString()}
    </button>

</div>
</div>

          </ToggleColorMode>
    
  );
}
export default DateSelect;