import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ToggleColorMode from './Dashboard';
import CarPark from './CarPark';
import CarParkScheme from './CarParkScheme';
import AdminInfo from './AdminInfo';
import FinalAdmin from './FinalAdmin';
import { useEffect, useState } from 'react';
import { db } from "./Login";
import UploadBays from "./UploadBays";
import MapMyComponent from "./MapMyComponent";
import MapMyComponent2 from "./MapMyComponent2";
import Bookings_CL_New from "./newBooking";


const steps = ['Car Park', 'Payment Scheme', 'Admin', 'Review', 'Upload'];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <CarPark />;
    case 1:
      //return <Bookings_CL_New/>;
      //return <CarParkScheme />;
      return <MapMyComponent />;
    case 2:
      return <AdminInfo />;
    case 3:
      return <FinalAdmin />;
    case 4:
      return <UploadBays />;
    default:
      throw new Error('Unknown step');
  }
}

const theme = createTheme();

export default function SuperAdmin() {

  const [parksba, setparksba] = useState([]);


  const [carBays, setcarBays] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carBays");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [carPark, setcarPark] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carPark");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carParklocation, setcarParklocation] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParklocation");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carParkspaces, setcarParkspaces] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParkspaces");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [carParkScheme, setCarParkScheme] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParkScheme");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [adminName, setadminName] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("adminName");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [adminEmail, setadminEmail] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("adminEmail");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [adminNo, setadminNo] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("adminNo");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });
  const [carparkSelected, setcarparkSelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [carparkSelectedName, setcarparkSelectedName] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelectedName");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [baysSeet, setbaysSeet] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("list_bays_new");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [activeStep, setActiveStep] = React.useState(0);




  const getParks = async () => {
    db
      .collection("carParks")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {

          //if ((doc.data().admin == adminA)) 
          if(true){
            //console.log(adminA);
            parksba.push({
              id: doc.id,
              parkName: doc.data().carparkname,
            });
          }
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
    //console.log(baysnm);
    //console.log(baysPark);
  }

  useEffect(() => {
    // storing input name
    getParks();
  });




  function doNothing() {
    window.location.reload(false);
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <ToggleColorMode>
      <React.Fragment>
        <Container component="main" maxWidth="" sx={{ mb: 3 }}>
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}>
            <Typography component="h1" variant="h5" align="center">
              Add Car Park
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? doNothing() : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        Back
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 3, ml: 1 }}
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </Container>
      </React.Fragment>
    </ToggleColorMode>
  );
}