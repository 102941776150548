import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { db } from "./Login";
import { auth } from "./Login";
import { useAuthState } from "react-firebase-hooks/auth";
import moment, { months } from 'moment';
import { DateTime } from "luxon";


import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

import { Button } from 'react-bootstrap';
import Chip from '@mui/material/Chip';
import TimePicker from '@mui/lab/TimePicker';


export default function BookingCarPark() {

  const [calendarDays, setcalendarDays] = useState([]);
  const [calendarDaysIn, setcalendarDaysIn] = useState([]);
  const [dateOutFinal, setdateOutFinal] = useState(new Date());
  const [calendarDaysOut, setcalendarDaysOut] = useState([]);

  const [dateInFinal, setdateInFinal] = useState(new Date());
  const [info2, setInfo2] = useState([]);
  const [timeIn, setTimeIn] = useState('');
  const [timeOut, setTimeOut] = useState('');

  const [dayValue, setdayValue] = useState('');
  const [dayValueOut, setdayValueOut] = useState('');

  const [admin, setAdmin] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("admin");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });
  const [user, loading, error] = useAuthState(auth);

  const [showall, setshowall] = useState(false);

  const [info, setInfo] = useState([]);
  const [filteredParking, setfilteredParking] = useState([]);

  const [filteredParkingbay, setfilteredParkingbay] = useState([]);

  const [selectedDateIn, setselectedDateIn] = useState(new Date());
  const fetchUserName = async () => {
    try {
      const query = await db
        .collection("users")
        .doc(user?.uid)
        .get();
      const userName = query.data();
      //console.log(userName);
      setAdmin(userName.adminA);
      //console.log(userName.adminA);
    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };

  const fetchParking = async () => {
    try {
      db.collection("parking_lots").get().then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.forEach(element => {
          var data = element.data();
          if (data.adminA == admin) {
            setInfo(arr => [...arr, data]);
          };
        });
      });

    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };

  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e]).map(e => arr[e]);
    return unique;
  }

  useEffect(() => {
    // storing input name
    if (!admin) {
      fetchUserName();
    }
    else {
      fetchParking();
    };
  }, []);


  useEffect(() => {
    // storing input name
    fetchParking();
  }, [admin]);


  useEffect(() => {
    // storing input name
    var filt = getUnique(info, "baytype");
    setfilteredParking(filt);
  }, [info]);

  const [carparkSelected, setcarparkSelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  useEffect(() => {

    fetchBays();
    localStorage.setItem("carparkSelected", JSON.stringify(carparkSelected));

    filteredParking.forEach(dat => {
      if (dat.parking == carparkSelected) {
        localStorage.setItem("carparkSelectedName", JSON.stringify(dat.baytype));
        localStorage.setItem("carparkSelectedlat", JSON.stringify(dat.lat));
        localStorage.setItem("carparkSelectedlng", JSON.stringify(dat.lng));
      }
    }
    );

  }, [carparkSelected]);

  function generateAll(valueGot) {
    setcarparkSelected(valueGot);
    setshowall(true);
  };


  function getUnique2(arr, index) {
    const unique = arr
      .map(e => e[index])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e]).map(e => arr[e]);
    return unique;
  }

  useEffect(() => {
    // storing input name

    var filt = getUnique2(info2, "id");
    setfilteredParkingbay(filt);
  }, [info2]);


  const fetchBays = async () => {
    var beac = [];
    try {
      db.collection("beacons").get().then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.forEach(element => {
          var data = element.data();
          data['icon'] = "./images/m1.png";
          if (data.parking == carparkSelected) {
            beac.push(data);
          };
        });
        setInfo2(beac);
      });

    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };


  useEffect(() => {
    // storing input name
    localStorage.setItem("filteredParkingbay", JSON.stringify(filteredParkingbay));
  }, [filteredParkingbay]);


  useEffect(() => {
    
    var dateHea = new Date(dayValue);
    var timeHea = new Date(timeIn);
    
    //console.log((dateHea > 0) && (timeHea > 0) );
    if ((dateHea > 0) && (timeHea > 0)) {
      var selectedDateInYear = dateHea.getFullYear();
      var selectedDateInMonth = (dateHea.getMonth() < 10) ? ('0'+(dateHea.getMonth()+1).toString()) : (dateHea.getMonth()+1);
      var selectedDateInDate = (dateHea.getDate() < 10) ? ('0'+dateHea.getDate().toString()) : dateHea.getDate();
      var selectedDateInHours = (timeHea.getHours() < 10) ? ('0'+timeHea.getHours().toString()) : timeHea.getHours();
      //var convertedselectedDateIn = DateTime([selectedDateInYear, selectedDateInMonth, selectedDateInDate, selectedDateInHours, 0, 0]);
      //var finalIn = convertedselectedDateIn.unix();
      var convertedselectedDateIn = new Date(selectedDateInYear+'-'+
      selectedDateInMonth + '-' + selectedDateInDate+'T'+selectedDateInHours
      +':00:00+01:00');
      setdateInFinal(convertedselectedDateIn);
    }

  }, [dayValue, timeIn, carparkSelected]);





  const sortDateIn = async () => {
    var beac = [];
    try {
      db.collection("calendar").get().then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.forEach(element => {
          var data = element.data();
          if (data.parking == carparkSelected) {
            beac.push(data);
          };
        });
        setcalendarDays(beac);
      });

    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    localStorage.setItem("dateInFinal", JSON.stringify(dateInFinal));

    if (moment(dateInFinal).unix() > 0) {
      sortDateIn();
    }
  }, [dateInFinal]);


  useEffect(() => {
    sortCalendar();

  }, [calendarDays]);


  function sortCalendar() {
    var temp = [];
    var takeOut = [];
    var finalA = [];

    calendarDays.forEach(element => {
      
      var elementdatein = new Date(element.datein);
      var elementdateout = new Date(element.dateout);
      
      if ((dateInFinal.getTime() < elementdatein.getTime())
        || (dateInFinal.getTime() > elementdateout.getTime())) {
        temp.push(element);
      }
      else {
        takeOut.push(element.carParkspace);
      }
    });

    if (takeOut.length > 0) {
      takeOut.forEach(element => {
        var reotate = [];

        if (finalA.length == 0) {
          reotate = temp;
        }
        else {
          var filt = getUnique2(finalA, "carParkspace");
          reotate = filt;
          finalA = [];
        }
        reotate.forEach(elementNew => {
          if (elementNew.carParkspace != element) {
            finalA.push(elementNew);
          }
        });

      });
      var filt = getUnique2(finalA, "carParkspace");

      setcalendarDaysIn(filt);
    }
    else {
      var filt = getUnique2(temp, "carParkspace");

      setcalendarDaysIn(filt);
    }

  }



  useEffect(() => {
   
    var dateHea = new Date(dayValueOut);
    var timeHea = new Date(timeOut);
    
    //console.log((dateHea > 0) && (timeHea > 0) );
    if ((dateHea > 0) && (timeHea > 0)) {
      var selectedDateInYear = dateHea.getFullYear();
      var selectedDateInMonth = (dateHea.getMonth() < 10) ? ('0'+(dateHea.getMonth()+1).toString()) : (dateHea.getMonth()+1);
      var selectedDateInDate = (dateHea.getDate() < 10) ? ('0'+dateHea.getDate().toString()) : dateHea.getDate();
      var selectedDateInHours = (timeHea.getHours() < 10) ? ('0'+timeHea.getHours().toString()) : timeHea.getHours();
      //var convertedselectedDateIn = DateTime([selectedDateInYear, selectedDateInMonth, selectedDateInDate, selectedDateInHours, 0, 0]);
      //var finalIn = convertedselectedDateIn.unix();
      var convertedselectedDateIn = new Date(selectedDateInYear+'-'+
      selectedDateInMonth + '-' + selectedDateInDate+'T'+selectedDateInHours
      +':00:00+01:00');

      setdateOutFinal(convertedselectedDateIn);
    }

  }, [dayValueOut, timeOut, calendarDaysIn]);


  useEffect(() => {
    localStorage.setItem("dateOutFinal", JSON.stringify(dateOutFinal));

    if (moment(dayValueOut).unix() > 0) {
      sortDateOut();
    }
  }, [dateOutFinal]);

  function sortDateOut() {
    var temp = [];
    var takeOut = [];
    var finalA = [];



    calendarDaysIn.forEach(element => {


      var elementdatein = new Date(element.datein);
      var elementdateout = new Date(element.dateout);
      

      if (((dateOutFinal.getTime() < elementdatein.getTime())
        && (dateInFinal.getTime() < elementdatein.getTime())
        &&
        (dateInFinal.getTime() < dateOutFinal.getTime())
      )
        ||
        (
          (dateOutFinal.getTime() > elementdateout.getTime())
          &&
          (dateInFinal.getTime() > elementdateout.getTime())
          &&
          (dateInFinal.getTime() < dateOutFinal.getTime())
        )
      ) {
        temp.push(element);
      }
      else {
        takeOut.push(element.carParkspace);
      }
    });



    if (takeOut.length > 0) {
      takeOut.forEach(element => {
        var reotate = [];

        if (finalA.length == 0) {
          reotate = temp;
        }
        else {
          var filt = getUnique2(finalA, "carParkspace");
          reotate = filt;
          finalA = [];
        }
        reotate.forEach(elementNew => {
          if (elementNew.carParkspace != element) {
            finalA.push(elementNew);
          }
        });

      });
      var filt = getUnique2(finalA, "carParkspace");

      setcalendarDaysOut(filt);
    }
    else {
      var filt = getUnique2(temp, "carParkspace");

      setcalendarDaysOut(filt);
    }

  }



  useEffect(() => {
    var finals = [];
    calendarDaysOut.forEach(element => {
      info2.forEach(elementNew => {
        if (elementNew.id == element.carParkspace) {
          finals.push(elementNew);
        }
      }
      );
    }
    );
    if (calendarDays.length > 0) {
      localStorage.setItem("idbaySelected", JSON.stringify(finals));
    };

    if (calendarDays.length == 0) {
      localStorage.setItem("idbaySelected", JSON.stringify(info2));
    };


  }, [calendarDaysOut]);


  return (
    <React.Fragment>
      <div style={{ padding: '10px', margin: "5px" }}>

        <Grid
          container spacing={4}
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >

          <Grid item xs={5}>
            <TextField fullWidth
              id="carparks"
              required
              select
              label="Car Parks"
              value={carparkSelected}
              onChange={e => generateAll(e.target.value)}
            //helperText="Please select your currenc"
            >
              {filteredParking.map((option) => (
                <MenuItem key={option.parking} value={option.parking}>
                  {option.baytype}
                </MenuItem>
              ))}
            </TextField>
          </Grid>


        </Grid>
      </div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>

        <Grid

          container spacing={4}
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >

          <Grid item xs={3}>
            <DesktopDatePicker
              label="Day In"
              required
              value={dayValue}
              //maxDate={new Date(Date.now() + ( 3600 * 1000 * 24 * 30))}
              onChange={(newValue) => {
                setdayValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />

          </Grid>
          <Grid item xs={3}>
            <TimePicker
              views={['hours']}
              label="Time In"
              required
              value={timeIn}
              onChange={(newValue) => {
                setTimeIn(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={3}>
            <DesktopDatePicker
              label="Day Out"
              required
              value={dayValueOut}
              //maxDate={new Date(Date.now() + ( 3600 * 1000 * 24 * 30))}
              onChange={(newValue) => {
                setdayValueOut(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />

          </Grid>
          <Grid item xs={3}>
            <TimePicker
              views={['hours']}
              label="Time Out"
              required
              value={timeOut}
              onChange={(newValue) => {
                setTimeOut(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>

    </React.Fragment>
  );
}