import React from 'react';
import Header from '../header';
import Navigate from './Navigate';
import styled from 'styled-components';

// component styles
const Wrapper = styled.div`
    @media (min-width: 700px) {
        display: flex;
        top: 0px;
        position: relative;
        height: calc(10%);
        width: 100%;
        flex: auto;
        flex-direction: column;
    }
`;
const Main = styled.main`
flex-direction: column;
left: 100px;
top: 120px;
    position: fixed;
    height: calc(100%);
    width: 100%;
    padding: 1em;
    overflow-y: scroll;
    @media (min-width: 700px) {
        flex: 1;
        margin-left: 300px;
        height: calc(100%);
        width: calc(100%);
    }
`;
const Layout = ({ children }) => {
    return (
    <React.Fragment>
        {/*<Header />*/}
        <Wrapper>
            <Navigate />
            <Main>{children}</Main>
        </Wrapper>
    </React.Fragment>
    );
};
export default Layout;