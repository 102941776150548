import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment, { months } from 'moment';

import { db } from "./Login";


const baysParka = [
  {
    value: 'V1',
    label: 'V1',
    payment: 'Per Hour',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V2',
    label: 'V2',
    payment: 'Per Hour',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V3',
    label: 'V3',
    payment: 'Per Hour',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V4',
    label: 'V4',
    payment: 'Per Hour',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V5',
    label: 'V5',
    payment: 'Per Hour',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V6',
    label: 'V6',
    payment: 'Per Hour',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V7',
    label: 'V7',
    payment: 'Per Hour',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V8',
    label: 'V8',
    payment: 'Per Hour',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V9',
    label: 'V9',
    payment: 'Guest or Permit Free',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V10',
    label: 'V10',
    payment: 'Guest or Permit Free',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V11',
    label: 'V11',
    payment: 'Guest or Permit Free',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V12',
    label: 'V12',
    payment: 'Guest or Permit Free',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V13',
    label: 'V13',
    payment: 'Guest or Permit Free',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V14',
    label: 'V14',
    payment: 'Guest or Permit Free',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V15',
    label: 'V15',
    payment: 'Elapsed Time',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V16',
    label: 'V16',
    payment: 'Elapsed Time',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V17',
    label: 'V17',
    payment: 'Elapsed Time',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V18',
    label: 'V18',
    payment: 'Elapsed Time',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V19',
    label: 'V19',
    payment: 'Elapsed Time',
    start: "Monday",
    end: "Sunday",
  },
];


export default function FinalAdminBooking() {


  const [filteredParkingbay, setfilteredParkingbay] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("filteredParkingbay");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [carParkScheme, setcarParkScheme] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParkScheme");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carParkSchemew, setcarParkSchemew] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParkSchemew");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carBays, setcarBays] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carBays");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });




  const [carPark, setcarPark] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carPark");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carParklocation, setcarParklocation] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParklocation");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carParkspaces, setcarParkspaces] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParkspaces");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [adminName, setadminName] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("adminName");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [adminEmail, setadminEmail] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("adminEmail");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [adminNo, setadminNo] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("adminNo");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });
  const [carparkSelected, setcarparkSelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [carparkSelectedName, setcarparkSelectedName] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelectedName");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [baysSeet, setbaysSeet] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("baysSeet");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [idbaySelected, setidbaySelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("idbaySelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [dateInFinal, setdateInFinal] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("dateInFinal");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [dateOutFinal, setdateOutFinal] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("dateOutFinal");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });



  const [fullName, setfullName] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("fullName");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });



  const [regNo, setregNo] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("regNo");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [emailOwner, setemailOwner] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("emailOwner");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [eventName, seteventName] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("eventName");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [phoneNo, setphoneNo] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("phoneNo");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [paymentType, setpaymentType] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("paymentType");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });





  useEffect(() => {
    // storing input name
    localStorage.setItem("carparkSelected", JSON.stringify(carparkSelected));
  }, [carparkSelected]);

  
  return (
    <React.Fragment>

      
          <Table size="small" >
            <TableHead>
              <TableRow>

              <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Car Park</TableCell>

                <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Bay</TableCell>
                <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Arrive</TableCell>
                <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Depart</TableCell>

                <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Full Name</TableCell>
                <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Reg. No.</TableCell>
                <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Email</TableCell>
                <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Event/Host Name</TableCell>
                <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Phone</TableCell>
                <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Payment</TableCell>


              </TableRow>
            </TableHead>
            <TableBody>
              {baysSeet.map((row) => (
                <TableRow key={row.id}>
                <TableCell align="center">{carparkSelectedName}</TableCell>
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">{row.datein}</TableCell>
                  <TableCell align="center">{row.dateout}</TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  
                  <TableCell align="justify">{row.reg}</TableCell>
                  <TableCell align="justify">{row.email}</TableCell>
                  <TableCell align="justify">{row.evho}</TableCell>
                  <TableCell align="justify">{row.phone}</TableCell>
                  <TableCell align="justify">{row.payment}</TableCell>

                
                </TableRow>
              ))}
            </TableBody>
          </Table>
       

    </React.Fragment>
  );
}