import { useAuthState } from "react-firebase-hooks/auth";
import "../css/style.css";
import { auth, db, logout } from "./Login";
import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import DashboardSec from "./Dashboard";

const BeaconSelect = props => {

  const selectbay = (value) => {

    var beaconsel = value;
    localStorage.setItem('beacons', JSON.stringify(beaconsel));

    props.history.push("/ClientPicker");
  };

  return (
   
    <DashboardSec>
       
    
      <div>
      <p>Selected Date: {JSON.parse(localStorage.getItem('data'))}</p>
      <p>Selected Time: {JSON.parse(localStorage.getItem('dataampm'))}</p>
      </div>

    <div>
            <button class = "dateselect__btn" onClick={() => selectbay("V1")}>
              V1
            </button>
    
            <button class = "dateselect__btn" onClick={() => selectbay("V2")}>
              V2
            </button>
    
            <button class = "dateselect__btn" onClick={() => selectbay("V3")}>
              V3
            </button>
    

    
            <button class = "dateselect__btn" onClick={() => selectbay("V4")}>
              V4
            </button>

    </div>
    
    <div>

    
            <button class = "dateselect__btn" onClick={() => selectbay("V5")}>
              V5
            </button>
    
            <button class = "dateselect__btn" onClick={() => selectbay("V6")}>
              V6
            </button>

            <button class = "dateselect__btn" onClick={() => selectbay("V7")}>
              V7
            </button>
    
            <button class = "dateselect__btn" onClick={() => selectbay("V8")}>
              V8
            </button>
    
            <button class = "dateselect__btn" onClick={() => selectbay("V9")}>
              V9
            </button>

                
            <button class = "dateselect__btn" onClick={() => selectbay("V10")}>
              V10
            </button>

            </div>
    <div>
    
            <button class = "dateselect__btn" onClick={() => selectbay("V11")}>
              V11
            </button>
    
            <button class = "dateselect__btn" onClick={() => selectbay("V12")}>
              V12
            </button>
    
            <button class = "dateselect__btn" onClick={() => selectbay("V13")}>
              V13
            </button>

            <button class = "dateselect__btn" onClick={() => selectbay("V14")}>
              V14
            </button>

            <button class = "dateselect__btn" onClick={() => selectbay("V15")}>
              V15
            </button>
    
    
    </div>


    <div>
    
            <button class = "dateselect__btn" onClick={() => selectbay("V16")}>
              V16
            </button>
    
            <button class = "dateselect__btn" onClick={() => selectbay("V17")}>
              V17
            </button>
    
            <button class = "dateselect__btn" onClick={() => selectbay("V18")}>
              V18
            </button>
                
            <button class = "dateselect__btn" onClick={() => selectbay("V19")}>
              V19
            </button>
    
    
    </div>
    
          </DashboardSec>
    
  );
}
export default BeaconSelect;