import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';


export default function FeesAdd() {
    const [feesCustomer, setfeesCustomer] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("feesCustomer");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });


    useEffect(() => {
        // storing input name
        localStorage.setItem("feesCustomer", JSON.stringify(feesCustomer));
      }, [feesCustomer]);





  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={6}>  
          <TextField
            required
            id="Fees"
            name="Fees"
            label="Fee per Bay[£]"
            
            autoComplete="Name"
            variant="standard"
            value={feesCustomer}
            onChange={e => setfeesCustomer(e.target.value)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}