export function formatPrice(cents) {
  return (cents / 100).toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP"
  });
}

export function rando(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}

export function getFunName() {
  const adjectives = [
    "Bradfield"
  ];

  const nouns = [
    "Center"
  ];

  return `${rando(adjectives)}-${rando(nouns)}`;
}


