import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import Layout from './Layout';
import "../css/style.css";
import DashboardSec from './Dashboard';

const UserForm = (props) => {
  const [state, setState] = useState({
    username: '',
    email: '',
    city: '',
    phone: ''   
  });

  const handleOnSubmit = (event) => {
    event.preventDefault();
    props.history.push('/confirmation');
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
    localStorage.setItem([name], JSON.stringify(value));
    //console.log([name] + value);
  };

  return (
    <DashboardSec>

<div>
      <p>Selected Date: {JSON.parse(localStorage.getItem('data'))}</p>
      <p>Selected Time: {JSON.parse(localStorage.getItem('dataampm'))}</p>
      <p>Selected Parking Bay: {JSON.parse(localStorage.getItem('beacons'))}</p>
      <p>Selected Client: {JSON.parse(localStorage.getItem('clientchosed'))}</p>
      </div>

        <div>
      <Form className="register-form" onSubmit={handleOnSubmit}>
        <Form.Group controlId="carregno">
          <Form.Label>Registration Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Registration Number"
            name="carreg"
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group controlId="visname">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Name"
            name="visitor"
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group controlId="phoneno">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Phone Number"
            name="phone"
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group controlId="emaila">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Email"
            name="email"
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group controlId="inputar">
          <Form.Label>Special Requests</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter Admin Note"
            name="inputar"
            onChange={handleInputChange}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Save Details
        </Button>
      </Form>
    </div>
    </DashboardSec>
    
  );
};

export default UserForm;
