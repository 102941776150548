import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { db } from "./Login";


const baysParka = [
  {
    value: 'V1',
    label: 'V1',
    payment: 'Per Hour',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V2',
    label: 'V2',
    payment: 'Per Hour',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V3',
    label: 'V3',
    payment: 'Per Hour',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V4',
    label: 'V4',
    payment: 'Per Hour',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V5',
    label: 'V5',
    payment: 'Per Hour',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V6',
    label: 'V6',
    payment: 'Per Hour',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V7',
    label: 'V7',
    payment: 'Per Hour',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V8',
    label: 'V8',
    payment: 'Per Hour',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V9',
    label: 'V9',
    payment: 'Guest or Permit Free',
    start: "Monday",
    end: "Friday",
  },
  {
    value: 'V10',
    label: 'V10',
    payment: 'Guest or Permit Free',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V11',
    label: 'V11',
    payment: 'Guest or Permit Free',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V12',
    label: 'V12',
    payment: 'Guest or Permit Free',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V13',
    label: 'V13',
    payment: 'Guest or Permit Free',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V14',
    label: 'V14',
    payment: 'Guest or Permit Free',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V15',
    label: 'V15',
    payment: 'Elapsed Time',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V16',
    label: 'V16',
    payment: 'Elapsed Time',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V17',
    label: 'V17',
    payment: 'Elapsed Time',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V18',
    label: 'V18',
    payment: 'Elapsed Time',
    start: "Monday",
    end: "Sunday",
  },
  {
    value: 'V19',
    label: 'V19',
    payment: 'Elapsed Time',
    start: "Monday",
    end: "Sunday",
  },
];


export default function FinalAdmin() {


  const [filteredParkingbay, setfilteredParkingbay] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("filteredParkingbay");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [carParkScheme, setcarParkScheme] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParkScheme");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carParkSchemew, setcarParkSchemew] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParkSchemew");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carBays, setcarBays] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carBays");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });




  const [carPark, setcarPark] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carPark");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carParklocation, setcarParklocation] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParklocation");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carParkspaces, setcarParkspaces] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParkspaces");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [adminName, setadminName] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("adminName");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [adminEmail, setadminEmail] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("adminEmail");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [adminNo, setadminNo] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("adminNo");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });
  const [carparkSelected, setcarparkSelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [carparkSelectedName, setcarparkSelectedName] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelectedName");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [baysSeet, setbaysSeet] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("list_bays_new");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });



  useEffect(() => {
    // storing input name
    localStorage.setItem("carparkSelected", JSON.stringify(carparkSelected));
  }, [carparkSelected]);



  useEffect(() => {
    // storing input name
    localStorage.setItem("baysSeet", JSON.stringify(baysSeet));
  }, [baysSeet]);


  function sortOut() {
    const listOfObjects = [];
    filteredParkingbay.forEach(element => {
      if (element['icon'] == "./images/m2.png") {
        var singleObj = {}
        singleObj['id'] = element['id'];
        singleObj['week'] = carParkScheme;
        singleObj['end'] = carParkSchemew;

        singleObj['carparkSelectedName'] = carparkSelectedName;
        singleObj['adminName'] = adminName;
        singleObj['adminEmail'] = adminEmail;
        singleObj['adminNo'] = adminNo;
        listOfObjects.push(singleObj);
      }
    });
    setbaysSeet(listOfObjects);
  }
  useEffect(() => {
    // storing input name
    sortOut();
  }, []);



  return (
    <React.Fragment>

      <List disablePadding>

        <ListItem key={carparkSelectedName} sx={{ py: 3, px: 0 }}>

          <Table size="small">
            <TableHead>
              <TableRow>

                <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Car Park Name</TableCell>
                <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Admin Name</TableCell>
                <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Admin Email</TableCell>
                <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Admin Phone</TableCell>


              </TableRow>
            </TableHead>
            <TableBody>

              <TableRow key={carparkSelectedName}>
                <TableCell align="center">{carparkSelectedName}</TableCell>
                <TableCell align="center">{adminName}</TableCell>
                <TableCell align="center">{adminEmail}</TableCell>
                <TableCell align="center">{adminNo}</TableCell>
              </TableRow>

            </TableBody>
          </Table>

        </ListItem>

        <ListItem key={carparkSelectedName} sx={{ py: 3, px: 0 }}>

          <Table size="small">
            <TableHead>
              <TableRow>

                <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Bay</TableCell>
                <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Monday-Friday</TableCell>
                <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Saturday-Sunday</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {baysSeet.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="center">{row.id}</TableCell>
                  <TableCell align="center">{row.week}</TableCell>
                  <TableCell align="center">{row.end}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ListItem>
      </List>

    </React.Fragment>
  );
}