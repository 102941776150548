import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import MapMyComponent from './MapMyComponent';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Slide from '@mui/material/Slide';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { db } from "./Login";
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import { MarkerClusterer } from '@react-google-maps/api';

const paymentTypes = [
  {
    value: 'Free',
    label: 'Free',
  },
  {
    value: 'Per Hour',
    label: 'Per Hour',
  },
  {
    value: 'Guest or Permit Free',
    label: 'Guest or Permit Free',
  },
  {
    value: 'Elapsed Time',
    label: 'Elapsed Time',
  },
];


const startDays = [
  {
    value: 'Monday',
    label: 'Monday',
  },
  {
    value: 'Tuesday',
    label: 'Tuesday',
  },
  {
    value: 'Wednesday',
    label: 'Wednesday',
  },
  {
    value: 'Thursday',
    label: 'Thursday',
  },
  {
    value: 'Friday',
    label: 'Friday',
  },
  {
    value: 'Saturday',
    label: 'Saturday',
  },
  {
    value: 'Sunday',
    label: 'Sunday',
  },
];



const endDays = [
  {
    value: 'Monday',
    label: 'Monday',
  },
  {
    value: 'Tuesday',
    label: 'Tuesday',
  },
  {
    value: 'Wednesday',
    label: 'Wednesday',
  },
  {
    value: 'Thursday',
    label: 'Thursday',
  },
  {
    value: 'Friday',
    label: 'Friday',
  },
  {
    value: 'Saturday',
    label: 'Saturday',
  },
  {
    value: 'Sunday',
    label: 'Sunday',
  },

];




const mapStyles = [
  {
    stylers: [
      {
        hue: '#4da6ff',
      },
      {
        saturation: 5,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        lightness: 30,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
];


const options2 = {
  maxZoom: 17,
  imagePath:
    './images/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
};

function createKey(location) {
  console.log(location)
  return location
};

const containerStyle = {
  width: '100%',
  height: '100%'
};



const options = {
  styles: mapStyles,
};


export default function CarParkScheme() {


  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAHXPTUj2yO7cLM89zIl3o-XMyEqGgEWgA"
  });

  const [info_new, setInfo_new] = useState([]);

  const [bays_new, setBays_new] = useState([]);

  const [list_bays_new, setListBays_new] = useState([]);

  const [filteredParking_new, setfilteredParking_new] = useState([]);



  const [open, setOpen] = React.useState(false);



  const [mapa, setMap] = React.useState(null);

  const [zoomLevel, setzoomLevel] = React.useState('5');



  const onLoad = React.useCallback(function callback(mapa2) {
    //const bounds = new window.google.maps.LatLngBounds(center);
    //mapa2.fitBounds(bounds);
    //setMap(mapa);
  }, [])

  const onUnmount = React.useCallback(function callback(mapa2) {
    setMap(null);
  }, [])

  const onClick = React.useCallback((id,baytype) => {   
    localStorage.setItem("idbaySelected", JSON.stringify(id));
    localStorage.setItem("baytypeSelected", JSON.stringify(baytype));

    }, [])


  const onClickMarker = React.useCallback(function callback(e) {
    setzoomLevel('19');
    console.log(e);
  }, [])


  const [filteredbays, setfilteredbays] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("filteredbays");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [carparkSelectedlat, setcarparkSelectedlat] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelectedlat");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carparkSelectedlng, setcarparkSelectedlng] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelectedlng");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const center = {
    lat: parseFloat(carparkSelectedlat),
    lng: parseFloat(carparkSelectedlng)
  };

  useEffect(() => {
    // storing input name
    localStorage.setItem("carparkSelected", JSON.stringify(carparkSelected));
  }, [carparkSelected]);






  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };


  const [carBays, setcarBays] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carBays");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [adminA, setAdminA] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("adminA");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carParkScheme, setcarParkScheme] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParkScheme");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });



  const [startDay, setstartDay] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("startDay");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [endDay, setendDay] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("endDay");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });



  const [carPark, setcarPark] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carPark");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carParklocation, setcarParklocation] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParklocation");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carParkspaces, setcarParkspaces] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carParkspaces");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [carparkSelected, setcarparkSelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });



  const [idbaySelected, setidbaySelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("idbaySelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [baytypeSelected, setbaytypeSelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("baytypeSelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  useEffect(() => {
    // storing input name
    localStorage.setItem("carparkSelected", JSON.stringify(carparkSelected));
  }, [carparkSelected]);


  useEffect(() => {
    // storing input name
    localStorage.setItem("carPark", JSON.stringify(carPark));
  }, [carPark]);

  useEffect(() => {
    // storing input name
    localStorage.setItem("carParklocation", JSON.stringify(carParklocation));
  }, [carParklocation]);

  useEffect(() => {
    // storing input name
    localStorage.setItem("carParkspaces", JSON.stringify(carParkspaces));
  }, [carParkspaces]);

  useEffect(() => {
    // storing input name
    localStorage.setItem("list_bays_new", JSON.stringify(list_bays_new));
  }, [list_bays_new]);

  const fetchBays_new = async () => {
    try {
      console.log("Getting firebase info");
      console.log(info_new);
      db.collection("beacons").get().then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.forEach(element_new => {
          var data_new = element_new.data();
          if (data_new.parking == carparkSelected) {
            setInfo_new(arr => [...arr, data_new]);
          };
        });
      });

    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };


  useEffect(() => {
    // storing input name
    fetchBays_new();
  }, []);

  if (filteredParking_new.length > 0) {
    filteredParking_new.map((location_new) => (
      console.log("location.lat")
    ));
  };


  function getUnique_new(arr_new, index_new) {
    const unique_new = arr_new
      .map(e => e[index_new])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr_new[e]).map(e => arr_new[e]);
    return unique_new;
  }

  useEffect(() => {
    // storing input name
    var filt_new = getUnique_new(info_new, "id");
    setfilteredParking_new(filt_new);
  }, [info_new]);


  function set_bays(bays_selected) {
    const transfer_bay = [];
    transfer_bay.push(bays_selected);
    setBays_new(transfer_bay);
  }

  function get_bays(bays_selected) {
    const transfer_bay = [];
    transfer_bay.push(bays_selected);
    var listOfObjects = [];
    listOfObjects = list_bays_new;
    transfer_bay.forEach(function (entry) {
      var singleObj = {}
      singleObj['id'] = entry;
      singleObj['start'] = startDay;
      singleObj['end'] = endDay;
      singleObj['type'] = carParkScheme;
      listOfObjects.push(singleObj);
    });
    const index = 'id';
    const unique = listOfObjects
      .map(e => e[index])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => listOfObjects[e]).map(e => listOfObjects[e]);

    setListBays_new(unique);
    const zero_now = [];
    setBays_new(zero_now);
  }

  function get_start(start_selected) {

    console.log(bays_new.length);
    handleClickOpen();

  }

  function deleteBay(which_bay) {

    var newList = [];

    newList = list_bays_new.filter((item) => item.id !== which_bay);

    setListBays_new(newList);

  }


  filteredbays.map((location) => (
    createKey(location.id)
  ));


  useEffect(() => {
    // storing input name
    console.log("idbaySelected");
    console.log(idbaySelected);
    console.log("idbaySelected");
  }, [idbaySelected]);

 
  return isLoaded  ? (

    <React.Fragment>
      <Grid
        container spacing={4}
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >


        <Grid item xs={6}>
        
          {
            (bays_new.length > 0)

              ?

              <TextField fullWidth
                id="bays"
                required
                select
                label="Bays"
                value={bays_new[0]}
                onChange={e => set_bays(e.target.value)}
              //helperText="Please select your currenc"
              >
                {filteredParking_new.map((option) => (
                  <MenuItem key={option.baytype} value={option.baytype}>
                    {option.baytype}
                  </MenuItem>
                ))}
              </TextField>
              :

              <TextField fullWidth
                error
                id="bays"
                required
                select
                label="Bays"
                value={bays_new[0]}
                onChange={e => set_bays(e.target.value)}
              //helperText="Please select your currenc"
              >
                {filteredParking_new.map((option) => (
                  <MenuItem key={option.baytype} value={option.baytype}>
                    {option.baytype}
                  </MenuItem>
                ))}
              </TextField>

          }

        </Grid>

        {
          (carParkScheme.length > 0)

            ?

            <Grid item xs={6}>
              <TextField fullWidth
                id="paymentType"
                required
                select
                label="Select"
                value={carParkScheme}
                onChange={e => setcarParkScheme(e.target.value)}

              >
                {paymentTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            :

            <Grid item xs={6}>
              <TextField fullWidth
                error
                id="paymentType"
                required
                select
                label="Select"
                value={carParkScheme}
                onChange={e => setcarParkScheme(e.target.value)}

              >
                {paymentTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

        }


        {(startDay.length > 0)

          ?

          <Grid item xs={6}>
            <TextField fullWidth
              id="startDay"
              required
              select
              label="Start Day"
              value={startDay}
              onChange={e => setstartDay(e.target.value)}
            //helperText="Please select your currenc"
            >
              {startDays.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          :

          <Grid item xs={6}>
            <TextField fullWidth
              error
              id="startDay"
              required
              select
              label="Start Day"
              value={startDay}
              onChange={e => setstartDay(e.target.value)}
            //helperText="Please select your currenc"
            >
              {startDays.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

        }

        {(endDay.length > 0)



          ?


          <Grid item xs={6}>
            <TextField fullWidth
              id="endDay"
              required
              select
              label="End Day"
              value={endDay}
              onChange={e => setendDay(e.target.value)}
            //helperText="Please select your currenc"
            >
              {endDays.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          :

          <Grid item xs={6}>
            <TextField fullWidth
              error
              id="endDay"
              required
              select
              label="End Day"
              value={endDay}
              onChange={e => setendDay(e.target.value)}
            //helperText="Please select your currenc"
            >
              {endDays.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

        }


      </Grid>
      {
        ((bays_new.length > 0) && (carParkScheme.length > 0)
          && (startDay.length > 0) && (endDay.length > 0))

          ?

          <Grid container spacing={12}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >

            <Grid item xs={7}>
              <Button style={{ fontSize: 16, margin: 14, padding: 3 }} variant="contained" endIcon={<AddIcon />} onClick={() => get_bays(bays_new[0])}>
                Add
              </Button>
            </Grid>
          </Grid>

          :

          <></>

      }

      <Grid container spacing={12}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >

        <Grid item xs={12}>
          <div style={{ height: '35vh', width: '100%', padding: '10px', margin: "5px" }}>
            <MapMyComponent/>
          </div>
        </Grid>

      </Grid>




      <Table size="small">
        <TableHead>
          <TableRow>

            <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Bay</TableCell>
            <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Payment Type</TableCell>
            <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Start Day</TableCell>
            <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>End Day</TableCell>
            <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Action</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {list_bays_new.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center">{row.id}</TableCell>
              {(row.type == "N/A") ?
                <TableCell align="center" style={{ color: "red" }} >{row.type}</TableCell>
                :
                <TableCell align="center" style={{ color: "white" }} >{row.type}</TableCell>
              }

              {(row.start == "N/A") ?
                <TableCell align="center" style={{ color: "red" }} >{row.start}</TableCell>
                :
                <TableCell align="center" style={{ color: "white" }} >{row.start}</TableCell>
              }

              {(row.end == "N/A") ?
                <TableCell align="center" style={{ color: "red" }} >{row.end}</TableCell>
                :
                <TableCell align="center" style={{ color: "white" }} >{row.end}</TableCell>
              }

              <TableCell align="center">
                <Button style={{ fontSize: 16, margin: 3, padding: 3 }} variant="contained" endIcon={<DeleteIcon />} onClick={() => deleteBay(row.id)}>
                  Delete
                </Button></TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>


    </React.Fragment>
  ): <></>;
}