import * as React from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import ToggleColorMode from './Dashboard';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Chip from '@mui/material/Chip';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import TimePicker from '@mui/lab/TimePicker';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import "../css/style.css";
import { useNavigate } from "react-router";

import moment, { months } from 'moment';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./Login";


import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import { MarkerClusterer } from '@react-google-maps/api';




const mapStyles = [
  {
    stylers: [
      {
        hue: '#4da6ff',
      },
      {
        saturation: 5,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        lightness: 30,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
];


const options2 = {
  maxZoom: 17,
  imagePath:
    './images/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
};

function createKey(location) {
  return location
};

const containerStyle = {
  width: '100%',
  height: '100%'
};



const options = {
  styles: mapStyles,
};







var rowsTable = [];

const paymentTypes = [
  {
    value: 'Customer Pay',
    label: 'Customer Pay',
  },
  {
    value: 'Business Pay',
    label: 'Business Pay',
  },
  {
    value: 'Complimentary',
    label: 'Complimentary',
  },
];



// Generate Order Data
function createData(id, vrn_cl, name_cl, event_cl, email_cl, timein_cl, timeout_cl, bay_cl, paymenttype_cl) {
  return {
    id, vrn_cl, name_cl, event_cl, email_cl, timein_cl, timeout_cl, bay_cl, paymenttype_cl
  };
}
function nothingD() { }

const rows = [
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Bookings_CL_New(props) {
  //const set1 = JSON.parse(localStorage.getItem('tog1'));



  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAHXPTUj2yO7cLM89zIl3o-XMyEqGgEWgA"
  });



  const [iconStr, seticonStr] = React.useState("./images/m3.png");

  const [mapa, setMap] = React.useState(null);

  const [zoomLevel, setzoomLevel] = React.useState('5');



  const onLoad = React.useCallback(function callback(mapa2) {
    //const bounds = new window.google.maps.LatLngBounds(center);
    //mapa2.fitBounds(bounds);
    //setMap(mapa);
  }, [])

  const onUnmount = React.useCallback(function callback(mapa2) {
    setMap(null);
  }, [])

  const [idbaySelected, setidbaySelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("idbaySelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [baytypeSelected, setbaytypeSelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("baytypeSelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [carparkSelected, setcarparkSelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  

  const [filteredParkingbay, setfilteredParkingbay] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("filteredParkingbay");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [filteredParkingi, setfilteredParkingi] = useState([]);

  const [info, setInfo] = useState([]);
  const [filteredParking, setfilteredParking] = useState([]);
  const navigate = useNavigate();
  const [setRequired, setsetRequired] = useState(false);

  const [val1, setVal1] = useState(true);
  const [val2, setVal2] = useState(true);
  const [val3, setVal3] = useState(true);
  const [val4, setVal4] = useState(true);
  const [val5, setVal5] = useState(true);
  const [val6, setVal6] = useState(true);
  const [val7, setVal7] = useState(true);
  const [val8, setVal8] = useState(true);
  const [val9, setVal9] = useState(true);
  const [val10, setVal10] = useState(true);
  const [val11, setVal11] = useState(true);
  const [val12, setVal12] = useState(true);
  const [val13, setVal13] = useState(true);
  const [val14, setVal14] = useState(true);
  const [val15, setVal15] = useState(true);
  const [val16, setVal16] = useState(true);
  const [val17, setVal17] = useState(true);
  const [val18, setVal18] = useState(true);
  const [val19, setVal19] = useState(true);

  const [val1c, setVal1c] = useState(false);
  const [val2c, setVal2c] = useState(false);
  const [val3c, setVal3c] = useState(false);
  const [val4c, setVal4c] = useState(false);
  const [val5c, setVal5c] = useState(false);
  const [val6c, setVal6c] = useState(false);
  const [val7c, setVal7c] = useState(false);
  const [val8c, setVal8c] = useState(false);
  const [val9c, setVal9c] = useState(false);
  const [val10c, setVal10c] = useState(false);
  const [val11c, setVal11c] = useState(false);
  const [val12c, setVal12c] = useState(false);
  const [val13c, setVal13c] = useState(false);
  const [val14c, setVal14c] = useState(false);
  const [val15c, setVal15c] = useState(false);
  const [val16c, setVal16c] = useState(false);
  const [val17c, setVal17c] = useState(false);
  const [val18c, setVal18c] = useState(false);
  const [val19c, setVal19c] = useState(false);


  // create state variables for each input

  const [formDone, setFormDone] = useState(false);
  const [dayValue, setdayValue] = useState('');
  const [dayOutValue, setdayOutValue] = useState('');
  const [timeIn, setTimeIn] = useState('');
  const [timeOut, setTimeOut] = useState('');

  const [fullNameval1, setfullNameval1] = useState('');
  const [vrnval1, setVrnval1] = useState('');
  const [eventNameval1, setEventNameval1] = useState('');
  const [phoneContactval1, setphoneContactval1] = useState('');
  const [paymentTypeval1, setPaymentTypeval1] = useState('');
  const [emailval1, setEmailval1] = useState('');

  const [fullName2, setFullName2] = useState('');
  const [vrn2, setVrn2] = useState('');
  const [eventName2, setEventName2] = useState('');
  const [phoneContact2, setphoneContact2] = useState('');
  const [paymentType2, setPaymentType2] = useState('');
  const [email2, setEmail2] = useState('');


  const [fullName3, setFullName3] = useState('');
  const [vrn3, setVrn3] = useState('');
  const [eventName3, setEventName3] = useState('');
  const [phoneContact3, setphoneContact3] = useState('');
  const [paymentType3, setPaymentType3] = useState('');
  const [email3, setEmail3] = useState('');



  const [fullName4, setFullName4] = useState('');
  const [vrn4, setVrn4] = useState('');
  const [eventName4, setEventName4] = useState('');
  const [phoneContact4, setphoneContact4] = useState('');
  const [paymentType4, setPaymentType4] = useState('');
  const [email4, setEmail4] = useState('');



  const [fullName5, setFullName5] = useState('');
  const [vrn5, setVrn5] = useState('');
  const [eventName5, setEventName5] = useState('');
  const [phoneContact5, setphoneContact5] = useState('');
  const [paymentType5, setPaymentType5] = useState('');
  const [email5, setEmail5] = useState('');



  const [fullName6, setFullName6] = useState('');
  const [vrn6, setVrn6] = useState('');
  const [eventName6, setEventName6] = useState('');
  const [phoneContact6, setphoneContact6] = useState('');
  const [paymentType6, setPaymentType6] = useState('');
  const [email6, setEmail6] = useState('');



  const [fullName7, setFullName7] = useState('');
  const [vrn7, setVrn7] = useState('');
  const [eventName7, setEventName7] = useState('');
  const [phoneContact7, setphoneContact7] = useState('');
  const [paymentType7, setPaymentType7] = useState('');
  const [email7, setEmail7] = useState('');



  const [fullName8, setFullName8] = useState('');
  const [vrn8, setVrn8] = useState('');
  const [eventName8, setEventName8] = useState('');
  const [phoneContact8, setphoneContact8] = useState('');
  const [paymentType8, setPaymentType8] = useState('');
  const [email8, setEmail8] = useState('');



  const [fullName9, setFullName9] = useState('');
  const [vrn9, setVrn9] = useState('');
  const [eventName9, setEventName9] = useState('');
  const [phoneContact9, setphoneContact9] = useState('');
  const [paymentType9, setPaymentType9] = useState('');
  const [email9, setEmail9] = useState('');



  const [fullName10, setFullName10] = useState('');
  const [vrn10, setVrn10] = useState('');
  const [eventName10, setEventName10] = useState('');
  const [phoneContact10, setphoneContact10] = useState('');
  const [paymentType10, setPaymentType10] = useState('');
  const [email10, setEmail10] = useState('');



  const [fullName11, setFullName11] = useState('');
  const [vrn11, setVrn11] = useState('');
  const [eventName11, setEventName11] = useState('');
  const [phoneContact11, setphoneContact11] = useState('');
  const [paymentType11, setPaymentType11] = useState('');
  const [email11, setEmail11] = useState('');



  const [fullName12, setFullName12] = useState('');
  const [vrn12, setVrn12] = useState('');
  const [eventName12, setEventName12] = useState('');
  const [phoneContact12, setphoneContact12] = useState('');
  const [paymentType12, setPaymentType12] = useState('');
  const [email12, setEmail12] = useState('');



  const [fullName13, setFullName13] = useState('');
  const [vrn13, setVrn13] = useState('');
  const [eventName13, setEventName13] = useState('');
  const [phoneContact13, setphoneContact13] = useState('');
  const [paymentType13, setPaymentType13] = useState('');
  const [email13, setEmail13] = useState('');



  const [fullName14, setFullName14] = useState('');
  const [vrn14, setVrn14] = useState('');
  const [eventName14, setEventName14] = useState('');
  const [phoneContact14, setphoneContact14] = useState('');
  const [paymentType14, setPaymentType14] = useState('');
  const [email14, setEmail14] = useState('');



  const [fullName15, setFullName15] = useState('');
  const [vrn15, setVrn15] = useState('');
  const [eventName15, setEventName15] = useState('');
  const [phoneContact15, setphoneContact15] = useState('');
  const [paymentType15, setPaymentType15] = useState('');
  const [email15, setEmail15] = useState('');



  const [fullName16, setFullName16] = useState('');
  const [vrn16, setVrn16] = useState('');
  const [eventName16, setEventName16] = useState('');
  const [phoneContact16, setphoneContact16] = useState('');
  const [paymentType16, setPaymentType16] = useState('');
  const [email16, setEmail16] = useState('');



  const [fullName17, setFullName17] = useState('');
  const [vrn17, setVrn17] = useState('');
  const [eventName17, setEventName17] = useState('');
  const [phoneContact17, setphoneContact17] = useState('');
  const [paymentType17, setPaymentType17] = useState('');
  const [email17, setEmail17] = useState('');



  const [fullName18, setFullName18] = useState('');
  const [vrn18, setVrn18] = useState('');
  const [eventName18, setEventName18] = useState('');
  const [phoneContact18, setphoneContact18] = useState('');
  const [paymentType18, setPaymentType18] = useState('');
  const [email18, setEmail18] = useState('');



  const [fullName19, setFullName19] = useState('');
  const [vrn19, setVrn19] = useState('');
  const [eventName19, setEventName19] = useState('');
  const [phoneContact19, setphoneContact19] = useState('');
  const [paymentType19, setPaymentType19] = useState('');
  const [email19, setEmail19] = useState('');


  const [dayValueT, setdayValueT] = useState(false);
  const [dayOutValueT, setdayOutValueT] = useState(false);
  const [timeInT, setTimeInT] = useState(false);
  const [timeOutT, setTimeOutT] = useState(false);
  const [selectedDateIn, setselectedDateIn] = useState(new Date());
  const [selectedDateOut, setselectedDateOut] = useState(new Date());
  const [selectedTimeIn, setselectedTimeIn] = useState(new Date());
  const [selectedTimeOut, setselectedTimeOut] = useState(new Date());
  const [formsShow, setformsShow] = useState(false);

  
  const [rowsHb, setrowsHb] = useState([]);
  const [newBays, setnewBays] = useState([]);
  
  const [filteredBays, setfilteredBays] = useState([]);
  const getMyDay = (valueA) => {
    var dateHea = new Date(valueA);
    setselectedDateIn(dateHea);
  }

  const getMyTimeIn = (valueB) => {
    var dateHeb = new Date(valueB);
    setselectedTimeIn(dateHeb);
  }

  const getMyDayOut = (valueC) => {
    var dateHec = new Date(valueC);
    setselectedDateOut(dateHec);
  }

  const getTimeOut = (valueD) => {
    var dateHed = new Date(valueD);
    setselectedTimeOut(dateHed);
  }


  function triggerChange() {
    if (dayValueT && dayOutValueT && timeInT && timeOutT) {
      resetAll();
      setBeacons();
      setformsShow(true);
    }
  }




  const [admin, setAdmin] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("admin");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [user, loading, error] = useAuthState(auth);

  const fetchParking = async () => {
    try {
      db.collection("parking_lots").get().then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.forEach(element => {
          var data = element.data();
          if (data.adminA == admin) {
            setInfo(arr => [...arr, data]);
          }
        });
      });

    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };


  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e]).map(e => arr[e]);
    return unique;
  }


  const fetchUserName = async () => {
    try {
      const query = await db
        .collection("users")
        .doc(user?.uid)
        .get();
      const userName = query.data();
      setAdmin(userName.adminA);
    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };


  useEffect(() => {
    // storing input name
    fetchUserName();
  }, []);

  useEffect(() => {
    // storing input name
    fetchParking();
  }, [admin]);


  useEffect(() => {
    // storing input name
    
    var filt = getUnique(info, "baytype");
    setfilteredParking(filt);
  }, [info]);

  const fetchBays = async () => {
    try {
      var zeor_it = [];
      db.collection("beacons").get().then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.forEach(element => {
          var data = element.data();
          
          if (data.parking == carparkSelected) {
            zeor_it.push(data);
            
          };
        });
        setnewBays(zeor_it);
      });

    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };



  useEffect(() => {
    // storing input name
    var filt = getUnique(newBays, "id");
    setfilteredBays(filt);
  }, [newBays]);

  useEffect(() => {
    // storing input name
    fetchBays();
  }, []);



  
  function resetAll() {
    setVal1c(false);
    setVal1(true);

    setVal2c(false);
    setVal2(true);

    setVal3c(false);
    setVal3(true);

    setVal4c(false);
    setVal4(true);

    setVal5c(false);
    setVal5(true);

    setVal6c(false);
    setVal6(true);

    setVal7c(false);
    setVal7(true);

    setVal8c(false);
    setVal8(true);

    setVal9c(false);
    setVal9(true);

    setVal10c(false);
    setVal10(true);

    setVal11c(false);
    setVal11(true);

    setVal12c(false);
    setVal12(true);

    setVal13c(false);
    setVal13(true);

    setVal14c(false);
    setVal14(true);

    setVal15c(false);
    setVal15(true);


    setVal16c(false);
    setVal16(true);



    setVal17c(false);
    setVal17(true);



    setVal18c(false);
    setVal18(true);



    setVal19c(false);
    setVal19(true);


  }

  function compose10(bay_no) {
    //setVal10c(true);
    //setVal10(false);
    console.log(bay_no);
    
  }


  const setBeacons = async () => {
    const get_bays = [];
    db
      .collection("calendar")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          if ((doc.data().admin == admin) &&  (doc.data().parking == carparkSelected)){

            var selectedDateInYear = selectedDateIn.getFullYear();
            var selectedDateInMonth = selectedDateIn.getMonth();
            var selectedDateInDate = selectedDateIn.getDate();
            var selectedDateInHours = selectedTimeIn.getHours();
            var convertedselectedDateIn = moment([selectedDateInYear, selectedDateInMonth, selectedDateInDate, selectedDateInHours, 0, 0]);

            var selectedDateOutYear = selectedDateOut.getFullYear();
            var selectedDateOutMonth = selectedDateOut.getMonth();
            var selectedDateOutDate = selectedDateOut.getDate();
            var selectedDateOutHours = selectedTimeOut.getHours();
            var convertedselectedDateOut = moment([selectedDateOutYear, selectedDateOutMonth, selectedDateOutDate, selectedDateOutHours, 0, 0]);

            var docDataDateInYear = doc.data().dateday.toDate().getFullYear();
            var docDataDateInMonth = doc.data().dateday.toDate().getMonth();
            var docDataDateInDate = doc.data().dateday.toDate().getDate();
            var docDataDateInHours = doc.data().timein.toDate().getHours();
            var convertedocDataDateIn = moment([docDataDateInYear, docDataDateInMonth, docDataDateInDate, docDataDateInHours, 0, 0]);


            var docDataDateOutYear = doc.data().dateOutday.toDate().getFullYear();
            var docDataDateOutMonth = doc.data().dateOutday.toDate().getMonth();
            var docDataDateOutDate = doc.data().dateOutday.toDate().getDate();
            var docDataDateOutHours = doc.data().timeout.toDate().getHours();
            var convertedocDataDateOut = moment([docDataDateOutYear, docDataDateOutMonth, docDataDateOutDate, docDataDateOutHours, 0, 0]);

            //console.log("convertedselectedDateIn.valueOf()" + convertedselectedDateIn.valueOf());
            //console.log("convertedocDataDateIn.valueOf()" + convertedocDataDateIn.valueOf());
            //console.log("convertedselectedDateOut.valueOf()" + convertedselectedDateOut.valueOf());
            console.log("convertedocDataDateOut.valueOf()" + convertedocDataDateOut.valueOf());

            if (
              ((convertedselectedDateIn.valueOf() === convertedocDataDateIn.valueOf())
                &&
                (convertedselectedDateOut.valueOf() === convertedocDataDateOut.valueOf()))

              ||

              (

                (convertedselectedDateIn.valueOf() >= convertedocDataDateIn.valueOf())
                &&
                (convertedselectedDateOut.valueOf() >= convertedocDataDateOut.valueOf())
                &&
                (convertedselectedDateIn.valueOf() <= convertedocDataDateOut.valueOf())


              )


              ||

              (
                (convertedselectedDateIn.valueOf() <= convertedocDataDateIn.valueOf())
                &&
                (convertedselectedDateOut.valueOf() <= convertedocDataDateOut.valueOf())
                &&
                (convertedselectedDateOut.valueOf() >= convertedocDataDateIn.valueOf())
              )

              ||

              (
                (convertedselectedDateIn.valueOf() >= convertedocDataDateIn.valueOf())
                &&
                (convertedselectedDateOut.valueOf() <= convertedocDataDateOut.valueOf())
              )


              ||

              (
                (convertedselectedDateIn.valueOf() <= convertedocDataDateIn.valueOf())
                &&
                (convertedselectedDateOut.valueOf() >= convertedocDataDateOut.valueOf())
              )


            ) {
             
              get_bays.push(
                {
                  id: doc.id,
                  vrn_cl: doc.data().vrn,
                  name_cl: doc.data().fullname,
                  event_cl: doc.data().eventname,
                  email_cl: doc.data().email,
                  phone_cl: doc.data().phone,
                  timein_cl: doc.data().dateday,
                  timeout_cl: doc.data().dateOutday,
                  paymenttype_cl: doc.data().paymenttype,
                  bay: (doc.data().bay),  
                  parking: (doc.data().parking),
                }
              );
              compose10(doc.data().bay);
            }
          }
        });

      })
      .catch(function (error) {
        //console.log("Error getting documents: ", error);
      });
  }


  const putData = async () => {


    if (!val1 && !val1c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: emailval1,
        phone: phoneContactval1,
        fullname: fullNameval1,
        vrn: vrnval1,
        eventname: eventNameval1,
        paymenttype: paymentTypeval1,
        //bay56: (val1c == true ? "fuzzy" : val1),
        bay56: val1,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: emailval1,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullNameval1 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventNameval1 + "</br>"
              + "<br>Vehicle Registration No.: " + vrnval1 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }


    if (!val2 && !val2c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email2,
        phone: phoneContact2,
        fullname: fullName2,
        vrn: vrn2,
        eventname: eventName2,
        paymenttype: paymentType2,
        //bay57: (val2c == true ? "fuzzy" : val2),
        bay57: val2,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email2,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName2 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName2 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn2 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }


    if (!val3 && !val3c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email3,
        phone: phoneContact3,
        fullname: fullName3,
        vrn: vrn3,
        eventname: eventName3,
        paymenttype: paymentType3,
        //bay58: (val3c == true ? "fuzzy" : val3),
        bay58: val3,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email3,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName3 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName3 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn3 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }



    if (!val4 && !val4c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email4,
        phone: phoneContact4,
        fullname: fullName4,
        vrn: vrn4,
        eventname: eventName4,
        paymenttype: paymentType4,
        //bay59: (val4c == true ? "fuzzy" : val4),
        bay59: val4,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email4,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName4 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName4 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn4 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }



    if (!val5 && !val5c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email5,
        phone: phoneContact5,
        fullname: fullName5,
        vrn: vrn5,
        eventname: eventName5,
        paymenttype: paymentType5,
        //bay60: (val5c == true ? "fuzzy" : val5),
        bay60: val5,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email5,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName5 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName5 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn5 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }



    if (!val6 && !val6c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email6,
        phone: phoneContact6,
        fullname: fullName6,
        vrn: vrn6,
        eventname: eventName6,
        paymenttype: paymentType6,
        //bay61: (val6c == true ? "fuzzy" : val6),
        bay61: val6,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email6,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName6 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName6 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn6 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }



    if (!val7 && !val7c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email7,
        phone: phoneContact7,
        fullname: fullName7,
        vrn: vrn7,
        eventname: eventName7,
        paymenttype: paymentType7,
        //bay62: (val7c == true ? "fuzzy" : val7),
        bay62: val7,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email7,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName7 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName7 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn7 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }



    if (!val8 && !val8c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email8,
        phone: phoneContact8,
        fullname: fullName8,
        vrn: vrn8,
        eventname: eventName8,
        paymenttype: paymentType8,
        //bay63: (val8c == true ? "fuzzy" : val8),
        bay63: val8,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email8,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName8 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName8 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn8 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }


    if (!val9 && !val9c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email9,
        phone: phoneContact9,
        fullname: fullName9,
        vrn: vrn9,
        eventname: eventName9,
        paymenttype: paymentType9,
        //bay64: (val9c == true ? "fuzzy" : val9),
        bay64: val9,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email9,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName9 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName9 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn9 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }




    if (!val10 && !val10c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email10,
        phone: phoneContact10,
        fullname: fullName10,
        vrn: vrn10,
        eventname: eventName10,
        paymenttype: paymentType10,
        //bay65: (val10c == true ? "fuzzy" : val10),
        bay65: val10,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email10,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName10 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName10 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn10 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }






    if (!val11 && !val11c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email11,
        phone: phoneContact11,
        fullname: fullName11,
        vrn: vrn11,
        eventname: eventName11,
        paymenttype: paymentType11,
        //bay66: (val11c == true ? "fuzzy" : val11),
        bay66: val11,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email11,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName11 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName11 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn11 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }






    if (!val12 && !val12c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email12,
        phone: phoneContact12,
        fullname: fullName12,
        vrn: vrn12,
        eventname: eventName12,
        paymenttype: paymentType12,
        //bay67: (val12c == true ? "fuzzy" : val12),
        bay67: val12,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email12,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName12 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName12 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn12 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }


    if (!val13 && !val13c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email13,
        phone: phoneContact13,
        fullname: fullName13,
        vrn: vrn13,
        eventname: eventName13,
        paymenttype: paymentType13,
        //bay68: (val13c == true ? "fuzzy" : val13),
        bay68: val13,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email13,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName13 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName13 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn13 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }



    if (!val14 && !val14c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email14,
        phone: phoneContact14,
        fullname: fullName14,
        vrn: vrn14,
        eventname: eventName14,
        paymenttype: paymentType14,
        //bay69: (val14c == true ? "fuzzy" : val14),
        bay69: val14,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email14,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName14 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName14 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn14 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }



    if (!val15 && !val15c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email15,
        phone: phoneContact15,
        fullname: fullName15,
        vrn: vrn15,
        eventname: eventName15,
        paymenttype: paymentType15,
        //bay70: (val15c == true ? "fuzzy" : val15),
        bay70: val15,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email15,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName15 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName15 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn15 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }



    if (!val16 && !val16c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email16,
        phone: phoneContact16,
        fullname: fullName16,
        vrn: vrn16,
        eventname: eventName16,
        paymenttype: paymentType16,
        //bay71: (val16c == true ? "fuzzy" : val16),
        bay71: val16,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email16,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName16 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName16 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn16 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }



    if (!val17 && !val17c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email17,
        phone: phoneContact17,
        fullname: fullName17,
        vrn: vrn17,
        eventname: eventName17,
        paymenttype: paymentType17,
        //bay72: (val17c == true ? "fuzzy" : val17),
        bay72: val17,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email17,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName17 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName17 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn17 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }



    if (!val18 && !val18c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email18,
        phone: phoneContact18,
        fullname: fullName18,
        vrn: vrn18,
        eventname: eventName18,
        paymenttype: paymentType18,
        //bay73: (val18c == true ? "fuzzy" : val18),
        bay73: val18,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email18,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName18 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName18 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn18 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }


    if (!val19 && !val19c) {
      db.collection('calendar').doc().set({
        dateday: dayValue,
        dateOutday: dayOutValue,
        timein: timeIn,
        timeout: timeOut,
        email: email19,
        phone: phoneContact19,
        fullname: fullName19,
        vrn: vrn19,
        eventname: eventName19,
        paymenttype: paymentType19,
        //bay74: (val19c == true ? "fuzzy" : val19),
        bay74: val19,
        admin: admin,
      });
      db
        .collection("mail")
        .add({
          to: email19,
          message: {
            subject: "Booking Confirmation for Cambridge Science Park - The Bradfield Centre",
            text: "This is the plaintext section of the email body.",
            html: "Dear " + fullName19 + ","
              + "<br>You have a new booking for Cambridge Science Park - The Bradfield Centre with the follwoing details:</br>"
              + "<br></br>"
              + "<br>Date In: " + selectedDateIn.getDate() + "/" + selectedDateIn.getMonth() + "/" + selectedDateIn.getFullYear() + " " + "</br>"
              + "<br>Time In: " + selectedTimeIn.getHours() + ":00" + "</br>"
              + "<br>Day Out: " + selectedDateOut.getDate() + "/" + selectedDateOut.getMonth() + "/" + selectedDateOut.getFullYear() + " " + "</br>"
              + "<br>Time Out: " + selectedTimeOut.getHours() + ":00" + "</br>"
              + "<br>Event or Host Name: " + eventName19 + "</br>"
              + "<br>Vehicle Registration No.: " + vrn19 + "</br>"
              + "<br></br>"
              + "<br>Thank you!" + "<br>"
          },
        })
        .then(() => console.log("Queued email for delivery!"));
    }


    rowsTable = [];

  };

  const toReport = (props) => {
    putData();

    //setFormDone(true);
    navigate('/Confirmation');
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormDone(true);
  };





  const [value, setValue] = React.useState(new Date());

  function onchangeName(theId, theTarget) {

    console.log(rowsTable[0]);

    if (theId == 'val1' && theTarget == 'name') {
      return (fullNameval1);
    }

    if (theId == 'val1' && theTarget == 'vrn') {
      return (vrnval1);
    }

    if (theId == 'val1' && theTarget == 'email') {
      return (emailval1);
    }

    if (theId == 'val1' && theTarget == 'phone') {
      return (phoneContactval1);
    }

    if (theId == 'val1' && theTarget == 'event') {
      return (eventNameval1);
    }


    if (theId == 'val1' && theTarget == 'payment') {
      return (paymentTypeval1);
    }





    if (theId == 'val2' && theTarget == 'name') {
      return (fullName2);
    }

    if (theId == 'val2' && theTarget == 'vrn') {
      return (vrn2);
    }

    if (theId == 'val2' && theTarget == 'email') {
      return (email2);
    }



    if (theId == 'val2' && theTarget == 'phone') {
      return (phoneContact2);
    }

    if (theId == 'val2' && theTarget == 'event') {
      return (eventName2);
    }


    if (theId == 'val2' && theTarget == 'payment') {
      return (paymentType2);
    }





    if (theId == 'val3' && theTarget == 'name') {
      return (fullName3);
    }

    if (theId == 'val3' && theTarget == 'vrn') {
      return (vrn3);
    }

    if (theId == 'val3' && theTarget == 'email') {
      return (email3);
    }



    if (theId == 'val3' && theTarget == 'phone') {
      return (phoneContact3);
    }

    if (theId == 'val3' && theTarget == 'event') {
      return (eventName3);
    }


    if (theId == 'val3' && theTarget == 'payment') {
      return (paymentType3);
    }








    if (theId == 'val4' && theTarget == 'name') {
      return (fullName4);
    }

    if (theId == 'val4' && theTarget == 'vrn') {
      return (vrn4);
    }

    if (theId == 'val4' && theTarget == 'email') {
      return (email4);
    }



    if (theId == 'val4' && theTarget == 'phone') {
      return (phoneContact4);
    }

    if (theId == 'val4' && theTarget == 'event') {
      return (eventName4);
    }


    if (theId == 'val4' && theTarget == 'payment') {
      return (paymentType4);
    }






    if (theId == 'val5' && theTarget == 'name') {
      return (fullName5);
    }

    if (theId == 'val5' && theTarget == 'vrn') {
      return (vrn5);
    }

    if (theId == 'val5' && theTarget == 'email') {
      return (email5);
    }



    if (theId == 'val5' && theTarget == 'phone') {
      return (phoneContact5);
    }

    if (theId == 'val5' && theTarget == 'event') {
      return (eventName5);
    }


    if (theId == 'val5' && theTarget == 'payment') {
      return (paymentType5);
    }






    if (theId == 'val6' && theTarget == 'name') {
      return (fullName6);
    }

    if (theId == 'val6' && theTarget == 'vrn') {
      return (vrn6);
    }

    if (theId == 'val6' && theTarget == 'email') {
      return (email6);
    }



    if (theId == 'val6' && theTarget == 'phone') {
      return (phoneContact6);
    }

    if (theId == 'val6' && theTarget == 'event') {
      return (eventName6);
    }


    if (theId == 'val6' && theTarget == 'payment') {
      return (paymentType6);
    }






    if (theId == 'val7' && theTarget == 'name') {
      return (fullName7);
    }

    if (theId == 'val7' && theTarget == 'vrn') {
      return (vrn7);
    }

    if (theId == 'val7' && theTarget == 'email') {
      return (email7);
    }



    if (theId == 'val7' && theTarget == 'phone') {
      return (phoneContact2);
    }

    if (theId == 'val7' && theTarget == 'event') {
      return (eventName7);
    }


    if (theId == 'val7' && theTarget == 'payment') {
      return (paymentType7);
    }




    if (theId == 'val8' && theTarget == 'name') {
      return (fullName8);
    }

    if (theId == 'val8' && theTarget == 'vrn') {
      return (vrn8);
    }

    if (theId == 'val8' && theTarget == 'email') {
      return (email8);
    }



    if (theId == 'val8' && theTarget == 'phone') {
      return (phoneContact8);
    }

    if (theId == 'val8' && theTarget == 'event') {
      return (eventName8);
    }


    if (theId == 'val8' && theTarget == 'payment') {
      return (paymentType8);
    }






    if (theId == 'val9' && theTarget == 'name') {
      return (fullName9);
    }

    if (theId == 'val9' && theTarget == 'vrn') {
      return (vrn9);
    }

    if (theId == 'val9' && theTarget == 'email') {
      return (email9);
    }



    if (theId == 'val9' && theTarget == 'phone') {
      return (phoneContact9);
    }

    if (theId == 'val9' && theTarget == 'event') {
      return (eventName9);
    }


    if (theId == 'val9' && theTarget == 'payment') {
      return (paymentType9);
    }




    if (theId == 'val10' && theTarget == 'name') {
      return (fullName10);
    }

    if (theId == 'val10' && theTarget == 'vrn') {
      return (vrn10);
    }

    if (theId == 'val10' && theTarget == 'email') {
      return (email10);
    }



    if (theId == 'val10' && theTarget == 'phone') {
      return (phoneContact10);
    }

    if (theId == 'val10' && theTarget == 'event') {
      return (eventName10);
    }


    if (theId == 'val10' && theTarget == 'payment') {
      return (paymentType10);
    }




    if (theId == 'val11' && theTarget == 'name') {
      return (fullName11);
    }

    if (theId == 'val11' && theTarget == 'vrn') {
      return (vrn11);
    }

    if (theId == 'val11' && theTarget == 'email') {
      return (email11);
    }



    if (theId == 'val11' && theTarget == 'phone') {
      return (phoneContact11);
    }

    if (theId == 'val11' && theTarget == 'event') {
      return (eventName11);
    }


    if (theId == 'val11' && theTarget == 'payment') {
      return (paymentType11);
    }











    if (theId == 'val12' && theTarget == 'name') {
      return (fullName12);
    }

    if (theId == 'val12' && theTarget == 'vrn') {
      return (vrn12);
    }

    if (theId == 'val12' && theTarget == 'email') {
      return (email12);
    }



    if (theId == 'val12' && theTarget == 'phone') {
      return (phoneContact12);
    }

    if (theId == 'val12' && theTarget == 'event') {
      return (eventName12);
    }


    if (theId == 'val12' && theTarget == 'payment') {
      return (paymentType12);
    }





    if (theId == 'val13' && theTarget == 'name') {
      return (fullName13);
    }

    if (theId == 'val13' && theTarget == 'vrn') {
      return (vrn13);
    }

    if (theId == 'val13' && theTarget == 'email') {
      return (email13);
    }



    if (theId == 'val13' && theTarget == 'phone') {
      return (phoneContact13);
    }

    if (theId == 'val13' && theTarget == 'event') {
      return (eventName13);
    }


    if (theId == 'val13' && theTarget == 'payment') {
      return (paymentType13);
    }








    if (theId == 'val14' && theTarget == 'name') {
      return (fullName14);
    }

    if (theId == 'val14' && theTarget == 'vrn') {
      return (vrn14);
    }

    if (theId == 'val14' && theTarget == 'email') {
      return (email14);
    }



    if (theId == 'val14' && theTarget == 'phone') {
      return (phoneContact14);
    }

    if (theId == 'val14' && theTarget == 'event') {
      return (eventName14);
    }


    if (theId == 'val14' && theTarget == 'payment') {
      return (paymentType14);
    }






    if (theId == 'val15' && theTarget == 'name') {
      return (fullName15);
    }

    if (theId == 'val15' && theTarget == 'vrn') {
      return (vrn15);
    }

    if (theId == 'val15' && theTarget == 'email') {
      return (email15);
    }



    if (theId == 'val15' && theTarget == 'phone') {
      return (phoneContact15);
    }

    if (theId == 'val15' && theTarget == 'event') {
      return (eventName15);
    }


    if (theId == 'val15' && theTarget == 'payment') {
      return (paymentType15);
    }






    if (theId == 'val16' && theTarget == 'name') {
      return (fullName16);
    }

    if (theId == 'val16' && theTarget == 'vrn') {
      return (vrn16);
    }

    if (theId == 'val16' && theTarget == 'email') {
      return (email16);
    }



    if (theId == 'val16' && theTarget == 'phone') {
      return (phoneContact16);
    }

    if (theId == 'val16' && theTarget == 'event') {
      return (eventName16);
    }


    if (theId == 'val16' && theTarget == 'payment') {
      return (paymentType16);
    }






    if (theId == 'val17' && theTarget == 'name') {
      return (fullName17);
    }

    if (theId == 'val17' && theTarget == 'vrn') {
      return (vrn17);
    }

    if (theId == 'val17' && theTarget == 'email') {
      return (email17);
    }



    if (theId == 'val17' && theTarget == 'phone') {
      return (phoneContact2);
    }

    if (theId == 'val17' && theTarget == 'event') {
      return (eventName17);
    }


    if (theId == 'val17' && theTarget == 'payment') {
      return (paymentType17);
    }




    if (theId == 'val18' && theTarget == 'name') {
      return (fullName18);
    }

    if (theId == 'val18' && theTarget == 'vrn') {
      return (vrn18);
    }

    if (theId == 'val18' && theTarget == 'email') {
      return (email18);
    }



    if (theId == 'val18' && theTarget == 'phone') {
      return (phoneContact18);
    }

    if (theId == 'val18' && theTarget == 'event') {
      return (eventName18);
    }


    if (theId == 'val18' && theTarget == 'payment') {
      return (paymentType18);
    }






    if (theId == 'val19' && theTarget == 'name') {
      return (fullName19);
    }

    if (theId == 'val19' && theTarget == 'vrn') {
      return (vrn19);
    }

    if (theId == 'val19' && theTarget == 'email') {
      return (email19);
    }



    if (theId == 'val19' && theTarget == 'phone') {
      return (phoneContact19);
    }

    if (theId == 'val19' && theTarget == 'event') {
      return (eventName19);
    }


    if (theId == 'val19' && theTarget == 'payment') {
      return (paymentType19);
    }








  }



  function onchangeUniversal(valueUniversal, targetUniversalname, targetUniversalvalue) {



    if (valueUniversal == 'val1' && targetUniversalname == 'name') {
      setfullNameval1(targetUniversalvalue);
    }

    if (valueUniversal == 'val1' && targetUniversalname == 'vrn') {
      setVrnval1(targetUniversalvalue);
    }

    if (valueUniversal == 'val1' && targetUniversalname == 'email') {
      setEmailval1(targetUniversalvalue);
    }


    if (valueUniversal == 'val1' && targetUniversalname == 'phone') {
      setphoneContactval1(targetUniversalvalue);
    }


    if (valueUniversal == 'val1' && targetUniversalname == 'event') {
      setEventNameval1(targetUniversalvalue);
    }




    if (valueUniversal == 'val1' && targetUniversalname == 'payment') {
      setPaymentTypeval1(targetUniversalvalue);
    }



    if (valueUniversal == 'val2' && targetUniversalname == 'name') {
      setFullName2(targetUniversalvalue);
    }

    if (valueUniversal == 'val2' && targetUniversalname == 'vrn') {
      setVrn2(targetUniversalvalue);
    }

    if (valueUniversal == 'val2' && targetUniversalname == 'email') {
      setEmail2(targetUniversalvalue);
    }


    if (valueUniversal == 'val2' && targetUniversalname == 'phone') {
      setphoneContact2(targetUniversalvalue);
    }


    if (valueUniversal == 'val2' && targetUniversalname == 'event') {
      setEventName2(targetUniversalvalue);
    }


    if (valueUniversal == 'val2' && targetUniversalname == 'payment') {
      setPaymentType2(targetUniversalvalue);
    }








    if (valueUniversal == 'val3' && targetUniversalname == 'name') {
      setFullName3(targetUniversalvalue);
    }

    if (valueUniversal == 'val3' && targetUniversalname == 'vrn') {
      setVrn3(targetUniversalvalue);
    }

    if (valueUniversal == 'val3' && targetUniversalname == 'email') {
      setEmail3(targetUniversalvalue);
    }


    if (valueUniversal == 'val3' && targetUniversalname == 'phone') {
      setphoneContact3(targetUniversalvalue);
    }


    if (valueUniversal == 'val3' && targetUniversalname == 'event') {
      setEventName3(targetUniversalvalue);
    }


    if (valueUniversal == 'val3' && targetUniversalname == 'payment') {
      setPaymentType3(targetUniversalvalue);
    }




    if (valueUniversal == 'val4' && targetUniversalname == 'name') {
      setFullName4(targetUniversalvalue);
    }

    if (valueUniversal == 'val4' && targetUniversalname == 'vrn') {
      setVrn4(targetUniversalvalue);
    }

    if (valueUniversal == 'val4' && targetUniversalname == 'email') {
      setEmail4(targetUniversalvalue);
    }


    if (valueUniversal == 'val4' && targetUniversalname == 'phone') {
      setphoneContact4(targetUniversalvalue);
    }


    if (valueUniversal == 'val4' && targetUniversalname == 'event') {
      setEventName4(targetUniversalvalue);
    }


    if (valueUniversal == 'val4' && targetUniversalname == 'payment') {
      setPaymentType4(targetUniversalvalue);
    }





    if (valueUniversal == 'val5' && targetUniversalname == 'name') {
      setFullName5(targetUniversalvalue);
    }

    if (valueUniversal == 'val5' && targetUniversalname == 'vrn') {
      setVrn5(targetUniversalvalue);
    }

    if (valueUniversal == 'val5' && targetUniversalname == 'email') {
      setEmail5(targetUniversalvalue);
    }


    if (valueUniversal == 'val5' && targetUniversalname == 'phone') {
      setphoneContact5(targetUniversalvalue);
    }


    if (valueUniversal == 'val5' && targetUniversalname == 'event') {
      setEventName5(targetUniversalvalue);
    }


    if (valueUniversal == 'val5' && targetUniversalname == 'payment') {
      setPaymentType5(targetUniversalvalue);
    }





    if (valueUniversal == 'val6' && targetUniversalname == 'name') {
      setFullName6(targetUniversalvalue);
    }

    if (valueUniversal == 'val6' && targetUniversalname == 'vrn') {
      setVrn6(targetUniversalvalue);
    }

    if (valueUniversal == 'val6' && targetUniversalname == 'email') {
      setEmail6(targetUniversalvalue);
    }


    if (valueUniversal == 'val6' && targetUniversalname == 'phone') {
      setphoneContact6(targetUniversalvalue);
    }


    if (valueUniversal == 'val6' && targetUniversalname == 'event') {
      setEventName6(targetUniversalvalue);
    }


    if (valueUniversal == 'val6' && targetUniversalname == 'payment') {
      setPaymentType6(targetUniversalvalue);
    }




    if (valueUniversal == 'val7' && targetUniversalname == 'name') {
      setFullName7(targetUniversalvalue);
    }

    if (valueUniversal == 'val7' && targetUniversalname == 'vrn') {
      setVrn7(targetUniversalvalue);
    }

    if (valueUniversal == 'val7' && targetUniversalname == 'email') {
      setEmail7(targetUniversalvalue);
    }


    if (valueUniversal == 'val7' && targetUniversalname == 'phone') {
      setphoneContact7(targetUniversalvalue);
    }


    if (valueUniversal == 'val7' && targetUniversalname == 'event') {
      setEventName7(targetUniversalvalue);
    }


    if (valueUniversal == 'val7' && targetUniversalname == 'payment') {
      setPaymentType7(targetUniversalvalue);
    }






    if (valueUniversal == 'val8' && targetUniversalname == 'name') {
      setFullName8(targetUniversalvalue);
    }

    if (valueUniversal == 'val8' && targetUniversalname == 'vrn') {
      setVrn8(targetUniversalvalue);
    }

    if (valueUniversal == 'val8' && targetUniversalname == 'email') {
      setEmail8(targetUniversalvalue);
    }


    if (valueUniversal == 'val8' && targetUniversalname == 'phone') {
      setphoneContact8(targetUniversalvalue);
    }


    if (valueUniversal == 'val8' && targetUniversalname == 'event') {
      setEventName8(targetUniversalvalue);
    }


    if (valueUniversal == 'val8' && targetUniversalname == 'payment') {
      setPaymentType8(targetUniversalvalue);
    }





    if (valueUniversal == 'val9' && targetUniversalname == 'name') {
      setFullName9(targetUniversalvalue);
    }

    if (valueUniversal == 'val9' && targetUniversalname == 'vrn') {
      setVrn9(targetUniversalvalue);
    }

    if (valueUniversal == 'val9' && targetUniversalname == 'email') {
      setEmail9(targetUniversalvalue);
    }


    if (valueUniversal == 'val9' && targetUniversalname == 'phone') {
      setphoneContact9(targetUniversalvalue);
    }


    if (valueUniversal == 'val9' && targetUniversalname == 'event') {
      setEventName9(targetUniversalvalue);
    }


    if (valueUniversal == 'val9' && targetUniversalname == 'payment') {
      setPaymentType9(targetUniversalvalue);
    }




    if (valueUniversal == 'val10' && targetUniversalname == 'name') {
      setFullName10(targetUniversalvalue);
    }

    if (valueUniversal == 'val10' && targetUniversalname == 'vrn') {
      setVrn10(targetUniversalvalue);
    }

    if (valueUniversal == 'val10' && targetUniversalname == 'email') {
      setEmail10(targetUniversalvalue);
    }


    if (valueUniversal == 'val10' && targetUniversalname == 'phone') {
      setphoneContact10(targetUniversalvalue);
    }


    if (valueUniversal == 'val10' && targetUniversalname == 'event') {
      setEventName10(targetUniversalvalue);
    }


    if (valueUniversal == 'val10' && targetUniversalname == 'payment') {
      setPaymentType10(targetUniversalvalue);
    }




    if (valueUniversal == 'val11' && targetUniversalname == 'name') {
      setFullName11(targetUniversalvalue);
    }

    if (valueUniversal == 'val11' && targetUniversalname == 'vrn') {
      setVrn11(targetUniversalvalue);
    }

    if (valueUniversal == 'val11' && targetUniversalname == 'email') {
      setEmail11(targetUniversalvalue);
    }


    if (valueUniversal == 'val11' && targetUniversalname == 'phone') {
      setphoneContact11(targetUniversalvalue);
    }


    if (valueUniversal == 'val11' && targetUniversalname == 'event') {
      setEventName11(targetUniversalvalue);
    }


    if (valueUniversal == 'val11' && targetUniversalname == 'payment') {
      setPaymentType11(targetUniversalvalue);
    }



















    if (valueUniversal == 'val12' && targetUniversalname == 'name') {
      setFullName12(targetUniversalvalue);
    }

    if (valueUniversal == 'val12' && targetUniversalname == 'vrn') {
      setVrn12(targetUniversalvalue);
    }

    if (valueUniversal == 'val12' && targetUniversalname == 'email') {
      setEmail12(targetUniversalvalue);
    }


    if (valueUniversal == 'val12' && targetUniversalname == 'phone') {
      setphoneContact12(targetUniversalvalue);
    }


    if (valueUniversal == 'val12' && targetUniversalname == 'event') {
      setEventName12(targetUniversalvalue);
    }


    if (valueUniversal == 'val12' && targetUniversalname == 'payment') {
      setPaymentType12(targetUniversalvalue);
    }








    if (valueUniversal == 'val13' && targetUniversalname == 'name') {
      setFullName13(targetUniversalvalue);
    }

    if (valueUniversal == 'val13' && targetUniversalname == 'vrn') {
      setVrn13(targetUniversalvalue);
    }

    if (valueUniversal == 'val13' && targetUniversalname == 'email') {
      setEmail13(targetUniversalvalue);
    }


    if (valueUniversal == 'val13' && targetUniversalname == 'phone') {
      setphoneContact13(targetUniversalvalue);
    }


    if (valueUniversal == 'val13' && targetUniversalname == 'event') {
      setEventName13(targetUniversalvalue);
    }


    if (valueUniversal == 'val13' && targetUniversalname == 'payment') {
      setPaymentType13(targetUniversalvalue);
    }




    if (valueUniversal == 'val14' && targetUniversalname == 'name') {
      setFullName14(targetUniversalvalue);
    }

    if (valueUniversal == 'val14' && targetUniversalname == 'vrn') {
      setVrn14(targetUniversalvalue);
    }

    if (valueUniversal == 'val14' && targetUniversalname == 'email') {
      setEmail14(targetUniversalvalue);
    }


    if (valueUniversal == 'val14' && targetUniversalname == 'phone') {
      setphoneContact14(targetUniversalvalue);
    }


    if (valueUniversal == 'val14' && targetUniversalname == 'event') {
      setEventName14(targetUniversalvalue);
    }


    if (valueUniversal == 'val14' && targetUniversalname == 'payment') {
      setPaymentType14(targetUniversalvalue);
    }





    if (valueUniversal == 'val15' && targetUniversalname == 'name') {
      setFullName15(targetUniversalvalue);
    }

    if (valueUniversal == 'val15' && targetUniversalname == 'vrn') {
      setVrn15(targetUniversalvalue);
    }

    if (valueUniversal == 'val15' && targetUniversalname == 'email') {
      setEmail15(targetUniversalvalue);
    }


    if (valueUniversal == 'val15' && targetUniversalname == 'phone') {
      setphoneContact15(targetUniversalvalue);
    }


    if (valueUniversal == 'val15' && targetUniversalname == 'event') {
      setEventName15(targetUniversalvalue);
    }


    if (valueUniversal == 'val15' && targetUniversalname == 'payment') {
      setPaymentType15(targetUniversalvalue);
    }





    if (valueUniversal == 'val16' && targetUniversalname == 'name') {
      setFullName16(targetUniversalvalue);
    }

    if (valueUniversal == 'val16' && targetUniversalname == 'vrn') {
      setVrn16(targetUniversalvalue);
    }

    if (valueUniversal == 'val16' && targetUniversalname == 'email') {
      setEmail16(targetUniversalvalue);
    }


    if (valueUniversal == 'val16' && targetUniversalname == 'phone') {
      setphoneContact16(targetUniversalvalue);
    }


    if (valueUniversal == 'val16' && targetUniversalname == 'event') {
      setEventName16(targetUniversalvalue);
    }


    if (valueUniversal == 'val16' && targetUniversalname == 'payment') {
      setPaymentType16(targetUniversalvalue);
    }




    if (valueUniversal == 'val17' && targetUniversalname == 'name') {
      setFullName17(targetUniversalvalue);
    }

    if (valueUniversal == 'val17' && targetUniversalname == 'vrn') {
      setVrn17(targetUniversalvalue);
    }

    if (valueUniversal == 'val17' && targetUniversalname == 'email') {
      setEmail17(targetUniversalvalue);
    }


    if (valueUniversal == 'val17' && targetUniversalname == 'phone') {
      setphoneContact17(targetUniversalvalue);
    }


    if (valueUniversal == 'val17' && targetUniversalname == 'event') {
      setEventName17(targetUniversalvalue);
    }


    if (valueUniversal == 'val17' && targetUniversalname == 'payment') {
      setPaymentType17(targetUniversalvalue);
    }






    if (valueUniversal == 'val18' && targetUniversalname == 'name') {
      setFullName18(targetUniversalvalue);
    }

    if (valueUniversal == 'val18' && targetUniversalname == 'vrn') {
      setVrn18(targetUniversalvalue);
    }

    if (valueUniversal == 'val18' && targetUniversalname == 'email') {
      setEmail18(targetUniversalvalue);
    }


    if (valueUniversal == 'val18' && targetUniversalname == 'phone') {
      setphoneContact18(targetUniversalvalue);
    }


    if (valueUniversal == 'val18' && targetUniversalname == 'event') {
      setEventName18(targetUniversalvalue);
    }


    if (valueUniversal == 'val18' && targetUniversalname == 'payment') {
      setPaymentType18(targetUniversalvalue);
    }





    if (valueUniversal == 'val19' && targetUniversalname == 'name') {
      setFullName19(targetUniversalvalue);
    }

    if (valueUniversal == 'val19' && targetUniversalname == 'vrn') {
      setVrn19(targetUniversalvalue);
    }

    if (valueUniversal == 'val19' && targetUniversalname == 'email') {
      setEmail19(targetUniversalvalue);
    }


    if (valueUniversal == 'val19' && targetUniversalname == 'phone') {
      setphoneContact19(targetUniversalvalue);
    }


    if (valueUniversal == 'val19' && targetUniversalname == 'event') {
      setEventName19(targetUniversalvalue);
    }


    if (valueUniversal == 'val19' && targetUniversalname == 'payment') {
      setPaymentType19(targetUniversalvalue);
    }



  }

  function togUniversal(bayno) {
    if (rowsTable.length > 0) {
      setsetRequired(true);
    }
    else {
      setsetRequired(false);
    }
    if (val1) {
      rowsTable.push({
        id: "val1",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }

    setVal1(!val1);

  }

  const togBay1 = () => {
    if (rowsTable.length > 0) {
      setsetRequired(true);
    }
    else {
      setsetRequired(false);
    }
    if (val1) {
      rowsTable.push({
        id: "val1",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }

    setVal1(!val1);
  };
  const togBay2 = () => {
    if (rowsTable.length == 0) {
      setsetRequired(true);
    }
    else {
      setsetRequired(false);
    }
    if (val2) {
      rowsTable.push({
        id: "val2",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }

    setVal2(!val2);
  };
  const togBay3 = () => {
    if (rowsTable.length == 0) {
      setsetRequired(false);
    }
    if (val3) {
      rowsTable.push({
        id: "val3",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }
    setVal3(!val3);
  };
  const togBay4 = () => {
    if (rowsTable.length == 0) {
      setsetRequired(false);
    }
    if (val4) {
      rowsTable.push({
        id: "val4",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }
    setVal4(!val4);
  };
  const togBay5 = () => {
    if (rowsTable.length == 0) {
      setsetRequired(false);
    }
    if (val5) {
      rowsTable.push({
        id: "val5",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }
    setVal5(!val5);
  };
  const togBay6 = () => {
    if (rowsTable.length == 0) {
      setsetRequired(false);
    }
    if (val6) {
      rowsTable.push({
        id: "val6",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }
    setVal6(!val6);
  };
  const togBay7 = () => {
    if (rowsTable.length == 0) {
      setsetRequired(false);
    }
    if (val7) {
      rowsTable.push({
        id: "val7",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }
    setVal7(!val7);
  };
  const togBay8 = () => {
    if (rowsTable.length == 0) {
      setsetRequired(false);
    }
    if (val8) {
      rowsTable.push({
        id: "val8",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }
    setVal8(!val8);
  };
  const togBay9 = () => {
    if (rowsTable.length == 0) {
      setsetRequired(false);
    }
    if (val9) {
      rowsTable.push({
        id: "val9",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }
    setVal9(!val9);
  };
  const togBay10 = () => {
    if (rowsTable.length == 0) {
      setsetRequired(false);
    }
    if (val10) {
      rowsTable.push({
        id: "val10",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }
    setVal10(!val10);
  };
  const togBay11 = () => {
    if (rowsTable.length == 0) {
      setsetRequired(false);
    }
    if (val11) {
      rowsTable.push({
        id: "val11",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }
    setVal11(!val11);
  };
  const togBay12 = () => {
    if (rowsTable.length == 0) {
      setsetRequired(false);
    }
    if (val12) {
      rowsTable.push({
        id: "val12",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }
    setVal12(!val12);
  };
  const togBay13 = () => {
    if (rowsTable.length == 0) {
      setsetRequired(false);
    }
    if (val13) {
      rowsTable.push({
        id: "val13",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }
    setVal13(!val13);
  };
  const togBay14 = () => {
    if (rowsTable.length == 0) {
      setsetRequired(false);
    }
    if (val14) {
      rowsTable.push({
        id: "val14",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }
    setVal14(!val14);
  };
  const togBay15 = () => {
    if (rowsTable.length == 0) {
      setsetRequired(false);
    }
    if (val15) {
      rowsTable.push({
        id: "val15",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }
    setVal15(!val15);
  };
  const togBay16 = () => {
    if (rowsTable.length == 0) {
      setsetRequired(false);
    }
    if (val16) {
      rowsTable.push({
        id: "val16",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }
    setVal16(!val16);
  };
  const togBay17 = () => {
    if (rowsTable.length == 0) {
      setsetRequired(false);
    }
    if (val17) {
      rowsTable.push({
        id: "val17",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }
    setVal17(!val17);
  };
  const togBay18 = () => {
    console.log(rowsTable);
    if (rowsTable.length == 0) {
      setsetRequired(false);
    }
    if (val18) {
      rowsTable.push({
        id: "val18",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }
    setVal18(!val18);
  };
  const togBay19 = () => {
    if (rowsTable.length == 0) {
      setsetRequired(false);
    }
    if (val19) {
      rowsTable.push({
        id: "val19",
      });
    }
    else {
      rowsTable.splice(0, 1);
    }
    setVal19(!val19);
  };




  const onClick = React.useCallback((id,baytype) => {  
  console.log(id);
    }, [])


  const onClickMarker = React.useCallback(function callback(e) {
    setzoomLevel('19');
    console.log(e);
  }, []);

  const [carparkSelectedlat, setcarparkSelectedlat] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelectedlat");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carparkSelectedlng, setcarparkSelectedlng] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelectedlng");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const center = {
    lat: 0.0,
    lng: 0.0
  };



  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e]).map(e => arr[e]);
    return unique;
  }

  useEffect(() => {
    // storing input name
    var filt = getUnique(info, "id");
    setfilteredParkingi(filt);
  }, [info]);




  console.log("carparkSelected");
  console.log(filteredParkingbay);
  console.log("carparkSelected");

  return isLoaded ? (
   
        <Paper
          sx={{
            margin: 2,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '75vh',
            overflow: 'auto',

          }}
        >


<Grid item xs={9}>
              <Box sx={{ flexGrow: 1 }}>
              <div style={{ height: '35vh', width: '100%', padding: '10px', margin: "5px" }}>
       

       <GoogleMap
         id="mapmycomponenet2"
         mapContainerStyle={containerStyle}
         center={center}
         zoom={parseFloat(zoomLevel)}
         options={options}
         onLoad={onLoad}
         onUnmount={onUnmount}
         onClick={onClickMarker}
       >
         { /* Child components, such as markers, info windows, etc. */ }
         <></>
         
         <MarkerClusterer options={options2} >
 
           {(clusterer) =>
             
            filteredParkingbay.map((location) => (
               <Marker 
               key={createKey(location.id)} 
               position={{lat: parseFloat(location.lat), lng: parseFloat(location.lng)}} 
               clusterer={clusterer} 
               icon = {iconStr} 
               label = {location.id} 
               animation = {google.maps.Animation.DROP}
               opacity = {1.0}
               onClick={() => onClick(location.id,location.baytype) }
               />
             )) 
           } 
         
         </MarkerClusterer>
         
       </GoogleMap>
       </div>
    
                      </Box>

              </Grid>
          <div align="center" style={{ fontSize: 20, fontWeight: 'bold' }}>New Bookings Form</div>

          <Box sx={{ flexGrow: 1 }}>

            <Grid container spacing={2}
              justifyContent="space-evenly"
              alignItems="center">
              <Grid item xs={3}>
                <div style={{ margin: '20px', }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={5} style={{ width: '220px', margin: '20px' }}>
                      <div style={{ margin: '7px', }}>
                        <DesktopDatePicker
                          label="Day In"
                          required
                          value={dayValue}
                          //maxDate={new Date(Date.now() + ( 3600 * 1000 * 24 * 30))}
                          onChange={(newValue) => {
                            setdayValueT(true);
                            getMyDay(newValue);
                            setdayValue(newValue);
                            setdayValueT(true);
                            setformsShow(false);
                            //console.log(rowsHb);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </div>
                      <div style={{ margin: '7px', }}>
                        <TimePicker
                          views={['hours']}
                          label="Time In"
                          required
                          value={timeIn}
                          onChange={(newValue) => {
                            setTimeInT(true);
                            getMyTimeIn(newValue);
                            setTimeIn(newValue);
                            setformsShow(false);
                            //console.log(rowsHb);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </div>
                      <div style={{ margin: '7px', }}>
                        <DesktopDatePicker
                          label="Day Out"
                          required
                          value={dayOutValue}
                          //maxDate={new Date(Date.now() + ( 3600 * 1000 * 24 * 30))}
                          onChange={(newValue) => {
                            setdayOutValueT(true);
                            getMyDayOut(newValue);
                            setdayOutValue(newValue);
                            setformsShow(false);
                            //console.log(rowsHb);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </div>
                      <div style={{ margin: '7px', }}>
                        <TimePicker
                          views={['hours']}
                          minutesStep={60}
                          label="Time Out"
                          required
                          value={timeOut}
                          onChange={(newValue) => {
                            setTimeOutT(true);
                            getTimeOut(newValue);
                            setTimeOut(newValue);
                            setformsShow(false);
                            //console.log(rowsHb);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </div>

                      

{
  (carparkSelected.length > 0)
  && (dayValue != null)
  && (timeIn != null)
  && (dayOutValue != null)
  && (timeOut != null)

  ?
  <div style={{ margin: "10px" }}>
                        <Chip color="success" icon={<SearchIcon />} label="Search Free Bays" onClick={triggerChange} />
                      </div>
  :
  <div></div>

}
                      
                    </Stack>
                  </LocalizationProvider>
                </div>
              </Grid>

              {(formsShow) ?


                <form onSubmit={toReport}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    key='row.id'
                  >
                    {rowsTable.map((row) => (
                      <Grid>
                        <TextField
                          required
                          label="Full Name"
                          variant="filled"
                          value={onchangeName(row.id, 'name')}
                          onChange={e => onchangeUniversal(row.id, 'name', e.target.value)}
                        />
                        <TextField
                          label="Vehicle Reg. No."
                          variant="filled"
                          required
                          value={onchangeName(row.id, 'vrn')}
                          onChange={e => onchangeUniversal(row.id, 'vrn', e.target.value)}
                        />
                        <TextField
                          label="Email"
                          variant="filled"
                          type="email"
                          required
                          value={onchangeName(row.id, 'email')}
                          onChange={e => onchangeUniversal(row.id, 'email', e.target.value)}
                        />
                        <TextField
                          label="Event Or Host Name"
                          variant="filled"
                          required
                          value={onchangeName(row.id, 'event')}
                          onChange={e => onchangeUniversal(row.id, 'event', e.target.value)}
                        />
                        <TextField
                          label="Phone"
                          variant="filled"
                          required
                          value={onchangeName(row.id, 'phone')}
                          onChange={e => onchangeUniversal(row.id, 'phone', e.target.value)}
                        />
                        <TextField
                          id="paymentType"
                          required
                          select
                          label="Select"
                          value={onchangeName(row.id, 'payment')}
                          onChange={e => onchangeUniversal(row.id, 'payment', e.target.value)}
                        //helperText="Please select your currenc"
                        >
                          {paymentTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>

                      </Grid>

                    )
                    )
                    }

                    <div>
                      <Button color="success" type="submit" className="btn btn-info btn-circle btn-lg">Book</Button>
                    </div>

                  </Grid>
                </form>

                :
                <div>
                </div>
              }



              {/*
    <Link color="secondary" href="#" onClick={addDaysNew} sx={{ mt: 3 }}>
        See more orders
      </Link>
      */}
            </Grid>
          </Box>

        </ Paper>

  ) : <></>;
}
