import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ToggleColorMode from './Dashboard';
import CustomerAdd from './CustomerAdd';
import SpacesAdd from './SpacesAdd';
import FeesAdd from './FeesAdd';
import FinalAdd from './FinalAdd';
import DatabaseAdd from './DatabaseAdd';
import { useEffect, useState } from 'react';
import { db } from "./Login";




function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



const steps = ['Customer', 'Spaces', 'Fees', 'Review', 'Database'];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <CustomerAdd />;
    case 1:
      return <SpacesAdd />;
    case 2:
        return <FeesAdd />;
    case 3:
        return <FinalAdd />;
    case 4:
        return <DatabaseAdd />;
    
    default:
      throw new Error('Unknown step');
  }
}

const theme = createTheme();

export default function BlueMeshAdmin() {

  const [activeStep, setActiveStep] = React.useState(0);

  const [nameCustomer, setnameCustomer] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("nameCustomer");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [locationCustomer, setlocationCustomer] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("locationCustomer");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [spacesCustomer, setspacesCustomer] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("spacesCustomer");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [feesCustomer, setfeesCustomer] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("feesCustomer");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  useEffect(() => {
    // storing input name
    localStorage.setItem("nameCustomer", JSON.stringify(nameCustomer));
  },[nameCustomer]);


  useEffect(() => {
    // storing input name
    localStorage.setItem("locationCustomer", JSON.stringify(locationCustomer));
  },[locationCustomer]);



  useEffect(() => {
    // storing input name
    localStorage.setItem("spacesCustomer", JSON.stringify(spacesCustomer));
  },[spacesCustomer]);




  useEffect(() => {
    // storing input name
    localStorage.setItem("feesCustomer", JSON.stringify(feesCustomer));
  },[feesCustomer]);


  function doNothing() {
    window.location.reload(true);
}

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <ToggleColorMode>
    <React.Fragment>

      <Container component="main" maxWidth="" sx={{ mb: 3 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h5" align="center">
            Add Customer
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? doNothing() : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      Back
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    onClick={handleNext}    
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </Container>
    </React.Fragment>
    </ToggleColorMode>
  );
}