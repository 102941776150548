import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { db } from "./Login";


export default function DatabaseAdd() {
    const [nameCustomer, setnameCustomer] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("nameCustomer");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [locationCustomer, setlocationCustomer] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("locationCustomer");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [spacesCustomer, setspacesCustomer] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("spacesCustomer");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [feesCustomer, setfeesCustomer] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("feesCustomer");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });


    
      useEffect(() => {
        // storing input name
        db.collection('customer').doc(nameCustomer).set({
            nameCustomer: nameCustomer,
            locationCustomer: locationCustomer,
            spacesCustomer: spacesCustomer,
            feesCustomer: feesCustomer,
        });
      },);
    
  return (
    <React.Fragment>
    <Typography variant="h6" gutterBottom align="center" >
      Finished!
    </Typography>
    <Typography variant="subtitle1" align="center" >
      A new customer has been added to the database.
    </Typography>
  </React.Fragment>
  );
}