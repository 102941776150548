import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HeaderBar = styled.header`
left: 0px;
top: 0px;
    width: 100px;
    padding: 0;
    display: flex;
    height: 95px;
    position: fixed;
    align-items: center;
    background: #ffffff;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.25);
    z-index: 1;
`;

const Nav = styled.nav`
left: 30%;
    padding: 1em;
    background: #ffffff;
    @media (max-width: 700px) {
        padding-top: 0px;
    }
    @media (min-width: 700px) {
        position: fixed;
        width: 100%;
        height: 100px;
        overflow-x: scroll;
    }
`;
const NavList = styled.ul`
    display: flex;
    flex-direction: row;
    margin: 45px;
    padding: 0px;
    list-style: none;
    line-height: 2;
    a {
        color: #000000;
        padding: 20px;
        text-decoration: none;
        font-weight: bold;
        font-size: 1em;
        
    }
    a:visited {
        color: #0077cc;
    }
    a:hover 
    {
        color: #000000;
    }
    a:focus {
        color: #000000;
    }
`;
const Navigate = () => {
    return (
        <Nav>
            <NavList>
                <HeaderBar>
                    <img src={'/images/logo.jpg'} alt="Park Here Now Logo" height="120" width="120" />
                </HeaderBar>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/FirstPage">My Account</Link>
                </li>
                <li>
                    <Link to="/dateselect">Start Booking</Link>
                </li>
            </NavList>
        </Nav>
    );
};
export default Navigate;