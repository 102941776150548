import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { db } from "./Login";
import {auth} from "./Login";
import { useAuthState } from "react-firebase-hooks/auth";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function CarPark() {


  const [info2, setInfo2] = useState([]);

  const [carParks, setcarParks] = useState([]);


  const [admin, setAdmin] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("admin");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });
  const [user, loading, error] =  useAuthState(auth);

  const [showall, setshowall] = useState(false);

  const [info, setInfo] = useState([]);
  const [filteredParking, setfilteredParking] = useState([]);

  const [filteredParkingbay, setfilteredParkingbay] = useState([]);

  const fetchUserName = async () => {
    try {
      const query = await db
        .collection("users")
        .doc(user?.uid)
        .get();
      const userName = query.data();
      //console.log(userName);
      setAdmin(userName.adminA);
      //console.log(userName.adminA);
    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };

  const fetchParking = async () => {
    try {
      db.collection("parking_lots").get().then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.forEach(element => {
          var data = element.data();
          if (data.adminA == admin)
          {
            setInfo(arr => [...arr, data]);
          }
          
        });
      });

    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };

  const fetchParkingLots = async () => {
    var whatPark = '';
    var carParksList = [];
    try {
      db.collection("parking_lots").get().then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.forEach(element => {
          var data = element.data();
          if (data.parking == carparkSelected)
          {
            whatPark = data.baytype;
          }
          
        });
      });

    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    };
    try {
      db.collection("carParks").get().then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.forEach(element => {
          var data = element.data();
          if (data.carPark == whatPark)
          {
            carParksList.push(data);
          }
          
        });
        setcarParks(carParksList);
      });

    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    };
  };

  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e]).map(e => arr[e]);
    return unique;
  }

  useEffect(() => {
    // storing input name
    if(!admin)
    {
      fetchUserName();
    }
    else 
    {
      console.log('fetchParking');
      fetchParking();
    };
  }, []);


  useEffect(() => {
    // storing input name
    fetchParking();
  }, [admin]);


  useEffect(() => {
    // storing input name
    var filt = getUnique(info, "baytype");
    setfilteredParking(filt);
  }, [info]);

  const [carparkSelected, setcarparkSelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  useEffect(() => {
    // s
    console.log("fetchBays();");
    fetchParkingLots();
    fetchBays();
    localStorage.setItem("carparkSelected", JSON.stringify(carparkSelected));
    
    filteredParking.forEach( dat => 
      {if (dat.parking == carparkSelected)
      {
        localStorage.setItem("carparkSelectedName", JSON.stringify(dat.baytype));
        localStorage.setItem("carparkSelectedlat", JSON.stringify(dat.lat));
        localStorage.setItem("carparkSelectedlng", JSON.stringify(dat.lng));

      }}
    );

  }, [carparkSelected]);

  function generateAll(valueGot) {
    console.log(valueGot);
    setcarparkSelected(valueGot);
    setshowall(true);
  };


  function getUnique2(arr, index) {
    const unique = arr
      .map(e => e[index])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e]).map(e => arr[e]);
    return unique;
  }

  useEffect(() => {
    // storing input name

    var filt = getUnique2(info2, "id");
    setfilteredParkingbay(filt);
  }, [info2]);


  const fetchBays = async () => {
    var beac = [];
    try {
      db.collection("beacons").get().then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.forEach(element => {
          var data = element.data();
          data['icon'] = "./images/m1.png";
          if (data.parking == carparkSelected) {
            beac.push(data);
          };
        });
        setInfo2(beac);
      });

    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };


  useEffect(() => {
    // storing input name
    localStorage.setItem("filteredParkingbay", JSON.stringify(filteredParkingbay));
  }, [filteredParkingbay]);



  return (
    <React.Fragment>

      <Grid
        container spacing={4}
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >

        <Grid item xs={5}>
          <TextField fullWidth
            id="carparks"
            required
            select
            label="Car Parks"
            value={carparkSelected}
            onChange={e => generateAll(e.target.value)}
          //helperText="Please select your currenc"
          >
            {filteredParking.map((option) => (
              <MenuItem key={option.parking} value={option.parking}>
                {option.baytype}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

{carParks.length > 0 

?


<List disablePadding>

<ListItem key={carparkSelected} sx={{ py: 3, px: 0 }}>

  <Table size="small">
    <TableHead>
      <TableRow>

        <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Bay</TableCell>
        <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Monday-Friday</TableCell>
        <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Saturday-Sunday</TableCell>
        <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Admin Name</TableCell>
        <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Admin Email</TableCell>
        <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Admin Phone</TableCell>

      </TableRow>
    </TableHead>
    <TableBody>
      {carParks.map((row) => (
        <TableRow key={row.id}>
          <TableCell align="center">{row.carParkspace}</TableCell>
          <TableCell align="center">{row.week}</TableCell>
          <TableCell align="center">{row.end}</TableCell>
          <TableCell align="center">{row.adminName}</TableCell>
          <TableCell align="center">{row.adminEmail}</TableCell>
          <TableCell align="center">{row.adminNo}</TableCell>

        </TableRow>
      ))}
    </TableBody>
  </Table>
</ListItem>
</List>


:

<></>

}

    </React.Fragment>
  );
}