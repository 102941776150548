import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey, blue, green, purple } from '@mui/material/colors';

import { useEffect, useState } from "react";
import {Link, useNavigate } from "react-router-dom";
import {auth, signInWithEmailAndPassword, signInWithGoogle } from "./Login";
import { useAuthState } from "react-firebase-hooks/auth";
import "../css/style.css";
import { db } from "./Login";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';



function Copyright(props) {
    return (
      <Typography variant="body2" align="center" {...props} 
      >
        {'Copyright © '}
        <a  target="_blank" href='https://bluemeshsolutions.com/' sx={{ pt: 0, color: 'text.info'  }}>
          Blue Mesh Solutions
        </a>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  



const theme = createTheme({  
    palette: {
    primary: {
      main: blue[500], 
    },
    secondary: {
      main: green[500],
    },
  }});

export default function SignInSide() {

    const auth = firebase.auth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, loading, error] =  useAuthState(auth);
    const navigate = useNavigate();

    const [admin, setAdmin] = useState("");

    useEffect(() => {
      // storing input name
      localStorage.setItem("admin", JSON.stringify(admin));
    }, [admin]);

    const fetchUserName = async () => {
      try {
        const query = await db
          .collection("users")
          .doc(user?.uid)
          .get();
        const userName = query.data();
        setAdmin(userName.adminA); 
    console.log(admin);

if ((userName.admin == "blue") || (userName.adminA == "super_admin") || (userName.adminB == "local_admin"))
{navigate('Bookings');}
else {navigate('NoAdmin');}
      } catch (err) {
        console.error(err);
        //alert("An error occured while fetching user data");
      }
    };

    useEffect(() => {
      if (loading) {
        // maybe trigger a loading screen
        return;
      }
      if (user) 
      {
        fetchUserName();
      }
    
    }, [user, loading]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    signInWithEmailAndPassword(data.get('email'), data.get('password'))
    console.log({
      //email: data.get('email'),
      //password: data.get('password'),
    });

  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '75vh', backgroundColor: 'rgba(229, 232, 235, 0.863)'}}>
        <CssBaseline />
        <Grid
          item
          xs={2}
          sm={2}
          md={4}
          sx={{
            backgroundImage: 'url(images/logo.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[500] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '75vh',
        
          }}
        />
        <Grid item xs={10} sm={10} md={8} component={Paper} elevation={22}
        sx={{ height: '75vh'}} 
         square>
          <Box
            sx={{
              my: 1,
              mx: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link to="/reset">
                    Forgot password?
                  </Link>
                </Grid>

              </Grid>
              <Copyright sx={{ mt: 6 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

