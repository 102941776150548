import React from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import ClientPicker from "./components/ClientPicker";
import NotFound from "./components/NotFound";
import Login from "./components/LogInFunc";
import FirstPage from "./components/FirstPage";
import Reset from "./components/Reset";
import Register from "./components/Register";
import Layout from './components/Layout';
import Ampm from "./components/Ampm";
import DateSelect from "./components/DateSelect";
import BeaconSelect from "./components/Beaconselect";
import UserForm from "./components/Formdetails";
import Confirmation from "./components/Confirmation";
import FirstPageList from "./components/listItems";
import Bookings_CL from "./components/Bookings";
import Bookings_CL_New from "./components/newBooking";
import SignInSide from "./components/SignInNew";
import CancelBooking from "./components/CancelBooking";
import Lottery from "./components/Lottery";
import BlueMeshAdmin from "./components/BlueMeshAdmin";
import SuperAdmin from "./components/SuperAdmin";
import NoAdmin from "./components/NoAdmin";
import CarPark from "./components/CarPark";
import CarParkScheme from "./components/CarParkScheme";
import AdminInfo from "./components/AdminInfo";
import FinalAdmin from "./components/FinalAdmin";
import DatabaseAdd from "./components/DatabaseAdd";
import MapContainer from './components/MapContainer';
import WithMarker from './components/WithMarker';
import MapMyComponent from "./components/MapMyComponent";
import UploadBays from "./components/UploadBays";
import MapMyComponent2 from "./components/MapMyComponent2";
import BookingAdmin from "./components/BookingAdmin";
import BookingCarPark from "./components/BookingCarPark";
import FinalAdminBooking from "./components/FinalAdminBooking";
import UploadBaysBooking from "./components/UploadBaysBooking";



function App() {
  return(
    <div>
    <Router>
    <Routes>
    <Route exact path="/" element={<SignInSide />} />
    <Route exact path="Bookings" element={<Bookings_CL/>} />
    <Route exact path="ClientPicker" element={<ClientPicker/>} />
    <Route path="client/:storeId" element={<UserForm/>} />
    <Route path="reset" element={<Reset/>} />
    <Route path="register" element={<Register/>} />
    <Route path="dateselect" element={<DateSelect/>} />
    <Route path="beaconselect" element={<BeaconSelect/>} />
    <Route exact path="Ampm" element={<Ampm/>} />
    <Route exact path="confirmation" element={<Confirmation/>} />
    <Route exact path="NewBooking" element={<Bookings_CL_New/>} />
    <Route exact path="CancelBooking" element={<CancelBooking/>} />
    <Route exact path="Lottery" element={<Lottery/>} />
    <Route exact path="BlueMeshAdmin" element={<BlueMeshAdmin/>} />
    <Route exact path="SuperAdmin" element={<SuperAdmin/>} />
    <Route exact path="NoAdmin" element={<NoAdmin/>} />
    <Route exact path="CarPark" element={<CarPark/>} />
    <Route exact path="CarParkScheme" element={<CarParkScheme/>} />
    <Route exact path="AdminInfo" element={<AdminInfo/>} />
    <Route exact path="FinalAdmin" element={<FinalAdmin/>} />
    <Route exact path="DatabaseAdd" element={<DatabaseAdd/>} />
    <Route exact path="MapContainer" element={<MapContainer/>} />
    <Route exact path="WithMarker" element={<WithMarker/>} />
    <Route exact path="MapMyComponent" element={<MapMyComponent/>} />
    <Route exact path="UploadBays" element={<UploadBays/>} />
    <Route exact path="MapMyComponent2" element={<MapMyComponent2/>} />
    <Route exact path="BookingAdmin" element={<BookingAdmin/>} />
    <Route exact path="BookingCarPark" element={<BookingCarPark/>} />
    <Route exact path="FinalAdminBooking" element={<FinalAdminBooking/>} />
    <Route exact path="UploadBaysBooking" element={<UploadBaysBooking/>} />

    
    <Route element={<NotFound />} />  
  </Routes>
</Router>
</div>
  );

  }

export default App;
