import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';


export default function FinalAdd() {
    const [nameCustomer, setnameCustomer] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("nameCustomer");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [locationCustomer, setlocationCustomer] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("locationCustomer");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [spacesCustomer, setspacesCustomer] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("spacesCustomer");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });

      const [feesCustomer, setfeesCustomer] = useState(() => {
        // getting stored value
        const saved = localStorage.getItem("feesCustomer");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
      });
      
      

  return (
    <React.Fragment>

      <List disablePadding>
        
          <ListItem key={nameCustomer} sx={{ py: 1, px: 0 }}>
            <ListItemText primary="Name" secondary={nameCustomer} />
          
          </ListItem>

          <ListItem key={locationCustomer} sx={{ py: 1, px: 0 }}>
            <ListItemText primary="Location" secondary={locationCustomer} />
            
          </ListItem>

          <ListItem key={spacesCustomer} sx={{ py: 1, px: 0 }}>
            <ListItemText primary="Spaces" secondary={spacesCustomer} />

            </ListItem>

          <ListItem key={feesCustomer} sx={{ py: 1, px: 0 }}>
            <ListItemText primary="Fee per Bay[£]" secondary={feesCustomer} />
           
          </ListItem>
    
      </List>

    </React.Fragment>
  );
}