import React, { Component } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import { MarkerClusterer } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { db } from "./Login";
import { map } from 'lodash';



const paymentTypes = [

  {
    value: 'Free',
    label: 'Free',
  },
  {
    value: 'Per Hour',
    label: 'Per Hour',
  },
  {
    value: 'Guest or Permit Free',
    label: 'Guest or Permit Free',
  },
  {
    value: 'Elapsed Time',
    label: 'Elapsed Time',
  },
];

const paymentTypesw = [


  {
    value: 'Free',
    label: 'Free',
  },
  {
    value: 'Per Hour',
    label: 'Per Hour',
  },
  {
    value: 'Guest or Permit Free',
    label: 'Guest or Permit Free',
  },
  {
    value: 'Elapsed Time',
    label: 'Elapsed Time',
  },
];

const mapStyles = [
  {
    stylers: [
      {
        hue: '#4da6ff',
      },
      {
        saturation: 5,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        lightness: 30,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
];


const options2 = {
  maxZoom: 17,
  imagePath:
    './images/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
};

function createKey(location) {
  return location
};

const containerStyle = {
  width: '100%',
  height: '100%'
};



const options = {
  styles: mapStyles,
};








function MapMyComponent(props) {



  const [list_bays_new, setListBays_new] = useState([]);

  const [bays_new, setBays_new] = useState([]);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAHXPTUj2yO7cLM89zIl3o-XMyEqGgEWgA"
  });



  const [iconStr, seticonStr] = React.useState("./images/m1.png");

  const [mapa, setMap] = React.useState(null);

  const [zoomLevel, setzoomLevel] = React.useState('18');

  const [info2, setInfo2] = useState([]);


  const [info, setInfo] = useState([]);

  const [carParkScheme, setcarParkScheme] = useState([]);
 
  const [carParkSchemew, setcarParkSchemew] = useState([]);


  const [filteredParkingbay, setfilteredParkingbay] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("filteredParkingbay");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const onLoad = React.useCallback(function callback(mapa2) {
    //const bounds = new window.google.maps.LatLngBounds(center);
    //mapa2.fitBounds(bounds);
    //setMap(mapa);
  }, [])

  const onUnmount = React.useCallback(function callback(mapa2) {
    setMap(null);
  }, [])

  const [idbaySelected, setidbaySelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("idbaySelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [baytypeSelected, setbaytypeSelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("baytypeSelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const onClick = React.useCallback((id, icon) => {
 
    var copyMe = [];
    filteredParkingbay.forEach(element => 
      {
        if(element["id"] == id)
        {
          (element["icon"]=="./images/m1.png")
          
          ?
          (element["icon"] = "./images/m2.png")
          :
          (element["icon"] = "./images/m1.png")
          ;
          copyMe.push(element);
        } else
        {
          copyMe.push(element);
        };
    });
    setfilteredParkingbay(copyMe);
   get_bays(id);
  }, [])


  const onClickMarker = React.useCallback(function callback(e) {
    setzoomLevel('19');
    setcenterlat(parseFloat(filteredParkingbay[1].lat));
    setcenterlng(parseFloat(filteredParkingbay[1].lng));
    //console.log(e);
  }, [])

  const [carparkSelected, setcarparkSelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carparkSelectedlat, setcarparkSelectedlat] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelectedlat");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carparkSelectedlng, setcarparkSelectedlng] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelectedlng");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem("carparkSelected", JSON.stringify(carparkSelected));
  }, [carparkSelected]);

  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e]).map(e => arr[e]);
    return unique;
  }

  function get_bays(bays_selected) {
    const listOfObjects = [];
    filteredParkingbay.forEach(element => {
      if (element['icon'] == "./images/m2.png")
      {
        var singleObj = {}
        singleObj['id'] = element['id'];
        singleObj['week'] = "Select";
        singleObj['end'] = "Select";
        listOfObjects.push(singleObj);
      }
    });
    setListBays_new(listOfObjects);
  }

  function deleteBay(which_bay, arrBays) {

    var newList = [];
    var copyA = [];
    copyA = arrBays;
    const index = 'id';
    const unique = copyA
    .map(e => e[index])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter(e => copyA[e]).map(e => copyA[e]);

    newList = unique.filter((item) => item.id !== which_bay);

    //setListBays_new(newList);
  }

  function setValues(bayV, id)
  {
    
    var bays = [];
    bays = list_bays_new;
    bays.forEach(element => 
        {
          
            element['week'] = bayV;
          
        }
    );
    console.log(bays);
    setListBays_new(bays);
    setcarParkScheme(bayV);
   
  }

  function setValuesw(bayV, id)
  {
  
    var bays = [];
    bays = list_bays_new;
    bays.forEach(element => 
        {
          
            element['end'] = bayV;
         
        }
    );
    setcarParkSchemew(bayV);
    setListBays_new(bays);
  
  }

  useEffect(() => {
    // storing input name
    localStorage.setItem("carParkScheme", JSON.stringify(carParkScheme));
  }, [carParkScheme]);

  useEffect(() => {
    // storing input name
    localStorage.setItem("carParkSchemew", JSON.stringify(carParkSchemew));
  }, [carParkSchemew]);


  useEffect(() => {
    // storing input name
    localStorage.setItem("filteredParkingbay", JSON.stringify(filteredParkingbay));
  }, [filteredParkingbay]);

  useEffect(() => {
    // storing input name
    localStorage.setItem("list_bays_new", JSON.stringify(list_bays_new));
  }, [list_bays_new]);


  const [centerlat, setcenterlat] = React.useState(parseFloat(carparkSelectedlat));

  const [centerlng, setcenterlng] = React.useState(parseFloat(carparkSelectedlng));

  const center = { lat: centerlat, lng: centerlng };

  console.log(filteredParkingbay);

  return isLoaded ? (

    <React.Fragment>
      <div style={{ height: '35vh', width: '100%', padding: '10px', margin: "5px" }}>


        <GoogleMap
          id="mapmycomponenet"
          mapContainerStyle={containerStyle}
          center={center}
          zoom={parseFloat(zoomLevel)}
          options={options}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={onClickMarker}
        >
          { /* Child components, such as markers, info windows, etc. */}
          <></>



          
              {filteredParkingbay.map((location) => (
                <Marker
                  key={createKey(location.id)}
                  position={{ lat: parseFloat(location.lat), lng: parseFloat(location.lng) }}
                  //clusterer={clusterer}
                  icon={location.icon}
                  label={location.id}
                  animation={google.maps.Animation.DROP}
                  opacity={1.0}
                  onClick={() => onClick(location.id, location.icon)}
                />
              ))}
            

        </GoogleMap>
      </div>


      {list_bays_new.length > 0

        ?
        <Table size="small">
          <TableHead>
            <TableRow>

              <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Bay</TableCell>
              <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Monday-Friday</TableCell>
              <TableCell align="center" style={{ fontSize: 16, fontWeight: 'bold' }}>Saturday-Sunday</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {list_bays_new.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">{row.id}</TableCell>
                <TableCell align="center">
                <TextField fullWidth
                
                id="paymentType"
                required
                select
                label="Select"
                value={carParkScheme}
                onChange={e => setValues(e.target.value, row.id)}

              >
                {paymentTypes.map((option) => (
                  <MenuItem key={row.id} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
</TableCell>
                <TableCell align="center">
                <TextField fullWidth
                
                id="paymentType"
                required
                select
                label="Select"
                value={carParkSchemew} 
                onChange={e => setValuesw(e.target.value, row.id)}

              >
                {paymentTypesw.map((option) => (
                  <MenuItem key={row.id+"w"} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        :
        <></>
      }
    </React.Fragment>
  ) : <></>
}

export default MapMyComponent;