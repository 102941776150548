import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import EventAvailable from '@mui/icons-material/EventAvailable';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import DeleteIcon from '@mui/icons-material/Delete';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';


import Typography from '@mui/material/Typography';
import { List, ListItemButton } from '@mui/material';
import { logout } from './Login';
import { useNavigate } from "react-router";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "./Login";
import { useEffect, useState } from "react";


function FirstPageList(props) {


  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  

 


  const [admin, setAdmin] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("admin");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [adminA, setAdminA] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("adminA");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const navigate = useNavigate();

  useEffect(() => {
    // storing input name
    localStorage.setItem("adminA", JSON.stringify(adminA));
  }, [adminA]);

  useEffect(() => {
    // storing input name
    localStorage.setItem("admin", JSON.stringify(admin));
  }, [admin]);

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (!user) navigate("/");
  }, [user, loading]);
  const gotobooking = () => {
    return navigate("DateSelect");
  };
  const backtologin = () => {
    auth.signOut();
    navigate('/');
  };
  const fetchUserName = async () => {
    try {
      const query = await db
        .collection("users")
        .doc(user?.uid)
        .get();
      const userName = query.data();
      setName(userName.name);
      //setAdmin(userName.admin);
      setAdminA(userName.adminA);

    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };
  function donothing() {};
  useEffect(() => {
    if (loading) return;
    //if (!user) return navigate.replace("/");
    fetchUserName();

  }, [user, loading]);



  const toBookings = (props) => {
    navigate('/Bookings');
    window.location.reload(false);
  };

  const toNewBooking = (props) => {
    navigate('/BookingAdmin');
  };

  const toCancelBooking = (props) => {
    navigate('/CancelBooking');
  };

  const toLottery = (props) => {
    navigate('/Lottery');
  };


  const toBlueMeshAdmin = (props) => {
    navigate('/BlueMeshAdmin');
  };

  const toSuperAdmin = (props) => {
    navigate('/SuperAdmin');
  };




  return (
    <List>
      <ListSubheader inset> {name} </ListSubheader>
      {
        (false) ?
        <ListItem button onClick={toBlueMeshAdmin}>
        <ListItemIcon>
          <AdminPanelSettingsIcon  color="success" />
        </ListItemIcon>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="#FFFFFF"
              >
                Blue Mesh Admin
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>

      :

      donothing()

      }


      {
        (adminA == 'super_admin') ?
        <ListItem button onClick={toSuperAdmin}>
        <ListItemIcon>
          <AdminPanelSettingsIcon color="success" />
        </ListItemIcon>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="#FFFFFF"
              >
                Super Admin
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>

      :

      donothing()

      }

      <ListItem button onClick={toNewBooking}>
        <ListItemIcon>
          <EventAvailable  color="success" />
        </ListItemIcon>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color=""
              >
                New Booking
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>

      <ListItem button onClick={toCancelBooking}>
        <ListItemIcon>
          <DeleteIcon color="success" />
        </ListItemIcon>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Cancel Booking
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>



      <ListItem button onClick={toBookings}>
        <ListItemIcon>
          <BarChartIcon color="success" />
        </ListItemIcon>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Reports
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>

      {/* <ListSubheader inset>Client</ListSubheader>*/}

      <ListItem button onClick={toLottery}>
        <ListItemIcon>
          <EmojiEventsIcon color="success" />
        </ListItemIcon>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Lottery
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>


      <ListItem button>
        <ListItemIcon>
          <PeopleIcon color="success" />
        </ListItemIcon>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Clients
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>

      <ListItem button>
        <ListItemIcon>
          <AddIcon color="success" />
        </ListItemIcon>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Add Client
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>

      {/* <ListSubheader inset>Account</ListSubheader> */}

      <ListItem button>
        <ListItemIcon>
          <SettingsIcon color="success" />
        </ListItemIcon>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Manage Account
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <ListItem button onClick={backtologin}>
        <ListItemIcon>
          <ExitToAppIcon color="success" />
        </ListItemIcon>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Logout
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>

    </List>

  )
}
export default FirstPageList;