import * as React from 'react';
import { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MenuIcon from '@mui/icons-material/Menu';

import FirstPageList from './listItems';


import { useTheme} from '@mui/material/styles';
//import Chart from './Chart';
import Deposits from './Deposits';
import Bookings_CL from './Bookings';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { amber, blue, deepOrange, grey, purple } from '@mui/material/colors';
import Order from './Order';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export default function ToggleColorMode({children}) { 
  const savedtoggleView = JSON.parse(localStorage.getItem('toggleView'));
  const savedmodeView = JSON.parse(localStorage.getItem('modeView'));

  const [mode, setMode] = React.useState(savedmodeView || 'light');
  const [open, setOpen] = React.useState(savedtoggleView || false);
  useEffect(() => {
    if ( open!==savedtoggleView) 
    {localStorage.setItem('toggleView', JSON.stringify(open));}
    if ( mode !==  savedmodeView)
    {localStorage.setItem('modeView', JSON.stringify(mode));}
  });

  const toggleDrawer = () => {
    setOpen(!open);
    localStorage.setItem('toggleView', JSON.stringify(!open));
  };
 
  const colorMode = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode) =>
          prevMode === 'light' ? 'dark' : 'light',
        );
        localStorage.setItem('modeView', JSON.stringify(mode));
        //console.log(mode);
      },
    }),
    [],
  );


  const getDesignTokens = (mode) => ({
    typography: {
      fontSize: 18,
      fontFamily: [
        'Josefin Sans',
        'sans-serif',
      ].join(','),
   },
    palette: {
      mode,
      ...(mode === 'light'
        ? {
          alternateTextColor: '#ff0000',
            // palette values for light mode
            primary: {
              light: '#ffffff',
              main: "#2e7d32",
              dark: '#ffffff',
              contrastText: '#ffffff',
            },
            secondary: {
              light: "#003A55",
              main: "#003A55",
              dark: '#ffffff',
              contrastText: '#fff',
            },
      
            divider: "#FFFFFF",
            background: {
              default: "#003A55",
              paper:"#003A55",
            },  
            text: {
              primary: "#FFFFFF",
              secondary: "#FFFFFF",
              info: '#ffffff',
            },
          }
        : 
        {
          alternateTextColor: '#ffffff',
            // palette values for light mode
            primary: {
              light: '#ffffff',
              main: "#66bb6a",
              dark: '#ffffff',
              contrastText: '#ffffff',
            },
            secondary: {
              light: "#003A55",
              main: "#003A55",
              dark: '#ffffff',
              contrastText: '#fff',
            },
      
            divider: "#FFFFFF",
            background: {
              default: "#003A55",
              paper:"#003A55",
            },  
            text: {
              primary: "#FFFFFF",
              secondary: "#FFFFFF",
              info: "#FFFFFF",
            },
          }
        ),
    },
  });

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <React.Fragment>
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
      <Box sx={{
        display: 'flex',
      }}>
        <CssBaseline />
        <AppBar color="secondary" position="absolute" open={open} >
          <Toolbar
          color="primary"
            sx={{
              pr: '3px', 
        // keep right padding when drawer closed
            }} >
            <IconButton
              edge="start"
              color="secondary"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '3px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon color="success"/>
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              noWrap
              sx={{ flexGrow: 1, fontSize: 18}}
            > 
              Dashboard
            </Typography>

            <Typography
              component="h6"
              variant="h6"
              sx={{ flexGrow: 0, fontSize: 18}}
            > 
              {/*{theme.palette.mode[0].toUpperCase() + theme.palette.mode.slice(1)} Mode */}
            </Typography>

            <IconButton onClick={colorMode.toggleColorMode} >
        {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>

            <IconButton >
              <Badge badgeContent={"0"} color="secondary">
                <NotificationsIcon color="success"/>
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar> 
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon color="success"/>
            </IconButton>
          </Toolbar>
          <Divider />
          {<FirstPageList/>}
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 2,
        alignItems: 'center',
        justifyContent: 'center',
            overflow: 'auto',
            color: 'text.info' ,
          }}
        >
        <Toolbar />
        {children}

       
        </Box>
      </Box>
      <Container maxWidth="lg" sx={{ mt: 0, mb: 0, }}>
            <Copyright sx={{ pt: 0 }}/>
          </Container>
          
      </ThemeProvider>
    </ColorModeContext.Provider>
    </React.Fragment>
  );
}


function Copyright(props) {
  return (
    <Paper
          sx={{
            margin: 1,
            p: 0.3,
            display: 'flex',
            flexDirection: 'column',
            height: '5vh',
            overflow: 'auto',
            backgroundColor: 'white',
          }}
        >
    <Typography variant="body2" align="center" {...props}  sx={{ flexGrow: 1, fontSize: 16, fontWeight: 'bold', color: 'black'}}
    >
      {'Copyright © '}
      <Link href="https://bluemeshsolutions.com/" sx={{ flexGrow: 1, fontSize: 16, fontWeight: 'bold', color: '#000000' }}>
      Blue Mesh Solutions
      </Link>{' '}
      {new Date().getFullYear()}
      {'  '}
      <img src={'/images/logo2.png'} alt="Park Here Now Logo" height="30" width="20" />

    </Typography>
    </Paper>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== false,
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== false })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);




//export default DashboardSec;
