import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';

// examples:
import MapContainer from './MapContainer';
import MapMyComponent from './MapMyComponent';


// consts: [34.0522, -118.2437]

const keyAk = process.env.REACT_APP_MAP_KEY;

// InfoWindow component
const InfoWindow = (props) => {
  
  const { place } = props;
  const infoWindowStyle = {
    position: 'relative',
    bottom: 50,
    left: '5px',
    width: 33,
    color: 'white',
    backgroundColor: "#003A55",
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 1,
    fontSize: 14,
    zIndex: 10,
  };

  return (
    <div style={infoWindowStyle}>
      <div align="center" style={{ fontSize: 16, fontWeight: 'bold'}}>
        {place.name}
      </div>
    </div>
  );
};

// Marker component
const Marker = ({ show, place }) => {
  const markerStyle = {
    border: '1px solid white',
    borderRadius: '100%',
    height: 30,
    width: 30,
    backgroundColor: 1 ? "#2e7d32" : 'blue',
    cursor: 'pointer',
    zIndex: 10,
  };

  return (
    <>
      <div style={markerStyle} />
      {show && <InfoWindow place={place} />}
    </>
  );
};

class WithMarker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      places: [],
    };
  }

  componentDidMount() {
    fetch('./places.json')
      .then((response) => response.json())
      .then((data) => {
        data.results.forEach((result) => {
          result.show = true; // eslint-disable-line no-param-reassign
        });
        this.setState({ places: data.results });
      });
  }

  // onChildClick callback can take two arguments: key and childProps
  onChildClickCallback = (key) => {
    this.setState((state) => {
      const index = state.places.findIndex((e) => e.id === key);
      console.log(state.places);

      state.places[index].show = !state.places[index].show; // eslint-disable-line no-param-reassign
      return { places: state.places };
    });
  };

  render() {
    const { places } = this.state;

    return (
      <>
       
          <MapMyComponent>
  
          </MapMyComponent>
         
       
      </>
    );
  }
}

InfoWindow.propTypes = {
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
    rating: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.string),
    price_level: PropTypes.number,
    opening_hours: PropTypes.shape({
      open_now: PropTypes.bool,
    }),
  }).isRequired,
};

Marker.propTypes = {
  show: PropTypes.bool.isRequired,
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
    rating: PropTypes.number,
    types: PropTypes.arrayOf(PropTypes.string),
    price_level: PropTypes.number,
    opening_hours: PropTypes.shape({
      open_now: PropTypes.bool,
    }),
  }).isRequired,
};

export default WithMarker;