import React, { Component } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import { MarkerClusterer } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import TimePicker from '@mui/lab/TimePicker';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import "../css/style.css";
import { useNavigate } from "react-router";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import { Button } from 'react-bootstrap';
import Chip from '@mui/material/Chip';
import { db } from "./Login";
import { map } from 'lodash';
import moment, { months } from 'moment';


const mapStyles = [
  {
    stylers: [
      {
        hue: '#4da6ff',
      },
      {
        saturation: 5,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        lightness: 30,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
];


const options2 = {
  maxZoom: 17,
  imagePath:
    './images/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
};

function createKey(location) {
  return location
};

const containerStyle = {
  width: '100%',
  height: '100%'
};



const options = {
  styles: mapStyles,
};




const paymentTypes = [
  {
    value: 'Free',
    label: 'Free',
  },
  {
    value: 'Per Hour',
    label: 'Per Hour',
  },
  {
    value: 'Guest or Permit Free',
    label: 'Guest or Permit Free',
  },
  {
    value: 'Elapsed Time',
    label: 'Elapsed Time',
  },
];



function MapMyComponent2() {



  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAHXPTUj2yO7cLM89zIl3o-XMyEqGgEWgA"
  });
  const [formDone, setFormDone] = useState(false);
  const [dayValue, setdayValue] = useState('');
  const [dayOutValue, setdayOutValue] = useState('');
  const [timeIn, setTimeIn] = useState('');
  const [timeOut, setTimeOut] = useState('');
  const [dayValueT, setdayValueT] = useState(false);
  const [dayOutValueT, setdayOutValueT] = useState(false);
  const [timeInT, setTimeInT] = useState(false);
  const [timeOutT, setTimeOutT] = useState(false);
  const [selectedDateIn, setselectedDateIn] = useState(new Date());
  const [selectedDateOut, setselectedDateOut] = useState(new Date());
  const [selectedTimeIn, setselectedTimeIn] = useState(new Date());
  const [selectedTimeOut, setselectedTimeOut] = useState(new Date());
  const [formsShow, setformsShow] = useState(false);

  const [iconStr, seticonStr] = React.useState("./images/m3.png");

  const [mapa, setMap] = React.useState(null);

  const [zoomLevel, setzoomLevel] = React.useState('18');

  const [info, setInfo] = useState([]);

  const [filteredParking, setfilteredParking] = useState([]);

  const [rowsTable, setrowsTable] = useState([]);


  const [phoneNo, setphoneNo] = useState("");
  const [eventName, seteventName] = useState("");
  const [emailOwner, setemailOwner] = useState("");
  const [regNo, setregNo] = useState("");
  const [fullName, setfullName] = useState("");
  const [paymentType, setpaymentType] = useState("");







  const onLoad = React.useCallback(function callback(mapa2) {
    //const bounds = new window.google.maps.LatLngBounds(center);
    //mapa2.fitBounds(bounds);
    //setMap(mapa);
  }, [])

  const onUnmount = React.useCallback(function callback(mapa2) {
    setMap(null);
  }, [])

  const [idbaySelected, setidbaySelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("idbaySelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [finalBays, setfinalBays] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("finalBays");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [baytypeSelected, setbaytypeSelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("baytypeSelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const onClick = React.useCallback((id, baytype) => {

    var copyMe = [];

    idbaySelected.forEach(element => {
      if (element["id"] == id) {
        (element["icon"] == "./images/m1.png")

          ?
          (element["icon"] = "./images/m2.png")
          :
          (element["icon"] = "./images/m1.png")
          ;
        copyMe.push(element);
      } else {
        copyMe.push(element);
      };
    });
    setidbaySelected(copyMe);
    get_bays(id);

  }, [])


  function get_bays(bays_selected) {
    const listOfObjects = [];
    idbaySelected.forEach(element => {
      if (element['icon'] == "./images/m2.png") {
        var singleObj = {}
        singleObj['id'] = element['id'];
        listOfObjects.push(singleObj);
      }
    });
    localStorage.setItem("idbaySelected", JSON.stringify(idbaySelected));
    setrowsTable(listOfObjects);
  }


  const onClickMarker = React.useCallback(function callback(e) {
    setzoomLevel('19');
    //xconsole.log(e);
  }, [])

  const [carparkSelected, setcarparkSelected] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelected");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [dateInFinal, setdateInFinal] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("dateInFinal");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [dateOutFinal, setdateOutFinal] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("dateOutFinal");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carparkSelectedlat, setcarparkSelectedlat] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelectedlat");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const [carparkSelectedlng, setcarparkSelectedlng] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("carparkSelectedlng");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });

  const center = {
    lat: parseFloat(carparkSelectedlat),
    lng: parseFloat(carparkSelectedlng)
  };

  useEffect(() => {
    // storing input name
    localStorage.setItem("carparkSelected", JSON.stringify(carparkSelected));
  }, [carparkSelected]);

  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e]).map(e => arr[e]);
    return unique;
  }

  useEffect(() => {
    // storing input name
    var filt = getUnique(info, "id");
    setfilteredParking(filt);
  }, [info]);


  const fetchBays = async () => {
    try {
      db.collection("beacons").get().then((querySnapshot) => {
        // Loop through the data and store
        // it in array to display
        querySnapshot.forEach(element => {
          var data = element.data();
          data['icon'] = "./images/m1.png";
          //console.log("data");
          //console.log(data);
          //console.log("data");
          if (data.parking == carparkSelected) {
            setInfo(arr => [...arr, data]);
          };
        });
      });

    } catch (err) {
      console.error(err);
      //alert("An error occured while fetching user data");
    }
  };


  useEffect(() => {
    // storing input name
    localStorage.setItem("fullName", JSON.stringify(fullName));
  }, [fullName]);

  useEffect(() => {
    // storing input name
    localStorage.setItem("regNo", JSON.stringify(regNo));
  }, [regNo]);

  useEffect(() => {
    // storing input name
    localStorage.setItem("emailOwner", JSON.stringify(emailOwner));
  }, [emailOwner]);


  useEffect(() => {
    // storing input name
    localStorage.setItem("eventName", JSON.stringify(eventName));
  }, [eventName]);


  useEffect(() => {
    // storing input name
    localStorage.setItem("phoneNo", JSON.stringify(phoneNo));
  }, [phoneNo]);


  useEffect(() => {
    // storing input name
    localStorage.setItem("paymentType", JSON.stringify(paymentType));
  }, [paymentType]);



  useEffect(() => {
    // storing input name
    fetchBays();
  }, []);


  function triggerChange() {
    console.log("triggerChange");
  };

  const toReport = (props) => {
    //putData();

    //setFormDone(true);
    navigate('/Confirmation');
  };

  function sortOut() {
    const listOfObjects = [];
    var datein = moment(dateInFinal)['_d'];
    var dateout = moment(dateOutFinal)['_d'];
    //console.log(datein);
    //console.log(dateout);
    //console.log(idbaySelected);
    idbaySelected.forEach(element => {
      if (element['icon'] == "./images/m2.png") {
        var singleObj = {}
        singleObj['id'] = element['id'];
        singleObj['datein'] = String(datein);
        singleObj['dateout'] = String(dateout);

        singleObj['name'] = fullName;
        singleObj['reg'] = regNo;
        singleObj['email'] = emailOwner;
        singleObj['evho'] = eventName;
        singleObj['phone'] = phoneNo;
        singleObj['payment'] = paymentType;

        listOfObjects.push(singleObj);
      }
    });
    //console.log(listOfObjects);
    localStorage.setItem("baysSeet", JSON.stringify(listOfObjects));
  }
  useEffect(() => {
    // storing input name
    sortOut();
  }, [idbaySelected, fullName,
    regNo, emailOwner,eventName, phoneNo, paymentType]);

console.log(idbaySelected);

  return isLoaded ? (
    <React.Fragment>


      <div style={{ height: '35vh', width: '100%', padding: '10px', margin: "5px" }}>

        <GoogleMap
          id="mapmycomponenet2"
          mapContainerStyle={containerStyle}
          center={center}
          zoom={parseFloat(zoomLevel)}
          options={options}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={onClickMarker}
        >
          { /* Child components, such as markers, info windows, etc. */}
          <></>

          
            {

              idbaySelected.map((location) => (
                <Marker
                  key={createKey(location.id)}
                  position={{ lat: parseFloat(location.lat), lng: parseFloat(location.lng) }}
                  //clusterer={clusterer}
                  icon={location.icon}
                  label={location.id}
                  animation={google.maps.Animation.DROP}
                  opacity={1.0}
                  onClick={() => onClick(location.id, location.baytype)}
                />
              ))
            }

        </GoogleMap>
      </div>

      {(rowsTable.length > 0) ?

          <form onSubmit={toReport}>
            
              {rowsTable.map((row) => (
                <div style={{ padding: '30px', margin: "5px" }}>

                <Grid
              
              container spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
              key={row.id}
            >
                <Grid item xs={3}>
                  <TextField
                    required
                    label="Bay No."
                    variant="filled"
                    value={row.id}
                    onChange={e => console.log(row.id)}

                  />
                  </Grid>
                  <Grid item xs={3}>
                  <TextField
                    required
                    label="Full Name"
                    variant="filled"
                    value={fullName}
                    onChange={e => setfullName(e.target.value)}
                  />
                  </Grid>
                  <Grid item xs={3}>
                  <TextField
                    label="Vehicle Reg. No."
                    variant="filled"
                    required
                    value={regNo}
                    onChange={e => setregNo(e.target.value.toUpperCase())}
                  />
                  </Grid>
                  <Grid item xs={3}>
                  <TextField
                    label="Email"
                    variant="filled"
                    type="email"
                    required
                    value={emailOwner}
                    onChange={e => setemailOwner(e.target.value)}
                  />
                  </Grid>
                  
            <Grid item xs={4}>
                  <TextField
                    label="Event Or Host Name"
                    variant="filled"
                    required
                    value={eventName}
                    onChange={e => seteventName(e.target.value)}
                  />
                  </Grid>
                  <Grid item xs={4}>
                  <TextField
                    label="Phone"
                    variant="filled"
                    required
                    value={phoneNo}
                    onChange={e => setphoneNo(e.target.value)}
                  />
                  </Grid>
                  <Grid item xs={4}>
                  <TextField
                  fullWidth
                    id="paymentType"
                    required
                    select
                    label="Select"
                    value={paymentType}
                    onChange={e => setpaymentType(e.target.value)}
                  //helperText="Please select your currenc"
                  >
                    {paymentTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                </Grid>
                </div>
              )
              )
             
              }
             
          </form>
        
        :
        <div>
        </div>
      }


    </React.Fragment>
  ) : <></>;
}

export default MapMyComponent2;