import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey, blue, green, purple } from '@mui/material/colors';

import { useEffect, useState } from "react";
import {Link, useNavigate } from "react-router-dom";
import {auth, signInWithEmailAndPassword, signInWithGoogle } from "./Login";
import { useAuthState } from "react-firebase-hooks/auth";
import "../css/style.css";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';



function Copyright(props) {
    return (
      <Typography variant="body2" align="center" {...props} 
      >
        {'Copyright © '}
        <a  target="_blank" href='https://bluemeshsolutions.com/' sx={{ pt: 0, color: 'text.info'  }}>
          Blue Mesh Solutions
        </a>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }
  



const theme = createTheme({  
    palette: {
    primary: {
      main: blue[500], 
    },
    secondary: {
      main: green[500],
    },
  }});

export default function NoAdmin() {
    const navigate = useNavigate();

    const backtologin = () => {
        auth.signOut();
        navigate('/');
      };

  const handleSubmit = (event) => {
    event.preventDefault();
    backtologin();
  };



  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '75vh', backgroundColor: 'rgba(229, 232, 235, 0.863)'}}>
        <CssBaseline />
        <Grid
          item
          xs={2}
          sm={2}
          md={4}
          sx={{
            backgroundImage: 'url(images/logo.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[500] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '75vh',
        
          }}
        />
        <Grid item xs={10} sm={10} md={8} component={Paper} elevation={22}
        sx={{ height: '75vh'}} 
         square>
          <Box
            sx={{
              my: 1,
              mx: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              No Admin Credentials
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In Again
              </Button>
              <Copyright sx={{ mt: 6 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

